<template>
  
  <div>

    <div class="fleet-wrapper">

      <fleet-left-sidebar></fleet-left-sidebar>

      <div class="main-fleet-content">

          <div class="two-thirds">
            
            <div class="before-icon"><font-awesome-icon icon="truck" /></div><h4 class="icon">Fleet Trucks</h4>  
            <div class="clear"></div>

            <div v-if="allTrucks.length">

              <div v-for="truck in allTrucks" :key="truck.truckID">
                <div class="notification">   
                  <div class="item-title">
                    <span class="item-name">{{truck.truckName}} ({{truck.truckUVI}})</span>
                  </div>
                  <div class="buttons">
                    <!-- <span @click="chooseLogType(truck)" class="button is-success">All Truck Logs</span> -->
                    <span @click="editsms(truck.truckName,truck.truckID,truck.truckUVI,truck.isPaidFor)" class="button is-link">View Truck</span>
                    <span v-if="truck.isPaidFor" @click="manageDrivers(truck.truckName,truck.truckID,truck.truckUVI)" class="button is-skip">Manage Drivers</span>
                    <!-- <span v-if="!demoFleet" @click="viewSMS(truck.truckName,truck.truckID,truck.truckUVI)" class="button cancel red"><span><font-awesome-icon icon="print" /></span></span> -->
                  </div>                
                </div>
              </div>

            </div>

            <div v-else><hr><p>No trucks found.</p></div>
      

            <!-- <div class="spacer s40"></div>

            <hr>

            <router-link class="button is-info" to="">Back to Fleet Dashboard</router-link> -->

          </div>

          <div class="one-third">
            
            <div class="before-icon"><font-awesome-icon icon="link" /></div><h4 class="icon">Quick Links</h4>  
            <div class="clear"></div>
            
            <div class="buttons">
              <span @click="showAddTruck = true" class="button is-info">Add a truck</span>
              <span class="button is-danger" @click="showDeleteTruck = true">Delete truck</span>
              <!-- <router-link to="truck-categories" class="button is-light">Truck Categories</router-link> -->
              <!-- <span class="button is-primary" @click="showNewLogs = true">New truck log</span> -->
            </div>
            <hr>
            <div class="buttons">
              <!-- <router-link to="log-startup" class="button is-success"><font-awesome-icon icon="check-circle" /> New Startup Log</router-link>                
              <router-link to="log-maintenance" class="button is-info"><font-awesome-icon icon="wrench" /> Record Maintenance</router-link>                
              <router-link to="general-log" class="button is-skip"><font-awesome-icon icon="ship" /> General Truck Log</router-link>                
              <router-link to="incident-report" class="button is-danger"><font-awesome-icon icon="exclamation-triangle" /> Incident Report</router-link>
              <router-link to="new-truck-induction" class="button is-warning"><font-awesome-icon icon="users" /> New Crew Induction</router-link>
              <router-link to="add-new-defect" class="button"><font-awesome-icon icon="wrench" /> Add New Defect</router-link> -->
              <!-- <span @click="addEditTask('')" to="add-edit-task" class="button is-warning"><font-awesome-icon icon="wrench" /> New Daily Task</span> -->
            </div>

          </div>

        


          <div class="clear"></div>
          <div class="spacer s90"></div>

      </div>

      <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showAddTruck}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
            <header class="modal-card-head is-info">
              <p class="modal-card-title">{{canAddTrucks ? 'New truck details:' : 'Max Trucks Reached'}}</p>
              <button class="delete" aria-label="close" @click.prevent="close"></button>
            </header>
            <section class="modal-card-body">       

              <div v-if="canAddTrucks">
                  <div class="form-group">      
                    <input class="input capitalise" type="text" v-model="newtruck.truckname" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>Truck Name:</label>
                  </div>
                  <div class="form-group">      
                    <input class="input capitalise" type="text" v-model="newtruck.truckuvi" required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>Truck Rego:</label>
                  </div>
              </div>
              <div v-else>
                <p>You've reached your maximum {{fleetStatus.maxTrucks}} permitted of trucks.</p>
                <p>Please call us on 0409 709 596 to discuss increasing your truck limit.</p>
              </div>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-info" v-show="showAddTruckButton" @click="saveTruck">Save new truck</button>
              <button class="button cancel" @click.prevent="close">{{canAddTrucks ? 'Cancel' : 'Got it!'}}</button>
            </footer>
        </div>
      </div>

      <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showDeleteTruck}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
            <header class="modal-card-head is-danger">
              <p class="modal-card-title">Delete a truck</p>
              <button class="delete" aria-label="close" @click.prevent="close"></button>
            </header>
            <section class="modal-card-body">   
              
              <div class="select is-primary">
                <select v-model="deletedTruck">
                  <option disabled value="">Select a truck</option>
                  <option v-for="truck in allTrucks" :value="truck" :key="truck.truckID">
                    {{ truck.truckName }}
                  </option>
                </select>
              </div>

              <div class="delete-truck" v-if="deletedTruck != ''">
                <p>Are you sure you want to delete {{deletedTruck.truckName}}? This cannot be undone.</p>
              </div>
              <!-- <p v-if="deletedTruck != ''">This may take 24 hours to be deleted.</p> -->

            </section>
            <footer class="modal-card-foot">
              <button class="button is-danger" :disabled="deletedTruck == ''" @click.prevent="deleteTruck()">Yes, delete</button>
              <button class="button cancel" @click.prevent="close">Cancel</button>
            </footer>
        </div>
      </div>

      <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showNewLogs}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">New truck log</p>
              <button class="delete" aria-label="close" @click.prevent="close"></button>
            </header>
            <section class="modal-card-body">   
              
              new logs here...

            </section>
            <footer class="modal-card-foot">
              <button class="button cancel" @click.prevent="close">Done</button>
            </footer>
        </div>
      </div>


      <div class="modal modal-full-screen modal-fx-fadeInScale" :class="{'is-active':showChooseLog}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
            <header class="modal-card-head is-info">
              <p class="modal-card-title">Choose a log type</p>
              <button class="delete" aria-label="close" @click.prevent="close"></button>
            </header>
            <section class="modal-card-body">       

              <div class="select is-primary">
                <select v-model="selectedLogType">
                  <option disabled value="">Select a log to view</option>
                  <option v-for="type in logTypes" :value="type.slug" :key="type.slug">
                    {{type.name}}
                  </option>
                </select>
              </div>
              
            </section>
            <footer class="modal-card-foot">
              <button class="button is-info" :disbled="selectedLogType == ''" @click="toLogs()">View All Logs</button>
              <button class="button cancel" @click.prevent="close">Cancel</button>
            </footer>
        </div>
      </div>



    </div>


  </div>

</template>



<script>
  
import { db } from '../main.js';
import firebase from 'firebase/app';
import 'firebase/auth';
import NProgress from 'nprogress';
const FleetLeftSidebar = () => import('../reusables/FleetLeftSidebar.vue');
// const SimpleModal = () => import('../reusables/SimpleModal.vue');

export default {

  components: {
    FleetLeftSidebar,
    // SimpleModal
  },  
  props: {

  },
  mounted: function() {
    firebase.auth().onAuthStateChanged( this.onUserLogin );
  },
  computed: {    
    currentFleet() {
      return this.$store.getters.getCurrentFleet;
    },
    showAddTruckButton() {
      return this.newtruck.truckname && this.newtruck.truckuvi;
    },
    fleetStatus() {
      return this.$store.getters.getFleetStatus;
    },
    canAddTrucks() {
      // return this.allTrucks.length < this.fleetStatus.maxTrucks;
      return true;
    },
    userData() {
      return this.$store.getters.getSubscriptionInfo;
    },
  },

  data() {
    return {
      firebaseid: '',
      allTrucks: [],
      showAddTruck: false,
      newtruck: {},
      showDeleteTruck: false,
      deletedTruck: '',
      showNewLogs: false,
      selectedTruck: '',
      selectedLogType: '',
      showChooseLog: false,
      fleetEmail: '',
      logTypes: [
        {name: 'All Trip Logs', slug: 'FleetAllStartups'},
        {name: 'All Maintenance Logs', slug: 'AllMaintenance'},
        {name: 'All general Logs', slug: 'GeneralLogs'},
        {name: 'All Incident Reports', slug: 'AllIncidents'},
      ],
      demoFleet: false,
    }
  },

  methods: {

    onUserLogin: function( user ) {
      this.firebaseid = user.uid;
      this.loadFleetTrucks();
      this.loadFleetInfo();
    },

    loadFleetTrucks() {
      let self = this;
      db.collection('fleets').doc(this.currentFleet.id).collection('fleetTrucks').onSnapshot(function(querySnapshot) {
        self.allTrucks = [];
        querySnapshot.forEach((doc) => {
          db.collection('trucks').doc(doc.id).get().then(function(docRef) {
              self.allTrucks.push({
                truckID: doc.id,
                truckName: doc.data().truckName,
                truckUVI: doc.data().truckUVI,
                isPaidFor: docRef.data().isPaidFor ? docRef.data().isPaidFor : false,
              }); 
          });
              // self.allTrucks.push({
              //   truckID: doc.id,
              //   truckName: doc.data().truckName,
              //   truckUVI: doc.data().truckUVI,
              // });
        });
      });
    },

    loadFleetInfo() {
      let self = this;
      db.collection('fleets').doc(this.currentFleet.id).get().then(function(doc) {
        self.fleetEmail = doc.data().fleetEmail;
        self.demoFleet = doc.data().isDemo ? doc.data().isDemo : false;
      });
    },

    close() {
      this.showAddTruck = false;
      this.newtruck = {};
      this.showDeleteTruck = false;
      this.deletedTruck = '';
      this.showNewLogs = false;
      this.selectedTruck = '';
      this.selectedLogType = '';
      this.showChooseLog = false;
    },

    manageDrivers(truckName,truckID) {
      this.$router.push({ name: 'FleetApprove', params: { 
        selectedTruckID : truckID,
        selectedTruckName : truckName,
      } })
    },

    saveTruck: function() {
      NProgress.start();
      let self = this;
      let newTruckName = self.newtruck.truckname.toUpperCase();
      let newTruckUVI = self.newtruck.truckuvi.toUpperCase();
      let newTruckArray = {
        truckName: newTruckName,
        truckUVI: newTruckUVI,
        truckOwnerID: self.currentFleet.id,
        truckOwnerName: self.currentFleet.name,
        truckOwnerEmail: self.fleetEmail,
        isFleetTruck: true,
        isPaidFor: false,
        truckState: self.currentFleet.state ? self.currentFleet.state : '',
        serviceAunit: 'KM',
        serviceBunit: 'KM',
        serviceCunit: 'KM',
      }
      // console.log(newTruckArray);

      db.collection("trucks").add(newTruckArray)
      .then(function(docRef) {
        db.collection("fleets").doc(self.currentFleet.id).collection('fleetTrucks').doc(docRef.id).set({
          truckOwnerID: self.currentFleet.id,
          truckOwnerName: self.currentFleet.name,
          truckName: newTruckName,
          truckUVI: newTruckUVI,
          truckID: docRef.id,
          truckState: self.currentFleet.state ? self.currentFleet.state : '',
        })
        .then(function() {
          self.showAddTruck = false;
          self.newtruck = {};
          NProgress.done();
        });
      })
      .catch(error => {
          console.error("Error adding truck: ", error);
          self.showAddTruck = false;
          self.newtruck = {};
          NProgress.done();
      });

    },

    deleteTruck() {
      // NProgress.start();
      let self = this;
      let listOfDriverIDs;
      // console.log('Delete:',this.deletedTruck);
      db.collection('trucks').doc(this.deletedTruck.truckID).get().then(function(doc) {
        listOfDriverIDs = doc.data().listOfDriverIDs;
      })
      .then(() => {
        // console.log(listOfDriverIDs);
        var deleteFleetTruck = firebase.functions().httpsCallable('deleteFleetTruck');
        deleteFleetTruck({
          listOfDriverIDs: listOfDriverIDs,
          truckID: self.deletedTruck.truckID,
          truckName: self.deletedTruck.truckName,
          userName: self.userData.username,
          fleetName: self.currentFleet.name
        }); 
        var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
        addTruckRevision({
          userName: self.userData.username,
          userID: self.firebaseid,
          truckName: self.deletedTruck.truckName,
          truckID: self.deletedTruck.truckID,
          revisionDetails: 'Fleet truck deleted',
        });
      })
      .then(() => {
        db.collection('trucks').doc(self.deletedTruck.truckID).update({
          listOfDriverIDs: [],
          listOfMasterIDs: []
        });
      })
      .then(() => {
        db.collection("fleets").doc(self.currentFleet.id).collection('fleetTrucks').doc(self.deletedTruck.truckID).delete();
      })
      .then(() => {
        self.close();
        self.deletedTruck = '';
        NProgress.done();
      });

    },  

    dailylogs: function(type,isActive,truckID,truckName,truckUVI,lastStartupID) {
      console.log(type,isActive,truckID,truckName,truckUVI,lastStartupID);
    },

    editsms: function(truckName,truckID,truckUVI,isPaidFor) {
      // console.log(truckName,truckID,truckUVI);
      this.selectedTruckID = truckID;
      this.$store.dispatch('UPDATESELECTEDTRUCK',{truckName,truckID,truckUVI,isPaidFor});
      this.$router.push({ name: 'FleetEditSms', params: { 
        selectedTruckID : truckID,
        selectedTruckName : truckName,
        selectedTruckUVI : truckUVI,
      } })
    },
    
    // viewSMS: function(truckName,truckID,truckUVI,isPaidFor) {
    //   let self = this;      
    //   this.$store.dispatch('UPDATESELECTEDTRUCK',{truckName,truckID,truckUVI,isPaidFor});
    //   this.$store.dispatch('UPDATESELECTEDTRUCK',{truckName,truckID,truckUVI});
    //   this.$router.push({
    //     name: 'ViewSms',
    //     params: {
    //       truckID: truckID,
    //       truckName: truckName,
    //       ownerID: self.currentFleet.id,
    //       isFleetTruck: true,
    //     }
    //   });
    // },

    chooseLogType(truck) {
      this.selectedTruck = truck;
      this.showChooseLog = true;
    },

    toLogs() {
      let self = this;
      // console.log(this.selectedLogType,this.selectedTruck);
      let truckName = this.selectedTruck.truckName;
      let truckID = this.selectedTruck.truckID;
      let truckUVI = this.selectedTruck.truckUVI;
      let isPaidFor = this.selectedTruck.isPaidFor;
      this.$store.dispatch('UPDATESELECTEDTRUCK',{truckName,truckID,truckUVI,isPaidFor});
      this.$router.push({ name: this.selectedLogType, params: { 
        selectedTruckID : self.selectedTruck.truckID,
        selectedTruckName : self.selectedTruck.truckName,
      } });
    },

    addEditTask(task) {
      this.$router.push({ 
        name: 'AddEditTask', 
        params: { 
         sentTask : task == '' ? {} : task,
         editing: task == '' ? false : true,
         archived: false,
         singleTruck: false
        } 
      });
    }

    




  }



}


</script>



<style lang="scss" scoped>
  

  .form-group {
    input.capitalise {
      text-transform: uppercase;
    }
  }
  .delete-truck {
    margin-top: 1rem;
  }
  .buttons {
    .button {
      svg {
        margin-right: 5px;
      }
    }
  }
  .item-title {
    text-align: left;
  }

</style>


