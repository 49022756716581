<template>
  
  <div>

    <div class="fleet-wrapper">

      <fleet-left-sidebar></fleet-left-sidebar>

      <div class="main-fleet-content">

          <div class="two-thirds">
            
            <div class="before-icon"><font-awesome-icon icon="truck" /></div><h4 class="icon">{{currentTruckName}}</h4>  
            <div class="clear"></div>

           <!-- {{currentTruckID}}/{{currentTruckName}}/{{currentTruckUVI}} -->

            <!-- <div class="sms-wrapper"> -->
            <div class="sms-wrapper">


              <div class="sms-buttons">

                <div class="buttons">
                  <router-link class="button is-light is-medium" :to="{name: 'FleetTruckInformation', params: {isFleetTruck: true} }">
                    <span><font-awesome-icon icon="info-circle" /></span>&nbsp;&nbsp;Truck Information
                  </router-link>
                </div>

                <div v-if="currentTruckPaidFor" class="buttons">
                  <router-link class="button is-dark is-medium" to="fleet-truck-log">
                    <span><font-awesome-icon icon="truck" /></span>&nbsp;&nbsp;Truck's Log
                  </router-link>
                </div>

                <!-- <div class="buttons">
                  <router-link class="button is-danger is-medium" :class="{ hascontent: (smsStatus.master && smsStatus.designated) }" to="duty-care">
                    <span><font-awesome-icon icon="file-signature" /></span>&nbsp;&nbsp;Responsibility Statements
                  </router-link>
                </div> -->

               <!--  <div class="buttons">
                  <router-link class="button is-danger is-medium" :class="{ hascontent: smsStatus.hazards || smsStatus.smsfleethazards }" to="new-all-hazards">
                    <span><font-awesome-icon icon="exclamation-triangle" /></span>&nbsp;&nbsp;Hazards on your truck
                  </router-link>
                </div> -->

                <!-- <div class="buttons">
                  <router-link class="button is-danger is-medium" :class="{ hascontent: smsStatus.defects }" to="all-defects">
                    <span><font-awesome-icon icon="wrench" /></span>&nbsp;&nbsp;Defects on your truck !!!!
                  </router-link>
                </div> -->

                <!-- <div class="buttons">
                  <router-link class="button is-danger is-medium" :class="{ hascontent: smsStatus.smscontacts || smsStatus.fleetcontacts }" to="emergency-contacts">
                    <span><font-awesome-icon icon="users" /></span>&nbsp;&nbsp;Emergency Contacts
                  </router-link>
                </div>

                <div class="buttons">
                  <router-link class="button is-danger is-medium" :class="{ hascontent: smsStatus.smspolicies || smsStatus.fleetpolicies }" to="truck-policies">
                    <span><font-awesome-icon icon="list" /></span>&nbsp;&nbsp;Truck Policies
                  </router-link>
                </div>

                <div class="buttons">
                  <router-link class="button is-danger is-medium" :class="{ hascontent: smsStatus.smssops || smsStatus.fleetsops }" to="operational-procedures">
                    <span><font-awesome-icon icon="anchor" /></span>&nbsp;&nbsp;Safe Work Procedures
                  </router-link>
                </div>

                <div class="buttons">
                  <router-link class="button is-danger is-medium" :class="{ hascontent: smsStatus.smseops || smsStatus.fleeteops }" to="emergency-procedures">
                    <span><font-awesome-icon icon="life-ring" /></span>&nbsp;&nbsp;Emergency Procedures
                  </router-link>
                </div> -->

               <!--  <hr> -->

                <!-- <div class="buttons">
                  <router-link class="button is-danger is-medium" :class="{ hascontent: smsStatus.smsmaintenance || smsStatus.fleetmaintenance }" to="maintenance-checklists">
                    <span><font-awesome-icon icon="cog" /></span>&nbsp;&nbsp;Maintenance Checklists
                  </router-link>
                </div>

                <div class="buttons">
                  <router-link class="button is-danger is-medium" :class="{ hascontent: smsStatus.truckinductionitems || smsStatus.fleetinductionitems }" to="truck-induction-items">
                    <span><font-awesome-icon icon="user" /></span>&nbsp;&nbsp;Crew Induction Checklists
                  </router-link>
                </div>

                <div v-if="takesPassengers" class="buttons">
                  <router-link class="button is-danger is-medium" :class="{ hascontent: smsStatus.passengerinductionitems || smsStatus.fleetpassengerinductionitems }" to="passenger-induction-checklist">
                    <span><font-awesome-icon icon="users" /></span>&nbsp;&nbsp;Passenger Inductions
                  </router-link>
                </div>

                <div class="buttons">
                  <router-link class="button is-danger is-medium" :class="{ hascontent: smsStatus.smsactivities || smsStatus.fleetactivities }" to="activity-checklists">
                    <span><font-awesome-icon icon="clipboard-list" /></span>&nbsp;&nbsp;Activity Checklists
                  </router-link>
                </div>

                <div class="buttons">
                  <router-link class="button is-danger is-medium" :class="{ hascontent: smsStatus.smsshutdowns || smsStatus.fleetshutdowns }" to="shutdown-checklists">
                    <span><font-awesome-icon icon="clipboard-list" /></span>&nbsp;&nbsp;Shutdown Checklists
                  </router-link>
                </div>
 -->
                <!-- <div class="buttons">
                  <router-link class="button is-danger is-medium" :class="{ hascontent: false }" to="">
                    <span><font-awesome-icon icon="clipboard-list" /></span>&nbsp;&nbsp;Passenger Induction Checklist
                  </router-link>
                </div> -->



                <hr>

                <div v-if="currentTruckPaidFor">

                
                      <div class="before-icon"><font-awesome-icon icon="clipboard-list" /></div><h4 class="icon">Custom Checklists</h4>  
                      <div class="clear"></div>



                      <div v-for="list in customChecklists" class="buttons" :key="list.checklistName">
                        <span class="button is-medium is-skip" @click="toCustomChecklist(list)">
                          <span><font-awesome-icon icon="list" /></span>&nbsp;&nbsp;{{list.checklistName}}
                        </span>
                      </div>

                      <span class="button" @click="showAddChecklist = true">Add a New Checklist</span>

                      <hr>


                      <!-- <div class="buttons">
                        <router-link class="button is-light is-medium" to="fleet-truck-shopping-list">
                          <span><font-awesome-icon icon="shopping-cart" /></span>&nbsp;&nbsp;Shopping List
                          <span v-if="shoppingCount > 0" class="counter">{{shoppingCount}}</span>
                        </router-link>
                      </div>


                      <div class="buttons">
                        <router-link class="button is-medium is-warning" to="all-defects">
                          <span><font-awesome-icon icon="wrench" /></span>&nbsp;&nbsp;Truck Defects
                          <span v-if="truckDefectCount > 0" class="counter">{{truckDefectCount}}</span>
                        </router-link>
                      </div> -->

                      <div class="buttons">
                        <!-- <router-link class="button is-danger is-medium" :class="{ hascontent: smsStatus.smsstartups || smsStatus.fleetstartups }" to="startup-checklists"> -->
                        <router-link class="button is-success is-medium" to="startup-checklists">
                          <span><font-awesome-icon icon="check-circle" /></span>&nbsp;&nbsp;Pre-start Checklists
                        </router-link>
                      </div>

                      <!-- <div class="buttons">
                        <router-link class="button is-medium is-warning" to="weight-management">
                          <span><font-awesome-icon icon="wrench" /></span>&nbsp;&nbsp;Weight Management
                        </router-link>
                      </div> -->

                      <div class="buttons">
                        <router-link class="button is-warning is-medium" to="fleet-service-schedules">
                          <span><font-awesome-icon icon="oil-can" /></span>&nbsp;&nbsp;Service Schedules
                        </router-link>
                      </div>


                      <div class="buttons">
                        <router-link class="button is-info is-medium" :class="{ hascontent: false }" to="fleet-truck-uploads">
                          <span><font-awesome-icon icon="camera" /></span>&nbsp;&nbsp;Upload Documents
                        </router-link>
                      </div>

                      <div class="buttons">
                        <router-link class="button is-skip is-medium" :class="{ hascontent: false }" to="fleet-truck-dates">
                          <span><font-awesome-icon icon="calendar-check" /></span>&nbsp;&nbsp;Important Dates
                        </router-link>
                      </div>

                      <div class="buttons">
                        <span class="button is-success is-medium" @click="toDailyTasks()">
                          <span><font-awesome-icon icon="wrench" /></span>&nbsp;&nbsp;Daily Tasks
                        </span>
                      </div>

                      <!-- <div class="buttons">
                        <router-link class="button is-success is-medium" to="fleet-truck-equipment">
                          <span><font-awesome-icon icon="life-ring" /></span>&nbsp;&nbsp;Equipment Register
                        </router-link>
                      </div>

                      <div v-if="takesPassengers" class="buttons">
                        <span class="button is-light is-medium" @click="toManifests()">
                          <span><font-awesome-icon icon="users" /></span>&nbsp;&nbsp;Passenger Manifests
                        </span>
                      </div> -->

                      <!-- <div class="buttons">
                        <router-link class="button is-link is-medium" to="service">
                          <span><font-awesome-icon icon="wrench" /></span>&nbsp;&nbsp;Servicing
                        </router-link>
                      </div> -->

                      <div class="buttons">
                        <router-link class="button is-medium" to="fleet-truck-revisions">
                          <span><font-awesome-icon icon="list" /></span>&nbsp;&nbsp;Activity Log
                        </router-link>
                      </div>

                </div>

                <div v-else class="">
                  <div class="before-icon red"><font-awesome-icon icon="exclamation-triangle" /></div><h4 class="icon red">Upgrade this Truck</h4>  
                  <div class="clear"></div>
                  <div class="buttons">
                    <span class="button is-success" @click="subscribeTruck()">Unlock all features for $39/year!</span>
                  </div>
                </div>



              </div>

              <div class="clear"></div>

          </div>
      

            <!-- <div class="spacer s40"></div>

            <hr>

            <router-link class="button is-info" to="">Back to Fleet Dashboard</router-link> -->

          </div>

          <div class="one-third">
            
            <div class="before-icon"><font-awesome-icon icon="link" /></div><h4 class="icon">Quick Links</h4>  
            <div class="clear"></div>
            
            <div class="buttons">
              <router-link to="fleet-trucks" class="button is-info">All Fleet Trucks</router-link>
              <!-- <span v-if="!demoFleet" @click="viewSMS()" class="button is-primary">SMS Snapshot</span> -->
            </div>

            <!-- <div v-if="currentTruckStatus != 'free' && currentTruckStatus != undefined" class="buttons">
              <span class="button is-danger" @click="revokeAccess()">Cancel subscription</span>
            </div> -->
            <div v-if="!currentTruckPaidFor" class="buttons">
              <span class="button is-success" @click="subscribeTruck()">Upgrade truck for $39/year!</span>
            </div>

          </div>
        


          <div class="clear"></div>

      </div>

      <div class="spacer s90">
        
      </div>


      <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showAddChecklist}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Add New Checklist</p>
            <button class="delete" aria-label="close" @click.prevent="close"></button>
          </header>
          <section class="modal-card-body">
            
            <input class="input" type="text" placeholder="Checklist Name" maxlength="70" v-model="newChecklistName" />

          </section>
          <footer class="modal-card-foot">
            <button class="button cancel" @click.prevent="close">Cancel</button>
            <button class="button is-primary" @click="addCustomChecklist()">Add</button>
          </footer>
        </div>
      </div>


    </div>


  </div>

</template>



<script>
  
import { db } from '../main.js';
import firebase from 'firebase/app';
import 'firebase/auth';
// import NProgress from 'nprogress';
const FleetLeftSidebar = () => import('../reusables/FleetLeftSidebar.vue');

export default {

  components: {
    FleetLeftSidebar,
  },  
  props: {

  },
  mounted: function() {
    this.$store.dispatch('UPDATESMSSTATUS');
    firebase.auth().onAuthStateChanged( this.onUserLogin );
  },
  computed: {    
    currentFleet() {
      return this.$store.getters.getCurrentFleet;
    },
    // currentFleetTruck() {
    //   return this.$store.getters.getCurrentFleetTruck;
    // },
    currentTruckID() {
      return this.$store.getters.getCurrentTruckID;
    },
    currentTruckName() {
      return this.$store.getters.getCurrentTruckName;
    },
    currentTruckUVI() {
      return this.$store.getters.getCurrentTruckUVI;
    },
    currentTruckPaidFor() {
      return this.$store.getters.getCurrentTruckPaidFor;
    },
    smsStatus() {
      return this.$store.getters.getSmsStatus;
    },
    userData() {
      return this.$store.getters.getSubscriptionInfo;
    },
    isFreeTrial() {
      return this.userData.freeTrial ? this.userData.freeTrial : false;
    }
    // takesPassengers() {
    //   return this.$store.getters.getSelectedTruck.takesPassengers;
    // }

  },

  data() {
    return {
      firebaseid: '',
      firebasedisplayname: '',
      truckHazardCount: 0,
      truckDefectCount: 0,
      shoppingCount: 0,
      customChecklists: [],
      showAddChecklist: false,
      newChecklistName: '',
      demoFleet: false,
      annualTruckFee: 39,
    }
  },

  methods: {

    onUserLogin: function( user ) {
      this.firebaseid = user.uid;
      this.firebasedisplayname = this.userData.username;
      this.loadFleetInfo();
      // this.loadCounts();
      this.loadCustomChecklists();
    },

    // loadCounts: function() {
    //   let self = this;
    //   this.shoppingCount = 0;
    //   db.collection('trucks').doc(this.currentTruckID).collection('smsDefects').where('resolved','==',false).onSnapshot(function(querySnapshot) {
    //     self.truckDefectCount = querySnapshot.size;
    //   });
    //   db.collection('trucks').doc(this.currentTruckID).collection('smsHazards').onSnapshot(function(querySnapshot) {
    //     self.truckHazardCount = querySnapshot.size;
    //   });
    //   db.collection('trucks').doc(this.currentTruckID).collection('shoppingList').onSnapshot(function(querySnapshot) {
    //     self.shoppingCount = querySnapshot.size;
    //   });
    // },

    loadFleetInfo() {
      let self = this;
      db.collection('fleets').doc(this.currentFleet.id).get().then(function(doc) {
        self.fleetEmail = doc.data().fleetEmail;
        self.demoFleet = doc.data().isDemo ? doc.data().isDemo : false;
      });
    },

    // toManifests() {
    //   // let self = this;
    //   let truckName = this.currentTruckName;
    //   let truckID = this.currentTruckID;
    //   let truckUVI = this.currentTruckUVI;
    //   this.$store.dispatch('UPDATESELECTEDVESSEL',{truckName,truckID,truckUVI});
    //   this.$router.push({
    //     name: 'FleetTruckManifests',
    //     params: {

    //     }
    //   });
    // },

    toDailyTasks() {
      // let self = this;
      // let truckName = this.currentTruckName;
      // let truckID = this.currentTruckID;
      // let truckUVI = this.currentTruckUVI;
      // this.$store.dispatch('UPDATESELECTEDVESSEL',{truckName,truckID,truckUVI});
      this.$router.push({
        name: 'FleetTruckDailyTasks',
        params: {

        }
      });
    },

    // viewSMS: function() {
    //   let self = this;      
    //   this.$router.push({
    //     name: 'ViewSms',
    //     params: {
    //       truckID: self.currentTruckID,
    //       truckName: self.currentTruckName,
    //       ownerID: self.currentFleet.id,
    //       isFleetTruck: true,
    //     }
    //   });
    // },

    addCustomChecklist() {
      let self = this;
      self.showAddChecklist = false;
      // console.log('new checklist name:',this.newChecklistName);
      db.collection('trucks').doc(this.currentTruckID).collection('customChecklists').add({
        checklistName: self.newChecklistName,
      })
      .then((doc) => {
        console.log('New checklist added with doc ID: ',doc.id);
        var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
        addTruckRevision({
          userName: self.firebasedisplayname,
          userID: self.firebaseid,
          truckName: self.currentTruckName,
          truckID: self.currentTruckID,
          revisionDetails: 'New custom checklist called ' + self.newChecklistName + ' added',
        });   
        self.newChecklistName = '';
      });
    },

    loadCustomChecklists() {
      let self = this;
      db.collection('trucks').doc(self.currentTruckID).collection('customChecklists').onSnapshot(function(querySnapshot) {
        self.customChecklists = [];
        querySnapshot.forEach((doc) => {
          self.customChecklists.push({
            id: doc.id,
            checklistName: doc.data().checklistName,
          });
        });
      })
    },

    toCustomChecklist(checklist) {
      // let self = this;
      // console.log(checklist.id, checklist.checklistName);
      this.$router.push({
        name: 'FleetCustomChecklist',
        params: {
          checklistID: checklist.id,
          checkListName: checklist.checklistName
        }
      });
    },

    close() {
      this.showAddChecklist = false;
      this.newChecklistName = '';
    },

    subscribeTruck: function() {
      var self = this;
      var futureDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

      self.$router.push({ name: 'SquareNewCheckout', params: { 
          userID: self.firebaseid, //done
          userName: self.userData.username ? self.userData.username : 'No name', //done
          todayChargeAmount: self.annualTruckFee, //done
          newAnnualChargeAmount: self.annualTruckFee, //done
          // updatedUserRole: self.userRole, //done
          // updatedMaxVessels: self.newMaxVessels, //done
          newRenewalDate: futureDate, //done
          // changeDate: false //done
          truckName: self.currentTruckName,
          truckID: self.currentTruckID,
        }
      });
        
    },




  }



}


</script>



<style lang="scss" scoped>
  
  .main-fleet-content {
    text-align: center;
    .sms-wrapper {
      // margin: 3rem auto;
      // width: 95%;
      // max-width: 500px;
      .sms-buttons {
        text-align: left;
        width: 100%;
        .button {
          margin: 0;
          padding: 1rem 1rem;
          text-align: left;
          display: inline-block;
          height: auto;
          white-space: normal;
        }
        .buttons {
          margin-bottom: 10px;
          position: relative;
          margin-right: 2rem;
        }
      }
    }
  }
  input[type="text"] {
    padding: 2rem 1rem;
  }
    
  span.counter {
    position: absolute;
    top: -8px;
    right: -10px;
    border-radius: 50%;
    background: red;
    width: 22px;
    height: 22px;
    color: #fff;
    font-size: 13px;
    line-height: 20px;
    font-weight: bold;
    text-align: center;
  }

  .button {
    &.is-medium {
      min-width: 340px;
    }
  }
  .is-danger {
    &.hascontent {
      background-color: #23d160 !important;
      color: #fff !important;
    }
  }
  // @media only screen and (max-width: 600px) {
  //   min-width: auto;
  //   width: 100%;
  // }
   @media only screen and (max-width: 767px) {
    
    .main-fleet-content {
      .sms-wrapper {
        width: 100%;
        margin: 1rem auto 3rem !important;
        .sms-buttons {
          width: 100%;
          border: none;
          margin: 0 auto;
          max-width: 315px;
          float: left;
          .button {
            min-width: auto;
            width: 100%;
            margin: 0 0 10px;
            text-align: left;
            display: block;
            font-size: 1.1rem!important;
            padding: 11px!important;
            white-space: normal;
            height: auto;
          }
        }
        .sms-info {
          width: 100%;
          float: none;
          border-bottom: 1px solid #e4e4e4;
          padding-bottom: 2rem;
          margin-bottom: 2rem;
        }
      }
    }

  }

  @media only screen and (max-width: 399px) {

    .main-fleet-content {
      .sms-wrapper {
        .sms-buttons {
          img {
            display: none;
          }
          .buttons {
            width: 100%;
            text-align: center;
            .button {
              width: 100%;
              margin: 0;
              text-align: left;
              display: block;
              font-size: 1.1rem !important;
              padding: 11px !important;
              white-space: normal;
            }
          }
        }
      }
    }
    
  }



</style>


