<template>
  
  <div>

    <div class="fleet-wrapper">

      <fleet-left-sidebar></fleet-left-sidebar>

      <div class="main-fleet-content">

          <div class="two-thirds">
            
            <div class="before-icon"><font-awesome-icon icon="users" /></div><h4 class="icon">All Fleet Drivers</h4>  
            <div class="clear"></div>

            <!-- {{isAddingStaff ? 'Adding Staff...' : 'not adding anyone'}} -->
            <span v-if="isAddingStaff" class="button is-primary is-loading show-text">Adding Staff...</span>

            <div v-if="allStaff.length">

              <div v-for="(staff, index) in allStaff" :key="index">
                <div class="notification">   
                  <div class="item-title">
                    <span class="item-name">{{staff.staffName}}</span> 
                  </div>
                  <div v-if="staff.connectionStatus == 'Pending'" class="buttons">
                    <span @click="cancelJobRequest(staff.staffName,staff.connectionID,'cancel')" class="button is-danger">Cancel Request</span>
                  </div>  
                  <div v-else class="buttons">
                    <span v-if="!staff.removing" class="button is-warning" @click="staffInductions(staff.staffID, staff.staffName)">Inductions</span>
                    <span v-if="!staff.removing" class="button is-info" @click="viewStaffProfile(staff.staffID, staff.staffName)">Profile</span>
                    <span @click="removeStaff(staff.staffID,staff.staffName,index)" class="button is-danger">{{staff.removing ? 'Deleting...' : 'Remove'}}</span>
                  </div>    
                  <div class="red" v-if="staff.qualExpired"><font-awesome-icon icon="exclamation-triangle" /> Check Qualifications</div>            
                </div>
              </div>

            </div>

            <div v-else><hr><p>No drivers found.</p></div>
      


            <!-- <div class="spacer s40"></div>

            <hr>

            <router-link class="button is-info" to="">Back to Fleet Dashboard</router-link> -->

          </div>

          <div class="one-third">
            
            <div class="before-icon"><font-awesome-icon icon="link" /></div><h4 class="icon">Quick Links</h4>  
            <div class="clear"></div>
            
            <div class="buttons">
              <router-link to="add-staff" class="button is-primary">Add new Driver</router-link>
              <!-- <router-link to="fleet-admins" class="button is-info">Manage Admins</router-link> -->
              <!-- <router-link to="fleet-staff-tracker" class="button is-warning">Find a Driver</router-link> -->
            </div>

          </div>

        


          <div class="clear"></div>
          <div class="spacer s90"></div>

      </div>


    </div>


  </div>

</template>



<script>
  
import { db } from '../main.js';
import firebase from 'firebase/app';
import 'firebase/auth';
import NProgress from 'nprogress';
const FleetLeftSidebar = () => import('../reusables/FleetLeftSidebar.vue');
import * as moment from 'moment';

export default {

  components: {
    FleetLeftSidebar,
  },  
  props: {

  },
  mounted: function() {
    firebase.auth().onAuthStateChanged( this.onUserLogin );
  },
  computed: {    
    currentFleet() {
      return this.$store.getters.getCurrentFleet;
    }
  },

  data() {
    return {
      allStaff: [],
      isAddingStaff: false,
    }
  },

  watch: {
    allStaff() {
      this.loadQualifications();
    }
  },

  methods: {

    onUserLogin: function() {
      // if (user) {         
      //   this.firebaseid = user.uid;
      // }
      this.checkAddingStaffStatus();
      this.loadStaff();
    },

    checkAddingStaffStatus() {
      let self = this;
      db.collection('fleets').doc(this.currentFleet.id).onSnapshot(function(doc) {
        self.isAddingStaff = doc.data().isAddingStaff ? doc.data().isAddingStaff : false;
      });
    },

    async loadStaff() {

      let self = this;
      // db.collection('fleets').doc(this.currentFleet.id).collection('fleetStaff').get().then(function(querySnapshot) {
      await db.collection('fleets').doc(this.currentFleet.id).collection('fleetStaff').onSnapshot(function(querySnapshot) {
        self.allStaff = [];
        querySnapshot.forEach((doc) => {
          self.allStaff.push({
            id: doc.id,
            staffID: doc.data().staffID,
            staffName: doc.data().staffName,
            staffEmail: doc.data().staffEmail,
            staffPhone: doc.data().staffPhone,
            fleetID: doc.data().fleetID,
            fleetName: doc.data().fleetName,
            dateAdded: doc.data().dateAdded,
            qualExpired: false,
            removing: false,
          });
        });
      // })
      // .then(function() {
      //   self.allStaff.forEach((staff) => {
      //     db.collection('userdata').doc(staff.id).collection('myQualifications').get().then(function(querySnapshot) {
      //       querySnapshot.forEach((doc) => {
      //         let tempDate = doc.data().expiryDate != '' ? moment(doc.data().expiryDate.toDate()) : moment();
      //         if (moment().isAfter(tempDate)) staff.qualExpired = true;
      //       });
      //     });
      //   });
      });

      // self.allStaff.forEach((staff) => {
      //   db.collection('userdata').doc(staff.id).collection('myQualifications').get().then(function(querySnapshot) {
      //     querySnapshot.forEach((doc) => {
      //       let tempDate = doc.data().expiryDate != '' ? moment(doc.data().expiryDate.toDate()) : moment();
      //       if (moment().isAfter(tempDate)) staff.qualExpired = true;
      //     });
      //   });
      // });

      //
      // function getStaff() {
      //   return new Promise(resolve => {
      //     db.collection(ll_profile).doc(user_uid).get().then(function(doc) {
      //       var learning_language = doc.data().learning_language;
      //       db.collection(ns_status).where("state", "==", "online").onSnapshot(function(snapshot) {
      //           var ns_match = [];
      //           snapshot.forEach(function(userSnapshot) {
                  
      //           });
      //           resolve(ns_match);
      //         });
      //     });
      //   });
      // }
      // getStaff().then(function() {
      //   //
      // });


    },

    async loadQualifications() {
      let self = this;
      self.allStaff.forEach((staff) => {
        db.collection('userdata').doc(staff.id).collection('myQualifications').get().then(function(querySnapshot) {
          querySnapshot.forEach((doc) => {
            let tempDate = doc.data().expiryDate != '' ? moment(doc.data().expiryDate.toDate()) : moment();
            if (moment().isAfter(tempDate)) staff.qualExpired = true;
          });
        });
      });
    },

    viewStaffProfile: function(staffID, staffName) { 
      this.$router.push({ 
        name: 'FleetStaffProfile', 
        params: { 
          staffID: staffID,
          staffName: staffName
        } 
      });
    },

    approveStaff(staffName,staffID) {
      this.$router.push({ name: 'FleetApprove', params: { 
        selectedStaffID : staffID,
        selectedStaffName : staffName,
      } })

    },

    removeStaff(staffID,staffName,index) {
      // console.log(this.allStaff[index]);
      // this.allStaff[index].removing = true;
      let self = this;    
      let message = {
        title: 'Remove ' + staffName + ' as fleet staff?',
        body: 'You can add them again later.'
      };
      let options = {
        okText: 'Yes, Remove',
        backdropClose: true,
        cancelText: 'Cancel',
      };
      this.$dialog
        .confirm(message, options)
        .then(function() {
          NProgress.start();  
          //
          self.allStaff[index].removing = true;
          var fleetRemoveAdmin = firebase.functions().httpsCallable('fleetRemoveAdmin');
          fleetRemoveAdmin({
            staffID: staffID, 
            staffName: staffName,
            fleetID: self.currentFleet.id,
            fleetName: self.currentFleet.name
          }); 

          var fleetRemoveStaff = firebase.functions().httpsCallable('fleetRemoveStaff');
          fleetRemoveStaff({
            staffID: staffID, 
            staffName: staffName,
            fleetID: self.currentFleet.id,
            fleetName: self.currentFleet.name
          }); 
          NProgress.done();
          // self.$router.replace('staff');
            
        })
        .catch(function() {
          console.log('Clicked on cancel'); 
          NProgress.done();
        });
    },

    staffInductions(staffID, staffName) {
      this.$router.push({ 
        name: 'FleetAllInductions', 
        params: { 
          staffID: staffID,
          staffName: staffName
        } 
      });
    }



  }



}


</script>



<style lang="scss" scoped>
  
  .red {
    color: red;
  }
  .two-thirds {
    .button {
      &.is-loading {
        &.show-text {
          padding-left: 35px !important;
          color: #fff !important;
          margin-bottom: 2rem;
          &:after {
            left: 10px !important;
          }
        }
      }
    }
  }


</style>


