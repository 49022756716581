<template>
 
  <div>

    <div class="fleet-wrapper">

      <fleet-left-sidebar></fleet-left-sidebar>

      <div class="main-fleet-content">

      <fleet-scroller tab="Truck Uploads" backLink="fleet-edit-sms" backTitle="Edit SMS"></fleet-scroller>

        <br>
        <div class="before-icon"><font-awesome-icon icon="list" /></div>
        <h4 class="icon">Important Documents on <span>{{ currentTruckName }}</span></h4>

        <div class="clear"></div>


        <div v-if="myUploads.length < 20">
            <vue-dropzone
                ref="imgDropZone"
                id="customdropzone"
                :options="dropzoneOptions"
                @vdropzone-complete="afterComplete"
            ></vue-dropzone>
        </div>

                <!-- <div v-if="docImages.length > 0" class="image-div">
                        <div v-for="image in docImages" :key="image.src">
                            <img :src="image.src" class="image" />
                            <p>{{image.src}}</p>
                        </div>
                        </div> -->

        <div v-if="docUploads.length > 0" class="image-div">
            <div class="thumb" v-for="image in docUploads" :key="image.id">
                <div @click="preview(image.imageUrl)" class="image-wrap">
                    <div v-if="image.type == 'application/pdf'">
                        <img src="../assets/img/pdf-image-placeholder.png" />
                    </div>
                    <div v-else><img :src="image.imageUrl" /></div>
                </div>
                <p>{{ image.imageLabel }}</p>
                <span class="delete is-large" @click="deleteImage(image.id)"></span>
            </div>
        </div>

        <div v-else>No documents</div>

        <div class="clear"></div>

        <hr />

        <h4>Safety Data Sheets</h4>

        <!-- <div v-if="msdsImages.length > 0" class="image-div">
            <div v-for="image in msdsImages" :key="image.src">
                <img :src="image.src" class="image" />
                <p>{{image.src}}</p>
            </div>
        </div> -->

        <div v-if="msdsUploads.length > 0" class="image-div">
            <div class="thumb" v-for="image in msdsUploads" :key="image.src">
                <div @click="preview(image.imageUrl)" class="image-wrap">
                    <div v-if="image.type == 'application/pdf'">
                        <img src="../assets/img/pdf-image-placeholder.png" />
                    </div>
                    <div v-else><img :src="image.imageUrl" /></div>
                </div>
                <p>{{ image.imageLabel }}</p>
                <span class="delete is-large" @click="deleteImage(image.id)"></span>
            </div>
        </div>

        <div v-else>No Safety Data Sheets</div>

        <div class="clear"></div>

        <hr>
            
        <div class="buttons">
          <router-link to="fleet-edit-sms">
            <span class="button is-info">Back to SMS</span>
          </router-link>
        </div>

        <div class="spacer s90"></div>

       
            <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': saveImageModal }">
                <div class="modal-background" @click="cancelUpload"></div>
                    <div class="modal-card">
                    <header class="modal-card-head">
                        <p class="modal-card-title">File Details:</p>
                        <button
                        class="delete"
                        aria-label="close"
                        @click="cancelUpload"
                        ></button>
                    </header>
                    <section class="modal-card-body">
                        <p>What sort of file is this?</p>
                        <div class="select is-primary">
                            <select v-model="fileType">
                                <option disabled value="">Choose one</option>
                                <option :value="type.id" v-for="type in fileTypes" :key="type.id">
                                {{ type.name }}
                                </option>
                            </select>
                        </div>
                        <div class="spacer s20"></div>
                        <div v-if="fileType != ''">
                            <p>Name this file (optional)</p>
                            <div>
                                <input
                                class="input"
                                type="text"
                                placeholder="Type file name"
                                v-model="tempLabel"
                                />
                            </div>
                        </div>
                    </section>
                    <footer class="modal-card-foot">
                        <button
                            class="button is-primary"
                            :disabled="fileType == ''"
                            @click="saveImage"
                            >
                            Save
                        </button>
                        <button class="button cancel" @click="cancelUpload">Cancel</button>
                    </footer>
                </div>
            </div>

            <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': previewModal }" >
                <div class="modal-background" @click="close"></div>
                <div class="modal-card preview">
                <!-- <header class="modal-card-head">
                        <p class="modal-card-title">Search for a person:</p>
                        <button class="delete" aria-label="close" @click="close"></button>
                        </header> -->
                <section class="modal-card-body">
                    <iframe width="560" height="315" :src="selectedUrl" frameborder="0"></iframe>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click="close">Done</button>
                </footer>
                </div>
            </div>
      
      </div>







    </div>


  </div>



</template>

<script>

import { db } from "../main.js";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import NProgress from "nprogress";

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
let uuid = require("uuid");
const FleetLeftSidebar = () => import('../reusables/FleetLeftSidebar.vue');
const FleetScroller = () => import("../reusables/FleetScroller.vue");

// const StickyFooter = () => import('../reusables/StickyFooter.vue');

export default {
  name: "FleetTruckUploads",
  props: {
    truckID: String,
    truckName: String,
  },
  components: {
    // StickyFooter,
    vueDropzone: vue2Dropzone,
    FleetLeftSidebar,
    FleetScroller
  },
  mounted: function() {
    firebase.auth().onAuthStateChanged(this.onUserLogin);
  },
  computed: {
    userRole() {
      return this.$store.getters.getSubscriptionInfo.userrole;
    },
    msdsUploads() {
      return this.myUploads.filter((img) => img.cat == "msds");
    },
    docUploads() {
      return this.myUploads.filter((img) => img.cat == "doc");
    },
    currentTruckID() {
      return this.$store.getters.getCurrentTruckID;
    },
    currentTruckName() {
      return this.$store.getters.getCurrentTruckName;
    },
    currentTruckUVI() {
      return this.$store.getters.getCurrentTruckUVI;
    },
    currentFleet() {
      return this.$store.getters.getCurrentFleet;
    },
    userData() {
      return this.$store.getters.getSubscriptionInfo;
    },
  },
  data: function() {
    return {
      firebaseid: "",
      firebasedisplayname: '',
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        addRemoveLinks: false,
        acceptedFiles: ".jpg, .jpeg, .png, .pdf",
        dictDefaultMessage: `<p class='text-default'><i class='fa fa-cloud-upload mr-2'></i> Drag Images or Click Here</p>
            <p class="form-text">Allowed Files: .jpg, .jpeg, .png, .pdf</p>`,
        maxFilesize: 2,
      },
      msdsImages: [],
      docImages: [],
      myUploads: [],
      saveImageModal: false,
      fileTypes: [
        { id: "doc", name: "General Truck Document" },
        { id: "msds", name: "Safety Data Sheet" },
      ],
      fileType: "",
      currentUpload: "",
      tempLabel: "",
      previewModal: false,
      selectedUrl: "",
    };
  },

  methods: {
    onUserLogin: function(user) {
      if (user) {
        this.firebaseid = user.uid;
        this.firebasedisplayname = this.userData.username;
        this.loadUploads();
      }
    },

    loadUploads() {
        let self = this;
        db.collection("trucks").doc(this.currentTruckID).collection("uploads").onSnapshot(function(querySnapshot) {
            self.myUploads = [];
            querySnapshot.forEach((doc) => {
                self.myUploads.push({
                    id: doc.id,
                    imageUrl: doc.data().imageUrl,
                    imageLabel: doc.data().imageLabel,
                    type: doc.data().type,
                    cat: doc.data().cat,
                });
             });
        });
    },

    close() {
      this.previewModal = false;
      this.selectedUrl = "";
    },

    async saveImage() {
        NProgress.start();
        let self = this;
        this.saveImageModal = false;
        // console.log('Upload:',this.currentUpload);
        // console.log('Filetype',this.fileType);
        var imageName = uuid.v1();
        var imageLabel = this.tempLabel == "" ? "No name" : this.tempLabel;
        this.isLoading = true;

        try {
            //save image
            let file = self.currentUpload;
            var metadata = {
            // contentType: "image/png"
            };
            var storageRef = firebase.storage().ref();
            // var imageRef = storageRef.child(`images/${self.firebaseid}/${self.fileType}/${imageName}.png`);
            // var imageRef = storageRef.child(`images/${self.firebaseid}/${imageName}.png`);
            var imageRef = storageRef.child(`truck-uploads/${self.currentTruckID}/${imageName}.png`);
            await imageRef.put(file, metadata);
            var downloadURL = await imageRef.getDownloadURL();

            // if (self.fileType == 'doc') {
            //   self.docImages.push({ src: downloadURL });
            // } else {
            //   self.msdsImages.push({ src: downloadURL });
            // }

            db.collection("trucks").doc(self.currentTruckID).collection("uploads").doc(imageName).set({
                imageUrl: downloadURL,
                imageLabel: imageLabel,
                cat: self.fileType,
                type: self.currentUpload.type,
            })
            .then(function() {
                NProgress.done();
                var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
                addTruckRevision({
                  userName: self.firebasedisplayname,
                  userID: self.firebaseid,
                  truckName: self.currentTruckName,
                  truckID: self.currentTruckID,
                  revisionDetails: 'New document uploaded',
                });   
                self.fileType = "";
                self.tempLabel = "";
            });
        } catch (error) {
            console.log(error);
            NProgress.done();
            self.fileType = "";
            self.tempLabel = "";
        }
        this.$refs.imgDropZone.removeFile(this.currentUpload);
    },

    cancelUpload() {
      this.saveImageModal = false;
      this.$refs.imgDropZone.removeFile(this.currentUpload);
      this.currentUpload = "";
      this.fileType = "";
      this.tempLabel = "";
    },

    async afterComplete(upload) {
      this.currentUpload = upload;
      this.saveImageModal = true;

      // var imageName = uuid.v1();
      // this.isLoading = true;
      // try {
      //     //save image
      //     let file = upload;
      //     var metadata = {
      //       contentType: "image/png"
      //     };
      //     var storageRef = firebase.storage().ref();
      //     var imageRef = storageRef.child(`${fileType}/${imageName}.png`);
      //     await imageRef.put(file, metadata);
      //     var downloadURL = await imageRef.getDownloadURL();
      //     this[fileType].push({ src: downloadURL });

      // } catch (error) {
      //   console.log(error);
      // }
      // this.$refs.imgDropZone.removeFile(upload);
    },

    preview(url) {
      console.log("URL", url);
      this.selectedUrl = url;
      this.previewModal = true;
    },

    deleteImage(id) {
      let self = this;
      let message;
      message = {
        title: "Confirm Delete?",
        body: "This cannot be undone.",
      };
      let options = {
        okText: "DELETE",
        backdropClose: true,
        cancelText: "Cancel",
      };
      this.$dialog
        .confirm(message, options)
        .then(function() {
             NProgress.start();

            db.collection("trucks").doc(self.currentTruckID).collection("uploads").doc(id).delete()
            .then(function() {
              NProgress.done();
            });
        })
        .catch(function() {
            NProgress.done();
        });
    },
  }

};

</script>
<style lang="scss" scoped>

.site-wrapper .container, ul.scroller {
  max-width: 800px;
}


.image-div {
  margin: 2rem 0;

  .thumb {
    width: 30%;
    float: left;
    margin-right: 3%;
    text-align: center;
    // overflow: hidden;
    margin-bottom: 3rem;
    cursor: pointer;
    position: relative;

    div {
      overflow: hidden;
      background: #f4f4f4;
      border-radius: 5px;
    }

    img {
      display: block;
      height: 200px;
      max-width: none;
      padding: 0;
      margin: 0 auto;
    }

    p {
      width: 100%;
      text-transform: uppercase;
      margin-top: 10px;
    }

    .delete {
      position: absolute;
      top: -15px;
      right: -15px;
      background: #bbb;
    }
  }
}

.image {
  max-width: 250px;
  margin: 15px;
}

.vue-dropzone {
  margin-bottom: 3rem;
}

.modal-card-body {
  p {
    margin-bottom: 8px;
  }

  input[type="text"] {
    padding: 25px;
  }
}

h4 {
  text-align: left;
  font-size: 1.3rem;
  margin-bottom: 3rem;
}

.modal-card {
  &.preview {
    width: 90%;
    height: 100%;
    max-width: none;

    iframe {
      width: 100%;
      height: 100%;
    }

    .modal-card-body {
      padding: 20px 20px 0;
    }
  }
}

@media only screen and (max-width: 600px) {
  .image-div {
    .thumb {
      width: 50%;
      margin-right: 0;
      padding: 2rem;
      .delete {
        top: 15px;
        right: 15px;
      }
    }
  }
}

@media only screen and (max-width: 479px) {
  .image-div {
    .thumb {
      width: 100%;

      div {
        height: 220px;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
