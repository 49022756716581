<template>
 
  <div>

    <div class="fleet-wrapper">

      <fleet-left-sidebar></fleet-left-sidebar>

      <div class="main-fleet-content">

          <fleet-scroller tab="Truck Log" backLink="fleet-edit-sms" backTitle="Truck Details"></fleet-scroller>

            <br>

            <div class="before-icon"><font-awesome-icon icon="calendar-check" /></div><h4 class="icon">Truck Log on <span>{{currentTruckName}}</span></h4>  
            <div class="clear"></div>

            <div v-if="trucksLog.length > 0" class="">
              <div class="truck-date" v-for="log in trucksLog" :class="{expired: log.priority}" @click="addEditTrucksLog(log)" :key="log.id">
                <div class="date-icon"><span v-if="log.priority"><font-awesome-icon icon="exclamation-triangle" /></span><span v-else><font-awesome-icon icon="truck" /></span></div>
                <div class="date-details">
                  <div class="date-title">{{log.details}}</div>
                  <div class="due-date">Posted: {{log.remaining}} by {{log.postedBy}}</div>
                </div>
              </div>
            </div>

            <div v-else>
              <p>No logs saved yet.</p>
            </div>

            <div class="spacer s40"></div>

            <div class="buttons">
              <span class="button is-warning" @click="toArchivedLogs()">Archived Logs</span>
              <span class="button is-primary" @click="addEditTrucksLog('')">New Truck's Log</span>
            </div>

            <hr>
            
            <div class="buttons">
              <router-link to="fleet-edit-sms">
                <span class="button is-info">Back to SMS</span>
              </router-link>
            </div>

            <div class="spacer s90"></div>
        
      
      </div>







    </div>


  </div>



</template>

<script>

import { db } from "../main.js";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
// import NProgress from "nprogress";
const FleetLeftSidebar = () => import('../reusables/FleetLeftSidebar.vue');
import * as moment from "moment";
const FleetScroller = () => import("../reusables/FleetScroller.vue");

// const StickyFooter = () => import('../reusables/StickyFooter.vue');

export default {
  name: "FleetTruckLog",
  props: {
    truckID: String,
    truckName: String,
  },
  components: {
    FleetLeftSidebar,
    FleetScroller
  },
  mounted: function() {
    firebase.auth().onAuthStateChanged(this.onUserLogin);
  },
  computed: {
    userRole() {
      return this.$store.getters.getSubscriptionInfo.userrole;
    },
    currentTruckID() {
      return this.$store.getters.getCurrentTruckID;
    },
    currentTruckName() {
      return this.$store.getters.getCurrentTruckName;
    },
    currentTruckUVI() {
      return this.$store.getters.getCurrentTruckUVI;
    },
    currentFleet() {
      return this.$store.getters.getCurrentCompany;
    },
    userData() {
      return this.$store.getters.getSubscriptionInfo;
    },
  },
  data: function() {
    return {
      firebaseid: "",
      firebasedisplayname: '',
      trucksLog: [],
    };
  },

  methods: {
    onUserLogin: function(user) {
      if (user) {
        this.firebaseid = user.uid;
        this.firebasedisplayname = this.userData.username;
        this.loadTrucksLog();
      }
    },

    loadTrucksLog() {
      let self = this; 
      db.collection('trucks').doc(self.currentTruckID).collection('trucksLog').orderBy('logTimestamp','desc').limit(30).where('archived','==',false).onSnapshot(function(querySnapshot) {
        self.trucksLog = [];
        querySnapshot.forEach((doc) => {
          self.trucksLog.push({
            id: doc.id,
            details: doc.data().logDetails ? doc.data().logDetails : 'No details',
            logTimestamp: doc.data().logTimestamp ? doc.data().logTimestamp.toDate() : '',
            priority: doc.data().priority ? doc.data().priority : false,
            postedBy: doc.data().postedBy ? doc.data().postedBy : 'not recorded',
            remaining: moment().to(doc.data().logTimestamp.toDate()),

          })
        });
      });
    },

    addEditTrucksLog(log) {
      this.$router.push({ 
        name: 'AddEditTrucksLog', 
        params: { 
         sentLog : log == '' ? {} : log,
         editing: log == '' ? false : true,
         archived: false
        } 
      });
    },

    toArchivedLogs() {
      this.$router.push({ 
        name: 'FleetArchivedTrucksLogs', 
        // params: { 
        //  sentLog : log == '' ? {} : log,
        //  editing: log == '' ? false : true
        // } 
      });
    }

    



  }

};

</script>
<style lang="scss" scoped>

.site-wrapper .container, ul.scroller {
  max-width: 800px;
}


.modal-card-body {
  p {
    margin-bottom: 8px;
  }

  input[type="text"] {
    padding: 25px;
  }
}

h4 {
  text-align: center;
  font-size: 1.3rem;
  margin-bottom: 3rem;
}

.modal-card {
  &.preview {
    width: 90%;
    height: 100%;
    max-width: none;

    iframe {
      width: 100%;
      height: 100%;
    }

    .modal-card-body {
      padding: 20px 20px 0;
    }
  }
}

h4 {
  text-align: left;
  font-size: 1.3rem;
  margin-bottom: 3rem;
}
.truck-date-button {
  .button {
    font-size: 1rem;
    text-align: left;
    .icon {
      background: lightgrey;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: inline-block;
      text-align: center;
      position: relative;
      vertical-align: middle;
      color: #fff;
      font-size: 20px;
      margin-right: 0.5rem;
      svg {
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
      }
    }
    .due-date {
      display: block;
    }
  }
}
.truck-date {
  border-bottom: 1px solid #f4f4f4;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  text-align: left;
  .date-icon {
    font-size: 1.1rem;
    color: black;
    float: left;
    span {
      background: lightgrey;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: inline-block;
      text-align: center;
      position: relative;
      vertical-align: middle;
      color: #fff;
      font-size: 20px;
      margin-right: 0.5rem;
      transition: 0.3s;
      svg {
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
      }
    }
  }
  &.expired {
    .date-icon {
      span {
        background: red;
      }
    }
  }
  .date-details {
    .date-title {
      color: #000;
      font-size: 1.1rem;
    }
    .due-date {
      margin-left: 48px;
      font-size: 0.9rem;
    }
  }
  &:last-of-type {
    border-bottom: none;
  }
  &:hover {
    .date-icon {
      span {
        background: lightblue;
      }
    }
  }
}


</style>
