<template>

  <div>

    <div class="fleet-wrapper">
      
      <fleet-left-sidebar></fleet-left-sidebar>
      
      <div class="main-fleet-content">

        <fleet-scroller tab="Checklist" backLink="fleet-edit-sms" backTitle="Truck SMS"></fleet-scroller>

        <div v-if="currentTruckID">

          <div class="card">
            <br>
            <div class="before-icon"><font-awesome-icon icon="list" /></div><h4 class="icon">{{checkListName}} on <span>{{currentTruckName}}</span></h4>  
            <div class="clear"></div>
            <div v-if="checklistItems.length" class="card-content">
              <div v-for="item in sorted(checklistItems)" :key="item.title">
                <div class="notification">
                  <div class="item-title">{{item.title}}</div>
                  <div class="item-details">
                    <div v-for="line in item.details.split('\n')" :key="line">{{line}}<br></div>
                  </div>
                  <div class="buttons">
                    <!-- <span class="button is-success" @click="launchEditItem(item.id,item.title,item.details)">Edit</span>
                    <span class="button is-danger" @click="deleteItem(item.id)">Remove</span> -->
                    <span class="button is-info" @click="launchItemWindow(item.id,item.title,item.details,'Read')">Read</span>
                    <span class="button is-success" @click="launchItemWindow(item.id,item.title,item.details,'Edit')">Edit</span>
                    <span class="button is-danger" @click="deleteItem(item.id)">Delete</span>
                  </div>
                </div>  
              </div>
              <span class="simple-link right" @click="enableSortModal">Change order</span>
            </div>
            <div v-else><p>No items found.</p></div>
          </div>

          <div class="button-wrapper full">
            <!-- <button v-on:click="launchAddNewItem" class="button is-primary">Write a truck Item</button> 
            <button v-on:click="launchAddHelperItems" class="button is-primary">Add helper Items</button> -->
            <button @click="launchItemWindow('','','','Write New')" class="button is-primary">Write new item</button>   
          </div>

          <hr>

          <div class="button-wrapper full">
            <span @click="deleteChecklist()" class="button is-danger">Delete checklist</span>   
            <router-link to="fleet-edit-sms">
              <span class="button is-info">Back to SMS</span>
            </router-link>
          </div>

          <div class="spacer s20"></div>

          <div class="button-wrapper full">
            <span @click="copyChecklist()" class="button is-light">Copy to another truck</span>   
          </div>


        </div>

        <div v-else>

          <p>No truck detected.</p><p>&nbsp;</p>
          <router-link to="fleet-edit-sms">
            <span class="button is-info">Back to SMS</span>
          </router-link>
        </div>




          <div class="spacer s50"></div>




      <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':enableSort}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Drag to change order of items</p>
            <button class="delete" aria-label="close" @click.prevent="close"></button>
          </header>
          <section class="modal-card-body"> 

            <div v-if="checklistItems.length">

              <draggable v-model="checklistItems" class="sorting-list" group="people" @start="drag=true" @end="drag=false">
                <div v-for="(item, index) in checklistItems" :key="item.id">{{index + 1}} - {{item.title}}  <span>&#8597;</span></div>
              </draggable>

            </div>

          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" @click="saveNewOrder">Update</button>
            <button class="button cancel" @click.prevent="close">Cancel</button>
          </footer>
        </div>
      </div>




      <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showChooseTruck}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Choose a truck:</p>
              <button class="delete" aria-label="close" @click.prevent="close"></button>
            </header>
            <section class="modal-card-body"> 
                <div class="select is-primary">
                  <select v-model="selectedTruck">
                    <option disabled value="">Choose a Truck</option>
                    <option v-for="truck in filteredTrucks" :value="truck" :key="truck.truckName">
                      {{ truck.truckName }}
                    </option>
                  </select>
                </div>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" @click="saveCopyChecklist()">Copy Checklist</button>
              <button class="button cancel" @click.prevent="close">Cancel</button>
            </footer>
        </div>
      </div>





        <edit-item-window :item="selectedItem" v-if="showItemWindow == true" v-on:sendItem="returnedItem"></edit-item-window>









      </div>

    </div>

  </div>

</template>






<script>

import { db } from '../main.js';
import firebase from 'firebase/app';
import 'firebase/auth'; 
import 'firebase/firestore';
  // import 'firebase/functions';
  import NProgress from 'nprogress';

  import draggable from 'vuedraggable';
  import EditItemWindow from '../reusables/EditItemWindow.vue';
  const FleetScroller = () => import('../reusables/FleetScroller.vue');
  const FleetLeftSidebar = () => import('../reusables/FleetLeftSidebar.vue');

  export default {

    name: 'CustomChecklists',
    components: {
      draggable,
      EditItemWindow,
      FleetScroller,
      FleetLeftSidebar
    },
    props: {
      checklistID: String,
      checkListName: String,
    },
    mounted: function() {
      firebase.auth().onAuthStateChanged( this.onUserLogin );   
    },
    computed: {
      isUserAdmin() {
        return this.$store.getters.getIsUserAdmin;
      },
      currentTruckID() {
        return this.$store.getters.getCurrentTruckID;
      },
      currentTruckName() {
        return this.$store.getters.getCurrentTruckName;
      },
      currentTruckUVI() {
        return this.$store.getters.getCurrentTruckUVI;
      },
      userData() {
        return this.$store.getters.getSubscriptionInfo;
      },   
      currentFleet() {
        return this.$store.getters.getCurrentFleet;
      },
      filteredTrucks() {
        return this.fleetTrucks.filter((truck) => truck.truckID != this.currentTruckID);
      }
    },

    data: function() {
      return {
        msg: 'Custom Checklists',
        firebaseid: '',
        firebasedisplayname: '',
        checklistItems: [],
        // isActiveEditItemModal: false,
        enableSort: false,
        editedItem: {},
        // isActiveNewItemModal: false,
        newitem: {},
        selectedItem: {},
        showItemWindow: false,
        showChooseTruck: false,
        selectedTruck: '',
        fleetTrucks: [],
      }
    },

    methods: {

      onUserLogin: function( user ) {
        if (user) {         
          this.firebaseemail = user.email;
          this.firebasedisplayname = this.userData.username;
          this.firebaseid = user.uid;
          this.loadChecklistItems();
          this.loadFleetTrucks(); 
        }
      },

      loadChecklistItems() {
        let self = this;
        db.collection('trucks').doc(self.currentTruckID).collection('customChecklists').doc(self.checklistID).collection('checklistItems').onSnapshot(function(querySnapshot) {
          self.checklistItems = [];
          querySnapshot.forEach((doc) => {
            self.checklistItems.push({
              id: doc.id,
              title: doc.data().title,
              details: doc.data().details,
              index: doc.data().index ? doc.data().index : 999,
            });
          });
        });
      },

      loadFleetTrucks() {
        let self = this;
        db.collection('fleets').doc(this.currentFleet.id).collection('fleetTrucks').onSnapshot(function(querySnapshot) {
          self.fleetTrucks = [];
          querySnapshot.forEach((doc) => {
              self.fleetTrucks.push({
                truckID: doc.id,
                truckName: doc.data().truckName,
                truckUVI: doc.data().truckUVI,
              });
          });
        });
      },

      enableSortModal: function() {
        this.checklistItems = this.sorted(this.checklistItems);
        this.enableSort = true;
      },

      sorted: function(arr) {
        return arr.slice().sort(function(a, b) {
          return a.index - b.index;
        });
      },

      backToSms: function() {
        let self = this;
        //console.log(self.currentTruckID,self.currentTruckName,self.currentTruckUVI);
        this.$router.push({ name: 'FleetEditSms', params: { 
          selectedTruckID: self.currentTruckID,
          selectedTruckName: self.currentTruckName,
          selectedTruckUVI: self.currentTruckUVI,
        } });
      },

      launchItemWindow: function(itemID,itemTitle,itemDetails,action) {
        this.showItemWindow = true;
        this.selectedItem = {
          id: itemID,
          title: itemTitle,
          details: itemDetails,
          heading: 'Item',
          action: action
        };
      },

      close() {
        this.enableSort = false;
        this.showChooseTruck = false;
        this.selectedTruck = '';
      },

      saveNewOrder: function() {
        var self = this;
        NProgress.start();
        this.checklistItems.forEach(function(item, index) {
          db.collection('trucks').doc(self.currentTruckID).collection('customChecklists').doc(self.checklistID).collection('checklistItems').doc(item.id).update({
            index: index + 1
          })
          .then(() => {
              // self.checklistItems = self.sorted(self.checklistItems);
              self.loadChecklistItems();
            })
          .then(() => {
            self.enableSort = false;
            NProgress.done();
          })
          .catch(error => {
            console.error("Error adding alert: ", error);
            NProgress.done();
          });
        });   
      },

      copyChecklist() {
        // let listItems = this.checklistItems.map((item) => item.title).join(', ');
        this.showChooseTruck = true;
      },

      saveCopyChecklist() {
        let self = this;
        NProgress.start();
        console.log(this.selectedTruck);

        // let tester = {
        //   sourceTruckName: self.currentTruckName,
        //   sourceTruckID: self.currentTruckID,
        //   targetTruckName: self.selectedTruck.truckName,
        //   targetTruckID: self.selectedTruck.truckID,
        //   checkListID: self.checklistID,
        //   checkListName: self.checkListName,
        //   userName: self.userData.username,
        // }

        // console.log(tester);

        var copyCustomChecklist = firebase.functions().httpsCallable('copyCustomChecklist');
        copyCustomChecklist({
          sourceTruckName: self.currentTruckName,
          sourceTruckID: self.currentTruckID,
          targetTruckName: self.selectedTruck.truckName,
          targetTruckID: self.selectedTruck.truckID,
          checkListID: self.checklistID,
          checkListName: self.checkListName,
          userName: self.userData.username,
        });

        var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
        addTruckRevision({
          userName: self.userData.username,
          userID: self.firebaseid,
          truckName: self.currentTruckName,
          truckID: self.currentTruckID,
          revisionDetails: 'Custom checklist ' + self.checkListName + ' deleted',
        });

        setTimeout(function() {
          self.$router.push({ name: 'FleetTrucks', params: {} });
          NProgress.done();
        }, 2000);
        
      },

      deleteItem: function(itemID) {        
        var self = this;    
        let dialogueMsg = "<h3>Remove this Item?</h3>";
        let dialogueOptions = {
          html: true,
          //loader: true,
          okText: 'Yes, remove',
          cancelText: 'No, go back',
          message: 'This is a message',
          backdropClose: true
        };
        this.$dialog
        .confirm(dialogueMsg, dialogueOptions)
        .then(function() {
          NProgress.start();  
          db.collection('trucks').doc(self.currentTruckID).collection('customChecklists').doc(self.checklistID).collection('checklistItems').doc(itemID).delete()
          .then(function(){ 
            NProgress.done();
            var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
            addTruckRevision({
              userName: self.firebasedisplayname,
              userID: self.firebaseid,
              truckName: self.currentTruckName,
              truckID: self.currentTruckID,
              revisionDetails: 'Custom checklist item deleted',
            });
          });
        })
        .catch(function() {
          console.log('Clicked on cancel'); 
          NProgress.done();
        });
      },

      deleteChecklist: function() {     
      console.log(this.checkListName);   
        var self = this;    
        let dialogueMsg = "<h3>Delete this checklist?</h3>";
        let dialogueOptions = {
          html: true,
          //loader: true,
          okText: 'Yes, remove',
          cancelText: 'No, go back',
          message: 'This is a message',
          backdropClose: true
        };
        this.$dialog
        .confirm(dialogueMsg, dialogueOptions)
        .then(function() {
          NProgress.start();  
          db.collection('trucks').doc(self.currentTruckID).collection('customChecklists').doc(self.checklistID).delete()
          .then(function(){ 
            NProgress.done();
            var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
            addTruckRevision({
              userName: self.firebasedisplayname,
              userID: self.firebaseid,
              truckName: self.currentTruckName,
              truckID: self.currentTruckID,
              revisionDetails: 'Custom checklist ' + self.checkListName + ' deleted',
            })
            .then(function() {
              self.$router.push({ name: 'FleetEditSms', params: { 
                selectedTruckID: self.currentTruckID,
                selectedTruckName: self.currentTruckName,
                selectedTruckUVI: self.currentTruckUVI,
              } });
            });
          });
        })
        .catch(function() {
          console.log('Clicked on cancel'); 
          NProgress.done();
        });
      },

      returnedItem: function(returnItem) {

        // console.log(returnItem);
        
        this.showItemWindow = false;
        this.selectedItem = {};

        if (returnItem.edited) {
          NProgress.start();
          let self = this;
          if (returnItem.isNewItem) {
            db.collection('trucks').doc(self.currentTruckID).collection('customChecklists').doc(self.checklistID).collection('checklistItems').add({
              title: returnItem.title,
              details: returnItem.details,
              index: 0
            })
            .then(() => {
              NProgress.done();
              var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
              addTruckRevision({
                userName: self.firebasedisplayname,
                userID: self.firebaseid,
                truckName: self.currentTruckName,
                truckID: self.currentTruckID,
                revisionDetails: 'New custom checklist item added',
              });
            })
            .catch(error => {
              console.error("Error adding new item: ", error);
              NProgress.done();
            });
          } else {
            db.collection('trucks').doc(self.currentTruckID).collection('customChecklists').doc(self.checklistID).collection('checklistItems').doc(returnItem.id).update({
              title: returnItem.title,
              details: returnItem.details,
            })
            .then(() => {
              NProgress.done();
              var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
              addTruckRevision({
                userName: self.firebasedisplayname,
                userID: self.firebaseid,
                truckName: self.currentTruckName,
                truckID: self.currentTruckID,
                revisionDetails: 'Custom checklist item updated',
              });
            })
            .catch(error => {
              console.error("Error updating item: ", error);
              NProgress.done();
            });
          }
        } else {
          NProgress.done();
        }

      },



  }

}





</script>




<style lang="scss" scoped>


textarea {
  // min-height: 100px !important;
  box-shadow: none;
  padding: 0.6rem 1rem;
}

.checkbox-wrapper {
  margin-bottom: 1rem;
  width: 100%;
  border-bottom: 1px dashed #eee;
  padding-bottom: 10px;
  .checkbox-content {
    float: left;
    width: calc(100% - 90px);
  }
}
.header-image {
  // background: url('../assets/img/placeholder-2.jpg') no-repeat center center;
  color: #fff;
}


</style>