<template>
 
  <div>

    <div class="fleet-wrapper">

      <fleet-left-sidebar></fleet-left-sidebar>

      <div class="main-fleet-content">

          <fleet-scroller tab="Important Dates" backLink="fleet-edit-sms" backTitle="Truck"></fleet-scroller>

            <br>

            <div class="before-icon"><font-awesome-icon icon="calendar-check" /></div><h4 class="icon">Important Dates on <span>{{currentTruckName}}</span></h4>  
            <div class="clear"></div>

            <div v-if="truckDates.length > 0" class="">
              <div class="truck-date" v-for="date in truckDates" :class="{expired: date.past}" @click="addEditDate(date)" :key="date.id">
                <div class="date-icon"><span v-if="date.past"><font-awesome-icon icon="exclamation-triangle" /></span><span v-else><font-awesome-icon icon="calendar-check" /></span></div>
                <div class="date-details">
                  <div class="date-title">{{date.title}}</div>
                  <div class="due-date">Due: {{formatDate(date.expiryDate, "D MMMM YYYY")}} ({{date.remaining}})</div>
                  <!-- <div class="due-date"> ({{date.remaining}})</div> -->
                </div>
              </div>
              <!-- <div class="truck-date-button" v-for="date in truckDates">
                <div class="button is-medium is-light"><div class="icon"><font-awesome-icon icon="calendar-check" /></div>{{date.title}}<br><div class="due-date">Due: {{formatDate(date.expiryDate, "D MMMM YYYY")}}</div></div>
              </div> -->
            </div>

            <div v-else>
              <p>No Dates saved yet.</p>
            </div>

            <div class="spacer s40"></div>

            <div class="buttons">
              <span class="button is-primary" @click="addEditDate('')">Add a date</span>
            </div>

            <hr>
            
            <div class="buttons">
              <router-link to="fleet-edit-sms">
                <span class="button is-info">Back to Truck</span>
              </router-link>
            </div>

            <div class="spacer s90"></div>
        
      
      </div>







    </div>


  </div>



</template>

<script>

import { db } from "../main.js";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
// import NProgress from "nprogress";
const FleetLeftSidebar = () => import('../reusables/FleetLeftSidebar.vue');
import * as moment from "moment";
const FleetScroller = () => import("../reusables/FleetScroller.vue");

// const StickyFooter = () => import('../reusables/StickyFooter.vue');

export default {
  name: "FleetTruckDates",
  props: {
    truckID: String,
    truckName: String,
  },
  components: {
    FleetLeftSidebar,
    FleetScroller
  },
  mounted: function() {
    firebase.auth().onAuthStateChanged(this.onUserLogin);
  },
  computed: {
    userRole() {
      return this.$store.getters.getSubscriptionInfo.userrole;
    },
    currentTruckID() {
      return this.$store.getters.getCurrentTruckID;
    },
    currentTruckName() {
      return this.$store.getters.getCurrentTruckName;
    },
    currentTruckUVI() {
      return this.$store.getters.getCurrentTruckUVI;
    },
    currentFleet() {
      return this.$store.getters.getCurrentFleet;
    },
    userData() {
      return this.$store.getters.getSubscriptionInfo;
    },
  },
  data: function() {
    return {
      firebaseid: "",
      firebasedisplayname: '',
      truckDates: [],
      showAddDate: false,
    };
  },

  methods: {
    onUserLogin: function(user) {
      if (user) {
        this.firebaseid = user.uid;
        this.firebasedisplayname = this.userData.username;
        this.loadTruckDates();
      }
    },

    close() {
      this.showAddDate = false;
    },

    loadTruckDates() {
      let self = this; 
      db.collection('trucks').doc(self.currentTruckID).collection('importantDates').orderBy('expiryDate','asc').onSnapshot(function(querySnapshot) {
        self.truckDates = [];
        querySnapshot.forEach((doc) => {
          let tempDate = doc.data().expiryDate ? moment(doc.data().expiryDate.toDate()) : moment();
          self.truckDates.push({
            id: doc.id,
            dateType: doc.data().dateType ? doc.data().dateType : '',
            details: doc.data().details ? doc.data().details : '',
            expiryDate: doc.data().expiryDate ? doc.data().expiryDate.toDate() : '',
            humanDate: doc.data().humanDate ? doc.data().humanDate : '',
            logDate: doc.data().logDate ? doc.data().logDate.toDate() : '',
            title: doc.data().title ? doc.data().title : '',
            remaining: moment().to(doc.data().expiryDate.toDate()),
            past: moment().isAfter(tempDate),
          })
        });
      });
    },

    savenewDate() {
      console.log('Saving new date..');
    },

    formatDate : function (sentDate,format) {
        // let fixDate = (sentDate ? sentDate.toDate() : new Date());
        // return moment(fixDate).format(format);
        return moment(sentDate).format(format);
    },

    addEditDate(date) {
      this.$router.push({ 
        name: 'AddEditDate', 
        params: { 
          sentDate : date == '' ? {} : date,
          editing: date == '' ? false : true,
          archived: false
        } 
      });
    }

    



  }

};

</script>
<style lang="scss" scoped>

.site-wrapper .container, ul.scroller {
  max-width: 800px;
}


.modal-card-body {
  p {
    margin-bottom: 8px;
  }

  input[type="text"] {
    padding: 25px;
  }
}

h4 {
  text-align: center;
  font-size: 1.3rem;
  margin-bottom: 3rem;
}

.modal-card {
  &.preview {
    width: 90%;
    height: 100%;
    max-width: none;

    iframe {
      width: 100%;
      height: 100%;
    }

    .modal-card-body {
      padding: 20px 20px 0;
    }
  }
}

h4 {
  text-align: left;
  font-size: 1.3rem;
  margin-bottom: 3rem;
}
.truck-date-button {
  .button {
    font-size: 1rem;
    text-align: left;
    .icon {
      background: lightgrey;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: inline-block;
      text-align: center;
      position: relative;
      vertical-align: middle;
      color: #fff;
      font-size: 20px;
      margin-right: 0.5rem;
      svg {
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
      }
    }
    .due-date {
      display: block;
    }
  }
}
.truck-date {
  border-bottom: 1px solid #f4f4f4;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  text-align: left;
  .date-icon {
    font-size: 1.1rem;
    color: black;
    float: left;
    span {
      background: lightgrey;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: inline-block;
      text-align: center;
      position: relative;
      vertical-align: middle;
      color: #fff;
      font-size: 20px;
      margin-right: 0.5rem;
      svg {
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
      }
    }
  }
  &.expired {
    .date-icon {
      span {
        background: red;
      }
    }
  }
  .date-details {
    .date-title {
      color: #000;
      font-size: 1.1rem;
    }
    .due-date {
      margin-left: 48px;
      font-size: 0.9rem;
    }
  }
  &:last-of-type {
    border-bottom: none;
  }
}


</style>
