<template>
 
  <div>

    <div class="fleet-wrapper">

      <fleet-left-sidebar></fleet-left-sidebar>

      <div class="main-fleet-content">

          <fleet-scroller tab="Daily Tasks" backLink="fleet-edit-sms" backTitle="Truck"></fleet-scroller>

            <br>

            <div class="before-icon"><font-awesome-icon icon="calendar-check" /></div><h4 class="icon">Daily Tasks on <span>{{currentTruckName}}</span></h4>  
            <div class="clear"></div>

            <div v-if="dailyTasks.length > 0" class="">
              <div class="truck-date current" v-for="(task, index) in dailyTasks" @click="addEditTask(task)" :key="index">
                <!-- <div v-if="task.priority == 'Low'" class="task-icon" :class="task.priority"><span>L</span></div>
                <div v-if="task.priority == 'Medium'" class="task-icon" :class="task.priority"><span>M</span></div>
                <div v-if="task.priority == 'High'" class="task-icon" :class="task.priority"><span>H</span></div> -->
                <div class="task-icon" :class="task.status">
                  <span class="active" v-if="task.status == 'Active'"><font-awesome-icon icon="stopwatch" /></span>
                  <span class="pending" v-else><font-awesome-icon icon="wrench" /></span>
                </div>

                <div class="date-details">
                  <div class="date-title">{{task.title}} <span class="priority" v-if="task.priority">(PRIORITY)</span></div>
                  <div class="due-date">Posted: {{formatDate(task.timestamp, "D MMMM YYYY")}} ({{task.remaining}})</div>
                </div>
              </div>
            </div>

            <div v-else>
              <p>No tasks saved yet.</p>
            </div>

            <div class="spacer s40"></div>

            <div class="buttons">
              <span class="button is-warning" @click="toArchivedTasks()">Archived Tasks</span>
              <span class="button is-primary" @click="addEditTask('')">New Daily Task</span>
            </div>

            <hr>
            
            <div class="buttons">
              <router-link to="fleet-edit-sms">
                <span class="button is-info">Back to Truck</span>
              </router-link>
            </div>

            <div class="spacer s90"></div>
        
      
      </div>







    </div>


  </div>



</template>

<script>

import { db } from "../main.js";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
// import NProgress from "nprogress";
const FleetLeftSidebar = () => import('../reusables/FleetLeftSidebar.vue');
import * as moment from "moment";
const FleetScroller = () => import("../reusables/FleetScroller.vue");

// const StickyFooter = () => import('../reusables/StickyFooter.vue');

export default {
  name: "FleetTruckDailyTasks",
  props: {
    truckID: String,
    truckName: String,
  },
  components: {
    FleetLeftSidebar,
    FleetScroller
  },
  mounted: function() {
    firebase.auth().onAuthStateChanged(this.onUserLogin);
  },
  computed: {
    userRole() {
      return this.$store.getters.getSubscriptionInfo.userrole;
    },
    currentTruckID() {
      return this.$store.getters.getCurrentTruckID;
    },
    currentTruckName() {
      return this.$store.getters.getCurrentTruckName;
    },
    currentTruckUVI() {
      return this.$store.getters.getCurrentTruckUVI;
    },
    currentFleet() {
      return this.$store.getters.getCurrentFleet;
    },
    userData() {
      return this.$store.getters.getSubscriptionInfo;
    },
  },
  data: function() {
    return {
      firebaseid: "",
      firebasedisplayname: '',
      dailyTasks: [],
    };
  },

  methods: {
    onUserLogin: function(user) {
      if (user) {
        this.firebaseid = user.uid;
        this.firebasedisplayname = this.userData.username;
        this.loadDailyTasks();
      }
    },

    formatDate : function (sentDate,format) {
      return moment(sentDate).format(format);
    },

    loadDailyTasks() {
      let self = this; 
      db.collection('trucks').doc(self.currentTruckID).collection('dailyTasks').orderBy('timestamp','desc').limit(30).where('archived','==',false).onSnapshot(function(querySnapshot) {
        self.dailyTasks = [];
        querySnapshot.forEach((doc) => {
          self.dailyTasks.push({
            id: doc.id,
            title: doc.data().title ? doc.data().title : 'No title',
            details: doc.data().details ? doc.data().details : 'No details',
            timestamp: doc.data().timestamp ? doc.data().timestamp.toDate() : '',
            priority: doc.data().priority ? doc.data().priority : false,
            remaining: moment().to(doc.data().timestamp.toDate()),
            status: doc.data().status ? doc.data().status : '-',
            acceptedBy: doc.data().acceptedBy ? doc.data().acceptedBy : '',
            acceptedAt: doc.data().acceptedAt ? doc.data().acceptedAt.toDate() : '',
            // postedBy: doc.data().postedBy ? doc.data().postedBy : 'not recorded',

          })
        });
      });
    },

    addEditTask(task) {
      this.$router.push({ 
        name: 'AddEditTask', 
        params: { 
         sentTask : task == '' ? {} : task,
         editing: task == '' ? false : true,
         archived: false,
         singleTruck: true
        } 
      });
    },

    toArchivedTasks() {
      this.$router.push({ 
        name: 'FleetArchivedDailyTasks', 
        // params: { 
        //  sentLog : log == '' ? {} : log,
        //  editing: log == '' ? false : true
        // } 
      });
    }

    



  }

};

</script>
<style lang="scss" scoped>

.site-wrapper .container, ul.scroller {
  max-width: 800px;
}


.modal-card-body {
  p {
    margin-bottom: 8px;
  }

  input[type="text"] {
    padding: 25px;
  }
}

h4 {
  text-align: center;
  font-size: 1.3rem;
  margin-bottom: 3rem;
}

.modal-card {
  &.preview {
    width: 90%;
    height: 100%;
    max-width: none;

    iframe {
      width: 100%;
      height: 100%;
    }

    .modal-card-body {
      padding: 20px 20px 0;
    }
  }
}

h4 {
  text-align: left;
  font-size: 1.3rem;
  margin-bottom: 3rem;
}
.truck-date-button {
  .button {
    font-size: 1rem;
    text-align: left;
    .icon {
      background: lightgrey;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: inline-block;
      text-align: center;
      position: relative;
      vertical-align: middle;
      color: #fff;
      font-size: 20px;
      margin-right: 0.5rem;
      svg {
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
      }
    }
    .due-date {
      display: block;
    }
  }
}
.truck-date {
  border-bottom: 1px solid #f4f4f4;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  text-align: left;
  .task-icon {
    font-size: 1.1rem;
    color: black;
    float: left;
    span {
      background: lightgrey;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: inline-block;
      text-align: center;
      position: relative;
      vertical-align: middle;
      color: #fff;
      font-size: 20px;
      margin-right: 0.5rem;
      transition: 0.3s;
      line-height: 38px;
      font-weight: bold;
      svg {
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
      }
      &.active {
        background: orange;
      }
      &.pending {
        background: cornflowerblue;
      }
    }
  }
  &.current {
    .task-icon {
      &.Low {
        span {
          background: green;
        }
      }
      &.Medium {
        span {
          background: orange;
        }
      } 
      &.High {
        span {
          background: red;
        }
      } 
    }
  }
  .date-details {
    .date-title {
      color: #000;
      font-size: 1.1rem;
      span {
        color: red;
      }
    }
    .due-date {
      margin-left: 48px;
      font-size: 0.9rem;
    }
  }
  &:last-of-type {
    border-bottom: none;
  }
  &:hover {
    .task-icon {
      span {
        background: lightblue;
      }
    }
  }
}


</style>
