<template>
 
  <div>

    <div class="fleet-wrapper">

      <fleet-left-sidebar></fleet-left-sidebar>

      <div class="main-fleet-content">

        <div class="before-icon"><font-awesome-icon icon="list" /></div>
        <h4 class="icon">All SMS Activity for {{currentFleet.name}}</h4>
        <div class="clear"></div>

        <!-- <p>Now: {{formatDate(now,'D MMMM (h:mm a)')}}</p> -->

        <div v-if="allRevisions.length">
          <ul class="revisions">
            <li v-for="(item, index) in sortedRevisions" :class="{current: getHoursPassed(item.timestamp) < 6}" :key="index">{{item.revision}} on truck <span class="caps">{{item.truckName}}</span> by {{item.userName}} - {{formatDate(item.timestamp,'D MMMM (h:mm a)')}}</li>
          </ul>
        </div>
        <div v-else>
          <p>No activity at the moment.</p>
        </div>

        <div class="spacer s100">
          
        </div>
       

      </div>







    </div>


  </div>



</template>

<script>

import { db } from "../main.js";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
// import NProgress from "nprogress";
const FleetLeftSidebar = () => import('../reusables/FleetLeftSidebar.vue');
import * as moment from 'moment';

// const StickyFooter = () => import('../reusables/StickyFooter.vue');

export default {
  props: {
    truckID: String,
    truckName: String,
  },
  components: {
    FleetLeftSidebar,
  },
  mounted: function() {
    firebase.auth().onAuthStateChanged(this.onUserLogin);
  },
  computed: {
    userRole() {
      return this.$store.getters.getSubscriptionInfo.userrole;
    },
    currentTruckID() {
      return this.$store.getters.getCurrentTruckID;
    },
    currentTruckName() {
      return this.$store.getters.getCurrentTruckName;
    },
    currentTruckUVI() {
      return this.$store.getters.getCurrentTruckUVI;
    },
    currentFleet() {
      return this.$store.getters.getCurrentFleet;
    },
    sortedRevisions() {
      return this.allRevisions.slice().sort((a, b) => b.timestamp - a.timestamp);
    },
    now() {
      return moment();
    }
  },
  data: function() {
    return {
      firebaseid: "",
      allTrucks: [],
      allRevisions: [],
    };
  },

  methods: {
    onUserLogin: function(user) {
      if (user) {
        this.firebaseid = user.uid;
        this.loadAllTrucks();
      }
    },

    formatDate : function (sentDate,format) {
      let fixDate = (sentDate ? sentDate.toDate() : new Date());
      return moment(fixDate).format(format);
    },

    getHoursPassed(timestamp) {
      // console.log(timestamp.toDate());
      let end = moment();
      let start = moment(timestamp.toDate());
      let duration = moment.duration(end.diff(start));
      // console.log(duration);
      return Math.round(duration.asHours());
    },

    loadAllTrucks: function() {
      let self = this;

        db.collection('fleets').doc(self.currentFleet.id).collection('fleetTrucks').get().then(function(querySnapshot) {
            self.truckCount = querySnapshot.size;
            self.barOneVal = querySnapshot.size;
            self.allTrucks = [];
            querySnapshot.forEach((doc) => {
              self.allTrucks.push(doc.id);
            });
        })
        .then(function() {
          self.loadRevisions();
        });

    },

    async loadRevisions() {
      let self = this;
      self.allRevisions = [];
      // let tempRevisions = [];
      // await self.allTrucks.forEach((truck) => {
      //     db.collection('trucks').doc(truck).collection('truckRevisions').orderBy('timestamp','desc').limit(100).onSnapshot(function(querySnapshot) {
      //       tempRevisions = [];
      //       querySnapshot.forEach((doc) => {
      //         // console.log(doc.data());
      //         //self.allRevisions.push(doc.data());
      //         tempRevisions.push(doc.data());
      //       });
      //       // console.log('TT',tempRevisions);
      //       self.allRevisions = self.allRevisions.concat(tempRevisions);
      //     });
      // });

      self.allTrucks.forEach((truck) => {
        db.collection('trucks').doc(truck).collection('truckRevisions').orderBy('timestamp','desc').limit(100).onSnapshot(function(querySnapshot) {
          // self.allRevisions = [];
          querySnapshot.forEach((doc) => {
            // console.log(doc.data());
            self.allRevisions.push(doc.data());
          });
        });
      });

    }

  }

};

</script>
<style lang="scss" scoped>

.site-wrapper .container, ul.scroller {
  max-width: 800px;
}
ul.revisions {
  list-style: disc inside;
  margin-left: 8px;
  li {
    text-indent: -23px;
    margin-left: 20px;
    margin-bottom: 10px;
    &.current {
      color: #3273dc;
    }
  }
}




</style>
