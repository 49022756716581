<template>
  
  <div>

    <div class="fleet-wrapper">

      <fleet-left-sidebar></fleet-left-sidebar>

      <div class="main-fleet-content">
            
          <div class="before-icon"><font-awesome-icon icon="clipboard-list" /></div><h4 class="icon">Fleet Reports</h4>  
          <div class="clear"></div>

          <transition name="list">
            <div v-show="showLogSelector" class="log-selector">
              <div class="report-step-1">

                <h6>Step 1: Choose a Date Range</h6>

                <div class="select is-primary">
                  <select v-model="rangeType">
                    <option disabled value="">Select a Period</option>
                    <option v-for="range in rangeTypes" :value="range" :key="range.slug">
                      {{ range.name }}
                    </option>
                  </select>
                </div>

                <div v-show="rangeType.slug == 'custom-range'" class="custom-dates">
                  
                  <div class="date-column">   
                    <label>From</label>
                    <datetime v-model="startDate" input-id="startingDate" input-style="width: 180px;" input-class="my-input-class"></datetime>   
                  </div>
                  <div class="date-column">   
                    <label>To</label>
                    <datetime v-model="endDate" input-id="endingDate" input-style="width: 180px;" input-class="my-input-class"></datetime>   
                  </div>

                  <div class="clear"></div>
                  <div class="spacer s10"></div>

                  <div v-if="rangeIsValid" class="">
                    ({{dateRange}} {{dateRange == 1 ? 'day' : 'days'}})
                  </div>
                  <div v-else class="red">
                    <font-awesome-icon icon="exclamation-triangle" /> &nbsp; Please check the dates.
                  </div>

                </div>

              </div>

              <div class="clear"></div>


              <div v-show="rangeType != '' && rangeIsValid" class="report-step-2">

                <div class="spacer s30"></div>

                <h6>Step 2: Choose a Truck</h6>

                <div class="select is-primary">
                  <select v-model="selectedTruck">
                    <option disabled value="">Select a Truck</option>
                    <option v-for="truck in allTrucks" :value="truck" :key="truck.truckID">
                      {{ truck.truckName }}
                    </option>
                  </select>
                </div>

                <div class="">
                  
                </div>

              </div>

              <div class="clear"></div>


              <div v-show="selectedTruck != '' && rangeIsValid" class="report-step-3">

                <div class="spacer s30"></div>

                <h6>Step 3: Choose a Log</h6>

                <div class="select is-primary">
                  <select v-model="selectedLog">
                    <option disabled value="">Choose a Log</option>
                    <option v-for="log in logTypes" :value="log" :key="log">
                      {{ log }}
                    </option>
                  </select>
                </div>

                <div class="">
                  
                </div>

              </div>


              <div v-show="selectedLog != '' && rangeIsValid" class="report-step-4">

                <div class="spacer s30"></div>

                <div class="buttons">
                  <span class="button is-primary" @click="createLog()"><font-awesome-icon icon="clipboard-list" /> &nbsp; Create Log</span>
                </div>

              </div>




            </div>
          </transition>


          <div v-show="!showLogSelector" class="displayedLogs">
            
            <div class="buttons">
              <span @click="showLogSelector = true" class="button is-warning"><font-awesome-icon icon="pen" /> &nbsp; Edit log details</span>
              <span @click="resetLog()" class="button is-danger"><font-awesome-icon icon="trash-alt" /> &nbsp; Reset log</span>
            </div>

            <hr>

            <!-- Activity Logs ================================================================ -->
            <div v-if="showActivityLogs" class="logTable activity">

              <h6>{{selectedLog}} Report for {{selectedTruck.truckName}} ({{rangeType.name}})</h6>
              
              <table style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;" class="table is-bordered is-fullwidth">
                <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
                  <tr>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Date</th>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Activity</th>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Person</th>
                  </tr>

                  <tr v-for="item in allActivity" :key="item.id">
                    <td style="font-size: 0.9rem; border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{formatDate(item.timestamp,'D MMM YYYY (h:mm a)')}}</td>
                    <td style="width: 50% !important;font-size: 0.9rem; border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{item.revision}}</td>
                    <td style="width: 50% !important;font-size: 0.9rem; border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{item.userName}}</td>
                  </tr>

                  <tr v-if="allActivity.length == 0"><td colspan="3">No logs for that period.</td></tr>

                </tbody>
              </table>

              <div v-if="allActivity.length > 0" class="buttons">
                <span class="button is-light"><font-awesome-icon icon="print" /> &nbsp; Print Report</span>
              </div>

            </div>

            <!-- Service Logs ================================================================ -->
            <div v-if="showServiceLogs" class="logTable services">

              <h6>{{selectedLog}} Report for {{selectedTruck.truckName}} ({{rangeType.name}})</h6>
              
              <table style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;" class="table is-bordered is-fullwidth">
                <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
                  <tr>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Date</th>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Service</th>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Mileage</th>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Logged By</th>
                  </tr>

                  <tr v-for="item in allServices" :key="item.id">
                    <td style="font-size: 0.9rem; border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{formatDate(item.timestamp,'D MMM YYYY (h:mm a)')}}</td>
                    <td style="width: 50% !important;font-size: 0.9rem; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{item.serviceName}}</td>
                    <td style="width: 50% !important;font-size: 0.9rem; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{item.mileage}}</td>
                    <td style="width: 50% !important;font-size: 0.9rem; border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{item.postedBy}}</td>
                  </tr>

                  <tr v-if="allServices.length == 0"><td colspan="4">No logs for that period.</td></tr>

                </tbody>
              </table>

              <div v-if="allServices.length > 0" class="buttons">
                <span class="button is-light"><font-awesome-icon icon="print" /> &nbsp; Print Report</span>
              </div>

            </div>

            <!-- Prestart Logs ================================================================ -->
            <div v-if="showPrestartLogs" class="logTable services">

              <h6>{{selectedLog}} Report for {{selectedTruck.truckName}} ({{rangeType.name}})</h6>
              
              <table style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;" class="table is-bordered is-fullwidth">
                <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
                  <tr>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Date</th>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Items Checked</th>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Mileage</th>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Logged By</th>
                  </tr>

                  <tr v-for="item in allPrestarts" :key="item.id">
                    <td style="font-size: 0.9rem; border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{formatDate(item.timestamp,'D MMM YYYY (h:mm a)')}}</td>
                    <td style="width: 50% !important;font-size: 0.9rem; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{item.itemCount}}</td>
                    <td style="width: 50% !important;font-size: 0.9rem; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{item.mileage}}</td>
                    <td style="width: 50% !important;font-size: 0.9rem; border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{item.postedBy}}</td>
                  </tr>

                  <tr v-if="allPrestarts.length == 0"><td colspan="4">No logs for that period.</td></tr>

                </tbody>
              </table>

              <div v-if="allPrestarts.length > 0" class="buttons">
                <span class="button is-light"><font-awesome-icon icon="print" /> &nbsp; Print Report</span>
              </div>

            </div>

            <!-- Refuelling Logs ================================================================ -->
            <div v-if="showRefuellingLogs" class="logTable services">

              <h6>{{selectedLog}} Report for {{selectedTruck.truckName}} ({{rangeType.name}})</h6>
              
              <table style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;" class="table is-bordered is-fullwidth">
                <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
                  <tr>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Date</th>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Fuel Added</th>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Mileage</th>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Logged By</th>
                  </tr>

                  <tr v-for="item in allRefuellingLogs" :key="item.id">
                    <td style="font-size: 0.9rem; border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{formatDate(item.timestamp,'D MMM YYYY (h:mm a)')}}</td>
                    <td style="width: 50% !important;font-size: 0.9rem; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{item.fuelAdded}}</td>
                    <td style="width: 50% !important;font-size: 0.9rem; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{item.mileage}}</td>
                    <td style="width: 50% !important;font-size: 0.9rem; border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{item.postedBy}}</td>
                  </tr>

                  <tr v-if="allRefuellingLogs.length == 0"><td colspan="4">No logs for that period.</td></tr>


                </tbody>
              </table>

              <div v-if="allRefuellingLogs.length > 0" class="buttons">
                <span class="button is-light"><font-awesome-icon icon="print" /> &nbsp; Print Report</span>
              </div>

            </div>


            <!-- Custom Logs ================================================================ -->
            <div v-if="showCustomLogs" class="logTable services">

              <h6>{{selectedLog}} Report for {{selectedTruck.truckName}} ({{rangeType.name}})</h6>
              
              <table style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;" class="table is-bordered is-fullwidth">
                <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
                  <tr>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Date</th>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Log Name</th>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Items Checked</th>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Mileage</th>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Logged By</th>
                  </tr>

                  <tr v-for="item in allCustomLogs" :key="item.id">
                    <td style="font-size: 0.9rem; border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{formatDate(item.timestamp,'D MMM YYYY (h:mm a)')}}</td>
                    <td style="width: 50% !important;font-size: 0.9rem; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{item.checklistName}}</td>
                    <td style="width: 50% !important;font-size: 0.9rem; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{item.itemCount}}</td>
                    <td style="width: 50% !important;font-size: 0.9rem; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{item.mileage}}</td>
                    <td style="width: 50% !important;font-size: 0.9rem; border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{item.postedBy}}</td>
                  </tr>

                  <tr v-if="allCustomLogs.length == 0"><td colspan="5">No logs for that period.</td></tr>

                </tbody>
              </table>

              <div v-if="allCustomLogs.length > 0" class="buttons">
                <span class="button is-light"><font-awesome-icon icon="print" /> &nbsp; Print Report</span>
              </div>

            </div>



            <!-- Trucks Logs ================================================================ -->
            <div v-if="showTrucksLogs" class="logTable services">

              <h6>{{selectedLog}} Report for {{selectedTruck.truckName}} ({{rangeType.name}})</h6>
              
              <table style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;" class="table is-bordered is-fullwidth">
                <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
                  <tr>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Date</th>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Details</th>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Logged By</th>
                  </tr>

                  <tr v-for="item in allTrucksLogs" :key="item.id">
                    <td style="font-size: 0.9rem; border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{formatDate(item.timestamp,'D MMM YYYY (h:mm a)')}}</td>
                    <td style="width: 50% !important;font-size: 0.9rem; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{item.details}}</td>
                    <td style="width: 50% !important;font-size: 0.9rem; border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{item.postedBy}}</td>
                  </tr>

                  <tr v-if="allTrucksLogs.length == 0"><td colspan="3">No logs for that period.</td></tr>

                </tbody>
              </table>

              <div v-if="allTrucksLogs.length > 0" class="buttons">
                <span class="button is-light"><font-awesome-icon icon="print" /> &nbsp; Print Report</span>
              </div>

            </div>




            <!-- Daily Tasks ================================================================ -->
            <div v-if="showDailyTasks" class="logTable services">

              <h6>{{selectedLog}} Report for {{selectedTruck.truckName}} ({{rangeType.name}})</h6>
              
              <table style="width: 100%;margin-bottom: 1.5rem;table-layout:fixed;margin-top:1rem;color:#363636;border-collapse:collapse;border-spacing:0;box-sizing:border-box;display:table;border-color:grey;" class="table is-bordered is-fullwidth">
                <tbody style="box-sizing:inherit;display:table-row-group;vertical-align:middle;border-color:inherit;">
                  <tr>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Date</th>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Task</th>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Accepted By</th>
                    <th style="font-size: 0.9rem;font-weight: bold !important; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">Status</th>
                  </tr>

                  <tr v-for="item in allDailyTasks" :key="item.id">
                    <td style="font-size: 0.9rem; border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{formatDate(item.timestamp,'D MMM YYYY (h:mm a)')}}</td>
                    <td style="width: 50% !important;font-size: 0.9rem; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{item.title}}</td>
                    <td style="width: 50% !important;font-size: 0.9rem; border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{item.acceptedBy}}</td>
                    <td style="width: 50% !important;font-size: 0.9rem; text-transform: uppercase !important;border: 1px solid #d4d4d4 !important;padding: 8px 12px;font-family: 'Open Sans', sans-serif;  text-align: left !important;">{{item.status}}</td>
                  </tr>

                  <tr v-if="allDailyTasks.length == 0"><td colspan="4">No tasks for that period.</td></tr>

                </tbody>
              </table>

              <div v-if="allDailyTasks.length > 0" class="buttons">
                <span class="button is-light"><font-awesome-icon icon="print" /> &nbsp; Print Report</span>
              </div>

            </div>








          </div>

          
        


          <div class="clear"></div>
          <div class="spacer s90"></div>

      </div>

      

      



    </div>


  </div>

</template>



<script>
  
import { db } from '../main.js';
import firebase from 'firebase/app';
import 'firebase/auth';
import NProgress from 'nprogress';
const FleetLeftSidebar = () => import('../reusables/FleetLeftSidebar.vue');
// const SimpleModal = () => import('../reusables/SimpleModal.vue');
import * as moment from 'moment'

export default {

  components: {
    FleetLeftSidebar,
    // SimpleModal
  },  
  props: {

  },
  mounted: function() {
    firebase.auth().onAuthStateChanged( this.onUserLogin );
    let starter = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    this.startDate = starter.toISOString();
  },
  computed: {    
    currentFleet() {
      return this.$store.getters.getCurrentFleet;
    },
    showAddTruckButton() {
      return this.newtruck.truckname && this.newtruck.truckuvi;
    },
    fleetStatus() {
      return this.$store.getters.getFleetStatus;
    },
    canAddTrucks() {
      // return this.allTrucks.length < this.fleetStatus.maxTrucks;
      return true;
    },
    userData() {
      return this.$store.getters.getSubscriptionInfo;
    },
    dateRange() {
      let diff = moment(this.endDate).diff(moment(this.startDate));
      // return moment.duration(diff).humanize();
      return Math.round(moment.duration(diff).asDays());
    },
    rangeIsValid() {
      return this.endDate > this.startDate;
    }
  },

  data() {
    return {
      firebaseid: '',
      allTrucks: [],
      selectedTruck: '',
      selectedLog: '',
      logTypes: ['All Activity','Prestart Logs','Service Logs','Refuelling Logs','Truck Logs','Custom Logs','Daily Tasks'],
      demoFleet: false,

      showLogSelector: true,

      rangeTypes: [
        {name: 'Last 7 Days', slug: 'seven-days'},
        {name: 'Last 30 Days', slug: 'thirty-days'},
        {name: 'Last 12 months', slug: 'one-year'},
        {name: 'Custom Date Range', slug: 'custom-range'},
      ],
      rangeType: '',
      startDate: '',
      endDate: new Date().toISOString(),

      finalStart: '',
      finalEnd: '',

      allActivity: [],
      showActivityLogs: false,
      allPrestarts: [],
      showPrestartLogs: false,
      allServices: [],
      showServiceLogs: false,
      showRefuellingLogs: false,
      allRefuellingLogs: [],
      showTrucksLogs: false,
      allTrucksLogs: [],
      showCustomLogs: false,
      allCustomLogs: [],
      showDailyTasks: false,
      allDailyTasks: [],

    }
  },

  methods: {

    onUserLogin: function( user ) {
      this.firebaseid = user.uid;
      this.loadFleetTrucks();
      this.loadFleetInfo();
    },

    loadFleetTrucks() {
      let self = this;
      db.collection('fleets').doc(this.currentFleet.id).collection('fleetTrucks').onSnapshot(function(querySnapshot) {
        self.allTrucks = [];
        querySnapshot.forEach((doc) => {
          db.collection('trucks').doc(doc.id).get().then(function(docRef) {
              self.allTrucks.push({
                truckID: doc.id,
                truckName: doc.data().truckName,
                truckUVI: doc.data().truckUVI,
                isPaidFor: docRef.data().isPaidFor ? docRef.data().isPaidFor : false,
              }); 
          });
        });
      });
    },

    loadFleetInfo() {
      let self = this;
      db.collection('fleets').doc(this.currentFleet.id).get().then(function(doc) {
        self.fleetEmail = doc.data().fleetEmail;
        self.demoFleet = doc.data().isDemo ? doc.data().isDemo : false;
      });
    },

    close() {

    },

    formatDate : function (sentDate,format) {
      return moment(sentDate).format(format);
    },
    
    createLog() {
      // console.log(this.rangeType, this.startDate, this.endDate, this.selectedTruck, this.selectedLog);
      this.showLogSelector = false;
      this.resetLogType();

      let self = this;
      // let finalStart = '';
      // let finalEnd = '';
      // let tempStart = '';

      switch(this.rangeType.slug) {
        case 'seven-days':
          // let tempStart = new Date(Date.now() - 8 * 24 * 60 * 60 * 1000);
          // self.finalStart = tempStart.toISOString();
          self.finalStart = new Date(Date.now() - 8 * 24 * 60 * 60 * 1000).toISOString();
          self.finalEnd = new Date().toISOString();
          break;
        case 'thirty-days':
          // tempStart = new Date(Date.now() - 31 * 24 * 60 * 60 * 1000);
          // finalStart = tempStart.toISOString();
          self.finalStart = new Date(Date.now() - 31 * 24 * 60 * 60 * 1000).toISOString();
          self.finalEnd = new Date().toISOString();
          break;
        case 'one-year':
          // tempStart = new Date(Date.now() - 366 * 24 * 60 * 60 * 1000);
          // finalStart = tempStart.toISOString();
          self.finalStart = new Date(Date.now() - 366 * 24 * 60 * 60 * 1000).toISOString();
          self.finalEnd = new Date().toISOString();
          break;
        case 'custom-range':
          self.finalStart = self.startDate;
          self.finalEnd = self.endDate;
          break;
        default:
          self.finalStart = self.startDate;
          self.finalEnd = self.endDate;
      }

      switch(this.selectedLog) {
        case 'Service Logs':
          self.buildServiceLogs();
          break;
        case 'Prestart Logs':
          self.buildPrestartLogs();
          break;
        case 'Refuelling Logs':
          self.buildRefuellingLogs();
          break;
        case 'Custom Logs':
          self.buildCustomLogs();
          break;
        case 'Truck Logs':
          self.buildTrucksLogs();
          break;
        case 'Daily Tasks':
          self.buildDailyTasks();
          break;
        default:
          self.buildActivityLogs();
      }         
      

    },

    buildServiceLogs() {
      let self = this;
      NProgress.start();
      db.collection('trucks').doc(this.selectedTruck.truckID).collection('serviceLogs').orderBy('logTimestamp','desc').get().then(function(querySnapshot) {
        self.allServices = [];
        querySnapshot.forEach((doc) => {
          let afterStart = moment(doc.data().logTimestamp.toDate()).isSameOrAfter(moment(self.finalStart));
          let beforeEnd = moment(doc.data().logTimestamp.toDate()).isSameOrBefore(moment(self.finalEnd));
          if (afterStart && beforeEnd) {
            self.allServices.push({
              id: doc.id,
              timestamp: doc.data().logTimestamp.toDate(),
              serviceName: doc.data().serviceName ? doc.data().serviceName : 'Not recorded',
              mileage: doc.data().mileage ? doc.data().mileage : 'Not recorded',
              postedBy: doc.data().postedBy ? doc.data().postedBy : 'Not recorded',
            });
          }
        });
      }).then(() => {
        self.showServiceLogs = true;
        NProgress.done();
      });
    },

    buildPrestartLogs() {
      let self = this;
      NProgress.start();
      db.collection('trucks').doc(this.selectedTruck.truckID).collection('logStartups').orderBy('startupTimestamp','desc').get().then(function(querySnapshot) {
        self.allPrestarts = [];
        querySnapshot.forEach((doc) => {
          let afterStart = moment(doc.data().startupTimestamp.toDate()).isSameOrAfter(moment(self.finalStart));
          let beforeEnd = moment(doc.data().startupTimestamp.toDate()).isSameOrBefore(moment(self.finalEnd));
          if (afterStart && beforeEnd) {
            self.allPrestarts.push({
              id: doc.id,
              timestamp: doc.data().startupTimestamp.toDate(),
              itemCount: doc.data().selectedItems ? doc.data().selectedItems.length : 'None',
              mileage: doc.data().mileage ? doc.data().mileage : 'Not recorded',
              postedBy: doc.data().postedBy ? doc.data().postedBy : 'Not recorded',
            });
          }
        });
      }).then(() => {
        self.showPrestartLogs = true;
        NProgress.done();
      });
    },

    buildRefuellingLogs() {
      let self = this;
      NProgress.start();
      db.collection('trucks').doc(this.selectedTruck.truckID).collection('refuellingLogs').orderBy('timestamp','desc').get().then(function(querySnapshot) {
        self.allRefuellingLogs = [];
        querySnapshot.forEach((doc) => {
          let afterStart = moment(doc.data().timestamp.toDate()).isSameOrAfter(moment(self.finalStart));
          let beforeEnd = moment(doc.data().timestamp.toDate()).isSameOrBefore(moment(self.finalEnd));
          if (afterStart && beforeEnd) {
            self.allRefuellingLogs.push({
              id: doc.id,
              timestamp: doc.data().timestamp.toDate(),
              mileage: doc.data().mileage ? doc.data().mileage : 'Nor recorded',
              fuelAdded: doc.data().fuelAdded ? doc.data().fuelAdded : 0,
              postedBy: doc.data().postedBy ? doc.data().postedBy : 'Not recorded',
            });
          }
        });
      }).then(() => {
        self.showRefuellingLogs = true;
        NProgress.done();
      });
    },

    buildCustomLogs() {
      let self = this;
      NProgress.start();
      db.collection('trucks').doc(this.selectedTruck.truckID).collection('customLogs').orderBy('timestamp','desc').get().then(function(querySnapshot) {
        self.allCustomLogs = [];
        querySnapshot.forEach((doc) => {
          let afterStart = moment(doc.data().timestamp.toDate()).isSameOrAfter(moment(self.finalStart));
          let beforeEnd = moment(doc.data().timestamp.toDate()).isSameOrBefore(moment(self.finalEnd));
          if (afterStart && beforeEnd) {
            self.allCustomLogs.push({
              id: doc.id,
              timestamp: doc.data().timestamp.toDate(),
              mileage: doc.data().mileage ? doc.data().mileage : 0,
              checklistName: doc.data().customChecklistName ? doc.data().customChecklistName : 'Not recorded',
              itemCount: doc.data().selectedItems ? doc.data().selectedItems.length : 'None',
              postedBy: doc.data().postedBy ? doc.data().postedBy : 'Unknown',
            });
          }
        });
      }).then(() => {
        self.showCustomLogs = true;
        NProgress.done();
      });
    },

    buildTrucksLogs() {
      let self = this;
      NProgress.start();
      db.collection('trucks').doc(this.selectedTruck.truckID).collection('trucksLog').orderBy('timestamp','desc').get().then(function(querySnapshot) {
        self.allTrucksLogs = [];
        querySnapshot.forEach((doc) => {
          let afterStart = moment(doc.data().timestamp.toDate()).isSameOrAfter(moment(self.finalStart));
          let beforeEnd = moment(doc.data().timestamp.toDate()).isSameOrBefore(moment(self.finalEnd));
          if (afterStart && beforeEnd) {
            self.allTrucksLogs.push({
              id: doc.id,
              timestamp: doc.data().timestamp.toDate(),
              details: doc.data().logDetails ? doc.data().logDetails : 'None',
              postedBy: doc.data().postedBy ? doc.data().postedBy : 'Unknown',
            });
          }
        });
      }).then(() => {
        self.showTrucksLogs = true;
        NProgress.done();
      });
    },

    buildDailyTasks() {
      let self = this;
      NProgress.start();
      db.collection('trucks').doc(this.selectedTruck.truckID).collection('dailyTasks').orderBy('timestamp','desc').get().then(function(querySnapshot) {
        self.allDailyTasks = [];
        querySnapshot.forEach((doc) => {
          let afterStart = moment(doc.data().timestamp.toDate()).isSameOrAfter(moment(self.finalStart));
          let beforeEnd = moment(doc.data().timestamp.toDate()).isSameOrBefore(moment(self.finalEnd));
          if (afterStart && beforeEnd) {
            self.allDailyTasks.push({
              id: doc.id,
              timestamp: doc.data().timestamp.toDate(),
              title: doc.data().title ? doc.data().title : 'None',
              status: doc.data().status ? doc.data().status : 'Unknown',
              acceptedBy: doc.data().acceptedBy ? doc.data().acceptedBy : 'Not recorded',
            });
          }
        });
      }).then(() => {
        self.showDailyTasks = true;
        NProgress.done();
      });
    },

    buildActivityLogs() {
      let self = this;
      NProgress.start();
      db.collection('trucks').doc(this.selectedTruck.truckID).collection('truckRevisions').orderBy('timestamp','desc').get().then(function(querySnapshot) {
        self.allActivity = [];
        querySnapshot.forEach((doc) => {
          let afterStart = moment(doc.data().timestamp.toDate()).isSameOrAfter(moment(self.finalStart));
          let beforeEnd = moment(doc.data().timestamp.toDate()).isSameOrBefore(moment(self.finalEnd));
          if (afterStart && beforeEnd) {
            self.allActivity.push({
              id: doc.id,
              timestamp: doc.data().timestamp.toDate(),
              revision: doc.data().revision ? doc.data().revision : 'Not recorded',
              userName: doc.data().userName ? doc.data().userName : 'Not recorded',
            });
          }
        });
      }).then(() => {
        self.showActivityLogs = true;
        NProgress.done();
      });
    },

    resetLogType() {
      this.showActivityLogs = false;
      this.showServiceLogs = false;
      this.showPrestartLogs = false;
      this.showRefuellingLogs = false;
      this.showTrucksLogs = false;
      this.showCustomLogs = false;
      this.showDailyTasks = false;

      this.allActivity = [];
      this.allServices = [];
      this.allPrestarts = [];
      this.allRefuellingLogs = [];
      this.allTrucksLogs = [];
      this.allCustomLogs = [];
      this.allDailyTasks = [];
    },

    resetLog() {
      this.showLogSelector = true;
      this.rangeType = '';
      let starter = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
      this.startDate = starter.toISOString();
      this.endDate = new Date().toISOString();
      this.selectedTruck = '';
      this.selectedLog = '';
      this.showActivityLogs = false;
      this.showServiceLogs = false;
      this.showPrestartLogs = false;
      this.finalStart = '';
      this.finalEnd = '';
    }

    




  }



}


</script>



<style lang="scss" scoped>
  

  .form-group {
    input.capitalise {
      text-transform: uppercase;
    }
  }
  .delete-truck {
    margin-top: 1rem;
  }
  .buttons {
    .button {
      svg {
        margin-right: 5px;
      }
    }
  }
  .item-title {
    text-align: left;
  }
  h6 {
    margin-bottom: 1rem;
    font-weight: bold;
  }
  .custom-dates {
    margin-top: 1.5rem;
    width: 100%;
    .date-column {
      float: left;
      width: auto;
      margin-right: 1rem;
      label {
        margin-bottom: 6px;
        display: block;
      }
    }
    input.my-input-class {
      color: red;
      width: 180px !important;
    }
  }
  .red {
    color: red;
  }
  .log-selector {

  }
  .list-enter,
  .list-leave-to {
    visibility: hidden;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }

  .list-enter-active,
  .list-leave-active {
    transition: all 0.3s;
  }
  

</style>


