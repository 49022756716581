<template>
	
		<div>

        <div class="fleet-wrapper">


  		      <fleet-left-sidebar></fleet-left-sidebar>

      		  <div class="main-fleet-content">

                <div v-if="currentTruckID">

                    <fleet-scroller tab="Service Schedules" backLink="fleet-edit-sms" backTitle="Truck Details"></fleet-scroller>

                    <br>
                    <div class="before-icon">
                      <font-awesome-icon icon="truck" />
                    </div>
                    <h4 class="icon">Service Schedules</h4>
                    <div class="clear"></div>

                    <div class="mileage">
                      <span>LATEST MILEAGE: </span> {{numberWithCommas(lastMileage)}}  KM 
                      <span @click="editItem('Last Mileage','lastMileage',lastMileage, 'KM')" class="button is-small is-primary">Edit</span>
                    </div>

          				  
          				  <div class="tabs profile is-fullwidth is-centered">
          				    <ul class="service-table">
          				      <li :class="[ serviceTab === 'serviceA' ? 'is-active' : '']">
          				      	<a @click="setTab('serviceA')"><font-awesome-icon icon="wrench" />{{serviceAname}}</a>
          				      </li>
          				      <li :class="[ serviceTab === 'serviceB' ? 'is-active' : '']">
          				      	<a @click="setTab('serviceB')"><font-awesome-icon icon="wrench" />{{serviceBname}}</a>
          				      </li>
                        <li :class="[ serviceTab === 'serviceC' ? 'is-active' : '']">
                          <a @click="setTab('serviceC')"><font-awesome-icon icon="wrench" />{{serviceCname}}</a>
                        </li>
          				    </ul>
          				  </div>

          				  <div class="tab-content">

          				  	<div v-if="serviceTab === 'serviceA'">

          				  		<p>&nbsp;</p>

          						  <table class="table is-fullwidth is-bordered">
          						  	<tr>
          						  		<th>Item</th>
          						  		<th>Details</th>
          						  	</tr>
          						  	<tr v-if="serviceWarning || serviceOverdue">
          						  		<td>Service</td>
          						  		<td>
          						  			<span class="tag is-warning" v-if="serviceWarning"> <font-awesome-icon icon="exclamation-triangle" />&nbsp;Due</span>
          						  			<span class="tag is-danger" v-if="serviceOverdue"> <font-awesome-icon icon="exclamation-triangle" />&nbsp;Overdue</span>
          						  		</td>
          						  	</tr>
                          <tr>
                            <td>Service name</td>
                            <td>{{serviceAname}} <span @click="editItemName('serviceAname',serviceAname)" class="button is-small is-light">Edit</span>
                            </td>
                          </tr>
                          <!-- <tr>
                            <td>Service type (Engine Hours or KM)</td>
                            <td>{{serviceAunit}} <span @click="editServiceUnit('Service A Type','serviceAunit',serviceAunit)" class="button is-small is-light">Edit</span>
                            </td>
                          </tr> -->
                          <tr>
                            <td>Service interval</td>
                            <td>{{numberWithCommas(serviceAinterval)}}  {{(serviceAunit)}} <span @click="editItem('Service A Interval','serviceAinterval',serviceAinterval, serviceAunit)" class="button is-small is-light">Edit</span>
                            </td>
                          </tr>
                          <!-- <tr>
                            <td class="bold">Latest reading</td>
                            <td class="bold">{{numberWithCommas(serviceAlatestReading)}}  {{(serviceAunit)}} <span @click="editItem('Latest Reading','serviceAlatestReading',serviceAlatestReading, serviceAunit)" class="button is-small is-light">Edit</span>
                            </td>
                          </tr> -->
                          <tr>
                            <td>Last service date</td>
                            <td>{{serviceAlastServiceDate}}</td>
                          </tr>
                          <tr>
                            <td>{{(serviceAunit)}} at last service</td>
                            <td>{{serviceAlastServiceValue}}  {{(serviceAunit)}}</td>
                          </tr>
                          <tr>
                            <td class="bold" :class="[serviceAnextServiceValueCalc < lastMileage ? 'red' : 'green']">Next service due at</td>
                            <td class="bold" :class="[serviceAnextServiceValueCalc < lastMileage ? 'red' : 'green']">{{serviceAnextServiceValueCalc}} {{(serviceAunit)}}</td>
                          </tr>

          						  </table>

                        <hr>

                        <div>
                          <br>
                          <div class="before-icon"><font-awesome-icon icon="check-circle" /></div><h4 class="icon">{{serviceAname}} Checklist</h4>  
                          <div class="clear"></div>
                          <div v-if="serviceAItems.length" class="card-content">
                            <div v-for="(item, index) in sorted(serviceAItems)" :key="index">
                              <div class="notification">
                                <div class="item-title">{{item.title}}</div>
                                <div class="item-details">
                                  <div v-for="line in item.details.split('\n')" :key="line">{{line}}<br></div>
                                </div>
                                <div class="buttons">
                                  <span class="button is-info" @click="launchItemWindow(item.id,item.title,item.details,'Read')">Read</span>
                                  <span class="button is-success" @click="launchItemWindow(item.id,item.title,item.details,'Edit')">Edit</span>
                                  <span class="button is-danger" @click="deleteItem(item.id)">Delete</span>
                                </div>
                              </div>  
                            </div>
                            <!-- <span class="simple-link right" @click="enableSortModal(serviceAItems)">Change order</span> -->
                          </div>
                          <div v-else><p>No items yet.</p></div>
                        </div>

                        <div class="button-wrapper full">
                          <button @click="launchItemWindow('','','','Write New')" class="button is-primary">Write new item</button>                     
                          <button @click="launchAddHelperItems()" class="button is-primary">Add helper items</button> 
                        </div>

                        <hr>

                        <div class="spacer s20"></div>
                        <div class="before-icon"><font-awesome-icon icon="check-circle" /></div><h4 class="icon">{{serviceAname}} History</h4>  
                        <div class="clear"></div>

          						  <ul v-if="serviceAHistory.length" class="sms-steps steps is-vertical">

          					    	<li class="steps-segment" v-for="(service, index) in serviceAHistory" :key="index">
          					        <span class="steps-marker is-primary">
          					        	<span class="icon">{{index+1}}</span>
          					        </span>
          					        <div class="steps-content">
          					          	<p class="heading">{{formatDate(service.logTimestamp,'ddd D MMMM (h:mm a)')}}</p>
          					          	<span class="button is-small" @click="showServiceDetails(service)">View Details</span>
          					          	<p>&nbsp;</p>
          					        </div>
          					      </li>		      

          					    </ul>

          					    <div v-else>No service history.</div>

          						</div>

          						<div v-if="serviceTab === 'serviceB'">

                        <p>&nbsp;</p>

                        <table class="table is-fullwidth is-bordered">
                          <tr>
                            <th>Item</th>
                            <th>Details</th>
                          </tr>
                          <tr v-if="serviceWarning || serviceOverdue">
                            <td>Service</td>
                            <td>
                              <span class="tag is-warning" v-if="serviceWarning"> <font-awesome-icon icon="exclamation-triangle" />&nbsp;Due</span>
                              <span class="tag is-danger" v-if="serviceOverdue"> <font-awesome-icon icon="exclamation-triangle" />&nbsp;Overdue</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Service name</td>
                            <td>{{serviceBname}} <span @click="editItemName('serviceBname',serviceBname)" class="button is-small is-light">Edit</span>
                            </td>
                          </tr>
                          <!-- <tr>
                            <td>Service type (Engine Hours or KM)</td>
                            <td>{{serviceBunit}} <span @click="editServiceUnit('Service A Type','serviceBunit',serviceBunit)" class="button is-small is-light">Edit</span>
                            </td>
                          </tr> -->
                          <tr>
                            <td>Service interval</td>
                            <td>{{numberWithCommas(serviceBinterval)}}  {{(serviceBunit)}} <span @click="editItem('Service A Interval','serviceBinterval',serviceBinterval, serviceBunit)" class="button is-small is-light">Edit</span>
                            </td>
                          </tr>
                         <!--  <tr>
                            <td class="bold">Latest reading</td>
                            <td class="bold">{{numberWithCommas(serviceBlatestReading)}}  {{(serviceBunit)}} <span @click="editItem('Latest Reading','serviceBlatestReading',serviceBlatestReading, serviceBunit)" class="button is-small is-light">Edit</span>
                            </td>
                          </tr> -->
                          <tr>
                            <td>Last service date</td>
                            <td>{{serviceBlastServiceDate}}</td>
                          </tr>
                          <tr>
                            <td>{{(serviceBunit)}} at last service</td>
                            <td>{{serviceBlastServiceValue}}  {{(serviceBunit)}}</td>
                          </tr>
                          <tr>
                            <td class="bold" :class="[serviceBnextServiceValueCalc < lastMileage ? 'red' : 'green']">Next service due at</td>
                            <td class="bold" :class="[serviceBnextServiceValueCalc < lastMileage ? 'red' : 'green']">{{serviceBnextServiceValueCalc}} {{(serviceBunit)}}</td>
                          </tr>
                          
                        </table>

                        <hr>

                        <div>
                          <br>
                          <div class="before-icon"><font-awesome-icon icon="check-circle" /></div><h4 class="icon">{{serviceBname}} Checklist</h4>  
                          <div class="clear"></div>
                          <div v-if="serviceBItems.length" class="card-content">
                            <div v-for="(item, index) in sorted(serviceBItems)" :key="index">
                              <div class="notification">
                                <div class="item-title">{{item.title}}</div>
                                <div class="item-details">
                                  <div v-for="line in item.details.split('\n')" :key="line">{{line}}<br></div>
                                </div>
                                <div class="buttons">
                                  <span class="button is-info" @click="launchItemWindow(item.id,item.title,item.details,'Read')">Read</span>
                                  <span class="button is-success" @click="launchItemWindow(item.id,item.title,item.details,'Edit')">Edit</span>
                                  <span class="button is-danger" @click="deleteItem(item.id)">Delete</span>
                                </div>
                              </div>  
                            </div>
                            <!-- <span class="simple-link right" @click="enableSortModal(serviceAItems)">Change order</span> -->
                          </div>
                          <div v-else><p>No items yet.</p></div>
                        </div>

                        <div class="button-wrapper full">
                          <button @click="launchItemWindow('','','','Write New')" class="button is-primary">Write new item</button>                     
                          <button @click="launchAddHelperItems()" class="button is-primary">Add helper items</button> 
                        </div>

                        <hr>

                        <div class="spacer s20"></div>
                        <div class="before-icon"><font-awesome-icon icon="check-circle" /></div><h4 class="icon">{{serviceBname}} History</h4>  
                        <div class="clear"></div>

                        <ul v-if="serviceBHistory.length" class="sms-steps steps is-vertical">

                          <li class="steps-segment" v-for="(service, index) in serviceBHistory" :key="index">
                            <span class="steps-marker is-primary">
                              <span class="icon">{{index+1}}</span>
                            </span>
                            <div class="steps-content">
                                <p class="heading">{{formatDate(service.logTimestamp,'ddd D MMMM (h:mm a)')}}</p>
                                <span class="button is-small" @click="showServiceDetails(service)">View Details</span>
                                <p>&nbsp;</p>
                            </div>
                          </li>         

                        </ul>

                        <div v-else>No service history.</div>

                      </div>


                      <div v-if="serviceTab === 'serviceC'">

                        <p>&nbsp;</p>

                        <table class="table is-fullwidth is-bordered">
                          <tr>
                            <th>Item</th>
                            <th>Details</th>
                          </tr>
                          <tr v-if="serviceWarning || serviceOverdue">
                            <td>Service</td>
                            <td>
                              <span class="tag is-warning" v-if="serviceWarning"> <font-awesome-icon icon="exclamation-triangle" />&nbsp;Due</span>
                              <span class="tag is-danger" v-if="serviceOverdue"> <font-awesome-icon icon="exclamation-triangle" />&nbsp;Overdue</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Service name</td>
                            <td>{{serviceCname}} <span @click="editItemName('serviceCname',serviceCname)" class="button is-small is-light">Edit</span>
                            </td>
                          </tr>
                          <!-- <tr>
                            <td>Service type (Engine Hours or KM)</td>
                            <td>{{serviceCunit}} <span @click="editServiceUnit('Service A Type','serviceCunit',serviceCunit)" class="button is-small is-light">Edit</span>
                            </td>
                          </tr> -->
                          <tr>
                            <td>Service interval</td>
                            <td>{{numberWithCommas(serviceCinterval)}}  {{(serviceCunit)}} <span @click="editItem('Service A Interval','serviceCinterval',serviceCinterval, serviceCunit)" class="button is-small is-light">Edit</span>
                            </td>
                          </tr>
                          <!-- <tr>
                            <td class="bold">Latest reading</td>
                            <td class="bold">{{numberWithCommas(serviceClatestReading)}}  {{(serviceCunit)}} <span @click="editItem('Latest Reading','serviceClatestReading',serviceClatestReading, serviceCunit)" class="button is-small is-light">Edit</span>
                            </td>
                          </tr> -->
                          <tr>
                            <td>Last service date</td>
                            <td>{{serviceClastServiceDate}}</td>
                          </tr>
                          <tr>
                            <td>{{(serviceCunit)}} at last service</td>
                            <td>{{serviceClastServiceValue}}  {{(serviceCunit)}}</td>
                          </tr>
                          <tr>
                            <td class="bold" :class="[serviceCnextServiceValueCalc < lastMileage ? 'red' : 'green']">Next service due at</td>
                            <td class="bold" :class="[serviceCnextServiceValueCalc < lastMileage ? 'red' : 'green']">{{serviceCnextServiceValueCalc}} {{(serviceCunit)}}</td>
                          </tr>
                          
                        </table>

                        <hr>

                        <div>
                          <br>
                          <div class="before-icon"><font-awesome-icon icon="check-circle" /></div><h4 class="icon">{{serviceCname}} Checklist</h4>  
                          <div class="clear"></div>
                          <div v-if="serviceCItems.length" class="card-content">
                            <div v-for="(item, index) in sorted(serviceCItems)" :key="index">
                              <div class="notification">
                                <div class="item-title">{{item.title}}</div>
                                <div class="item-details">
                                  <div v-for="line in item.details.split('\n')" :key="line">{{line}}<br></div>
                                </div>
                                <div class="buttons">
                                  <span class="button is-info" @click="launchItemWindow(item.id,item.title,item.details,'Read')">Read</span>
                                  <span class="button is-success" @click="launchItemWindow(item.id,item.title,item.details,'Edit')">Edit</span>
                                  <span class="button is-danger" @click="deleteItem(item.id)">Delete</span>
                                </div>
                              </div>  
                            </div>
                            <!-- <span class="simple-link right" @click="enableSortModal(serviceAItems)">Change order</span> -->
                          </div>
                          <div v-else><p>No items yet.</p></div>
                        </div>

                        <div class="button-wrapper full">
                          <button @click="launchItemWindow('','','','Write New')" class="button is-primary">Write new item</button>                     
                          <button @click="launchAddHelperItems()" class="button is-primary">Add helper items</button> 
                        </div>

                        <hr>

                        <div class="spacer s20"></div>
                        <div class="before-icon"><font-awesome-icon icon="check-circle" /></div><h4 class="icon">{{serviceCname}} History</h4>  
                        <div class="clear"></div>

                        <ul v-if="serviceCHistory.length" class="sms-steps steps is-vertical">

                          <li class="steps-segment" v-for="(service, index) in serviceCHistory" :key="index">
                            <span class="steps-marker is-primary">
                              <span class="icon">{{index+1}}</span>
                            </span>
                            <div class="steps-content">
                                <p class="heading">{{formatDate(service.logTimestamp,'ddd D MMMM (h:mm a)')}}</p>
                                <span class="button is-small" @click="showServiceDetails(service)">View Details</span>
                                <p>&nbsp;</p>
                            </div>
                          </li>         

                        </ul>

                        <div v-else>No service history.</div>

                      </div>

          					</div>

                    <hr>
                    <div class="buttons">  
                      <router-link to="fleet-edit-sms">
                        <span class="button is-info">Back to Truck</span>
                      </router-link>
                    </div>

                </div>

                <div v-else>
                  <div class="">
                    <p>No truck detected.</p>
                    <p>&nbsp;</p>
                    <div class="buttons">
                      <button @click="goBack()" class="button is-info"><span><font-awesome-icon icon="chevron-left" /></span>&nbsp; Go Back</button>
                    </div>
                  </div>
                </div>

                <div class="spacer s90"></div>

      				  

      			    <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showDetails}">
      			      <div class="modal-background" @click="close"></div>
      			      <div class="modal-card">
      						    <header class="modal-card-head">
      						      <p class="modal-card-title">Service Details</p>
      						      <button class="delete" aria-label="close" @click.prevent="close"></button>
      						    </header>
      						    <section class="modal-card-body">	
      										<table class="table is-fullwidth">
      									  	<tr>
      									  		<th>Item</th>
      									  		<th>Details</th>
      									  	</tr>
      									  	<tr>
      									  		<td>Service date</td>
      									  		<td>{{formatDate(currentService.logTimestamp,'ddd D MMMM (h:mm a)')}}</td>
      									  	</tr>
                            <tr>
                              <td>Logged by</td>
                              <td>{{currentService.postedBy}}</td>
                            </tr>
      									  	<tr>
      									  		<td>Mileage at service</td>
      									  		<td>{{currentService.mileage}} km</td>
      									  	</tr>
      									  	<tr>
      									  		<td>Service notes</td>
      									  		<td>{{currentService.notes}}</td>
      									  	</tr>
      									  </table>

      						    </section>
      						    <footer class="modal-card-foot">
      						      <button class="button is-primary" @click.prevent="close">Done</button>
                        <button class="button is-danger" @click="deleteService(currentService.id)">Delete Service</button>
      						    </footer>
      				  	</div>
      			    </div>


      			    <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':editModal}">
      			      <div class="modal-background" @click="close"></div>
      			      <div class="modal-card">
      						    <header class="modal-card-head">
      						      <p class="modal-card-title">{{editingItemName}}</p>
      						      <button class="delete" aria-label="close" @click.prevent="close"></button>
      						    </header>
      						    <section class="modal-card-body">											
      										<input ref="focused" class="input" type="tel" :placeholder="numberWithCommas(editingItemOldValue)" :maxlength="7" v-on:keypress="isNumber($event)" v-model="editingItemValue" />
                          <span class="unit">{{editingItemUnit}}</span>
      						    </section>
      						    <footer class="modal-card-foot">
      						      <button class="button is-primary" @click="saveServiceInterval()">Update</button>
      						      <button class="button" @click.prevent="close">Cancel</button>
      						    </footer>
      				  	</div>
      			    </div>


                <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':editDropdownModal}">
                  <div class="modal-background" @click="close"></div>
                  <div class="modal-card">
                      <header class="modal-card-head">
                        <p class="modal-card-title">{{editingItemName}}</p>
                        <button class="delete" aria-label="close" @click.prevent="close"></button>
                      </header>
                      <section class="modal-card-body">                     
                          <label class="label-fixed">SERVICE TYPE:</label>
                          <div class="spacer s10"></div>
                          <div class="control">
                            <div class="select">
                              <select v-model="editingItemValue">
                                <option disabled value="">Choose a type</option>
                                <option value="KM">KM</option>                                  
                                <option value="Hours">Hours</option>
                              </select>
                            </div>
                          </div>
                      </section>
                      <footer class="modal-card-foot">
                        <button class="button is-primary" @click="saveEditedModalItem">Update</button>
                        <button class="button" @click.prevent="close">Cancel</button>
                      </footer>
                  </div>
                </div>


                <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':editNameModal}">
                  <div class="modal-background" @click="close"></div>
                  <div class="modal-card">
                      <header class="modal-card-head">
                        <p class="modal-card-title">{{editingItemName}}</p>
                        <button class="delete" aria-label="close" @click.prevent="close"></button>
                      </header>
                      <section class="modal-card-body">                     
                          <input ref="focused" class="input" type="text" :placeholder="editingItemOldValue" maxlength="30" v-model="editingItemValue" />
                          <span class="unit">{{editingItemUnit}}</span>
                      </section>
                      <footer class="modal-card-foot">
                        <button class="button is-primary" @click="saveEditedItem">Update</button>
                        <button class="button" @click.prevent="close">Cancel</button>
                      </footer>
                  </div>
                </div>

                <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':isActiveAddHelperItems}">
                  <div class="modal-background" @click="close"></div>
                  <div class="modal-card">
                    <header class="modal-card-head">
                      <p class="modal-card-title">Suggested Items:</p>
                      <button class="delete" aria-label="close" @click.prevent="close"></button>
                    </header>
                    <section class="modal-card-body"> 

                      <div class="checkbox-wrapper">
                        <div class="field" v-for="(item, index) in helperItems" :key="index">
                          <input class="is-checkradio" type="checkbox" :id="item.id" :value="item" v-model="selectedItems">
                          <label :for="item.id">{{item.title}}</label>
                        </div>
                      </div>

                    </section>
                    <footer class="modal-card-foot">
                      <button class="button is-primary" @click="saveHelperItems()">Add to my Items</button>
                      <button class="button cancel" @click.prevent="close">Cancel</button>
                    </footer>
                  </div>
                </div>

                <!-- <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':enableSort}">
                  <div class="modal-background" @click="close"></div>
                  <div class="modal-card">
                    <header class="modal-card-head">
                      <p class="modal-card-title">Drag to change order of items</p>
                      <button class="delete" aria-label="close" @click.prevent="close"></button>
                    </header>
                    <section class="modal-card-body"> 

                      <div v-if="truckItems.length">

                        <draggable v-model="truckItems" class="sorting-list" group="people" @start="drag=true" @end="drag=false">
                          <div v-for="(item, index) in truckItems" :key="item.id">{{index + 1}} - {{item.title}}  <span>&#8597;</span></div>
                        </draggable>

                      </div>

                    </section>
                    <footer class="modal-card-foot">
                      <button class="button is-primary" @click="saveNewOrder">Update</button>
                      <button class="button cancel" @click.prevent="close">Cancel</button>
                    </footer>
                  </div>
                </div> -->


      			    <!-- <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showResetFuel}">
      			      <div class="modal-background" @click="close"></div>
      			      <div class="modal-card">
      						    <header class="modal-card-head">
      						      <p class="modal-card-title">Reset Fuel Consumption?</p>
      						      <button class="delete" aria-label="close" @click.prevent="close"></button>
      						    </header>
      						    <section class="modal-card-body">						
      						    		<p>Reset fuel consumption from now, assuming the truck is at full fuel capacity.</p>	
      						    		<p>&nbsp;</p>				
      										<input class="is-checkradio" id="isFull" type="checkbox" v-model="isFull">
      										<label for="isFull">Confirm truck is full of fuel</label>
      						    </section>
      						    <footer class="modal-card-foot">
      						      <button v-if="showResetButton" class="button is-primary" @click="resetFuel">Reset</button>
      						      <button class="button" @click.prevent="close">Cancel</button>
      						    </footer>
      				  	</div>
      			    </div> -->

                <edit-item-window :item="selectedItem" v-if="showItemWindow == true" v-on:sendItem="returnedItem"></edit-item-window>

      			</div>

        </div>

		</div>

</template>


<script>

	import { db } from '../main.js';
	import firebase from 'firebase/app';
	import 'firebase/auth'; 
	import 'firebase/firestore';
	import NProgress from 'nprogress';
	import * as moment from 'moment';
  import EditItemWindow from '../reusables/EditItemWindow.vue';

	// const StickyFooter = () => import('../reusables/StickyFooter.vue');
  const FleetScroller = () => import('../reusables/FleetScroller.vue');
  const FleetLeftSidebar = () => import('../reusables/FleetLeftSidebar.vue');

	

	export default {

		name: 'FleetServiceSchedules',

	  components: {
	  	// StickyFooter,
      FleetScroller,
      FleetLeftSidebar,
      EditItemWindow,
	  },

		// props: [
		// 	'truckID',
		// 	'truckName',
		// ],

		mounted: function() {
			firebase.auth().onAuthStateChanged( this.onUserLogin );	
		},

		computed: {
			nextServiceHours() {
				// const lastInterval = ( Math.floor( parseInt(this.lastEngineHours) / parseInt(this.serviceInterval) ) ) * parseInt(this.serviceInterval);
				// return lastInterval + parseInt(this.serviceInterval);
				return parseInt(this.lastServiceHours) + parseInt(this.serviceInterval);
			},
			serviceWarning() {
				return (this.hoursSinceLastService > (0.9 * parseInt(this.serviceInterval))) && (this.hoursSinceLastService <= parseInt(this.serviceInterval));
			},
			serviceOverdue() {
				return this.hoursSinceLastService > parseInt(this.serviceInterval);
			},
			hoursSinceLastService() {
				return parseInt(this.lastEngineHours) - parseInt(this.lastServiceHours);
			},

			// nextServiceHoursGenset() {
			// 	// const lastInterval = ( Math.floor( parseInt(this.lastEngineHoursGenset) / parseInt(this.serviceIntervalGenset) ) ) * parseInt(this.serviceIntervalGenset);
			// 	// return lastInterval + parseInt(this.serviceIntervalGenset);
			// 	return parseInt(this.lastServiceHoursGenset) + parseInt(this.serviceIntervalGenset);
			// },
			// serviceWarningGenset() {
			// 	return (this.hoursSinceLastServiceGenset > (0.9 * parseInt(this.serviceIntervalGenset))) && (this.hoursSinceLastServiceGenset <= parseInt(this.serviceIntervalGenset));
			// },
			// serviceOverdueGenset() {
			// 	return this.hoursSinceLastServiceGenset > parseInt(this.serviceIntervalGenset);
			// },
			// hoursSinceLastServiceGenset() {
			// 	return parseInt(this.lastEngineHoursGenset) - parseInt(this.lastServiceHoursGenset);
			// },

      serviceAnextServiceValueCalc() {
        return parseInt(this.serviceAinterval) + parseInt(this.serviceAlastServiceValue); 
      },
      serviceBnextServiceValueCalc() {
        return parseInt(this.serviceBinterval) + parseInt(this.serviceBlastServiceValue); 
      },
      serviceCnextServiceValueCalc() {
        return parseInt(this.serviceCinterval) + parseInt(this.serviceClastServiceValue); 
      },

      currentTruckID() {
        return this.$store.getters.getCurrentTruckID;
      },
      currentTruckName() {
        return this.$store.getters.getCurrentTruckName;
      },
      currentTruckUVI() {
        return this.$store.getters.getCurrentTruckUVI;
      },

      serviceAHistory() {
        return this.serviceHistoryAll.filter((doc) => doc.serviceName == this.serviceAname);
      },
      serviceBHistory() {
        return this.serviceHistoryAll.filter((doc) => doc.serviceName == this.serviceBname);
      },
      serviceCHistory() {
        return this.serviceHistoryAll.filter((doc) => doc.serviceName == this.serviceCname);
      }

		},

		data: function() {
			return {
				firebaseid: '',
				firebaseemail: '',
				serviceHistoryAll: [],
				currentService: '',
				showDetails: false,

				// serviceInterval: '',
				// lastEngineHours: '',
				// lastServiceHours: '',
				// lastServiceDate: '',

				// serviceIntervalGenset: '',
				// lastEngineHoursGenset: '',
				// lastServiceHoursGenset: '',
				// lastServiceDateGenset: '',

        lastMileage: 0,

        serviceAname: 'Service A',
        serviceAunit: '',
        serviceAinterval: 0,
        serviceAlastServiceValue: 0,
        serviceAlatestReading: 0,
        // serviceAnextServiceValue: 0,
        serviceAlastServiceDate: '',

        serviceBname: 'Service B',
        serviceBunit: '',
        serviceBinterval: 0,
        serviceBlastServiceValue: 0,
        serviceBlatestReading: 0,
        // serviceBnextServiceValue: 0,
        serviceBlastServiceDate: '',

        serviceCname: 'Service C',
        serviceCunit: '',
        serviceCinterval: 0,
        serviceClastServiceValue: 0,
        serviceClatestReading: 0,
        // serviceCnextServiceValue: 0,
        serviceClastServiceDate: '',

				editModal: false,
        editDropdownModal: false,
        editNameModal: false,
				editingItem: '',
				editingItemOldValue: '',
				editingItemValue: '',
				editingItemName: '',
        editingItemUnit: '',

        serviceAItems: [],
        serviceBItems: [],
        serviceCItems: [],

        showItemWindow: false,
        selectedItem: {},
        isActiveAddHelperItems: false,
        helperItems: [],
        selectedItems: [],

				//startingHours: 0,
				//runningFuelTotal: 0,

				//showResetFuel: false,
				//isFull: false,
				serviceTab: 'serviceA',
        serviceName: 'serviceAItems',
			}
		},

		methods: {

			onUserLogin: function( user ) {
      	if (user) {      		
	      	this.firebaseemail = user.email;
	      	//this.firebasedisplayname = user.displayName;
	      	this.firebaseid = user.uid;
	      	this.loadServiceInfo();
	      	this.loadServiceHistory();
          this.loadServiceChecklists();
          this.loadHelperItems();
	      }
      },

      setTab(tab) {
        this.serviceTab = tab;
        this.serviceName = tab + 'Items';
      },

      goBack: function() {
				this.$router.go(-1);
			},

			isNumber: function($evt) {
	      $evt = ($evt) ? $evt : window.event;
	      var charCode = ($evt.which) ? $evt.which : $evt.keyCode;
	      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
	        $evt.preventDefault();
	      } else {
	        return true;
	      }
	    },

      numberWithCommas(x) {
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },

      formatDate : function (sentDate,format) {
          let fixDate = (sentDate ? sentDate.toDate() : new Date());
          return moment(fixDate).format(format);
      },

      loadServiceInfo: function() {
      	var self = this;

				db.collection('trucks').doc(this.currentTruckID).onSnapshot(function(doc) {
					// serviceA
          self.lastMileage = doc.data().lastMileage ? doc.data().lastMileage : 0;

          self.serviceAname = doc.data().serviceAname ? doc.data().serviceAname : 'Service A';
					self.serviceAunit = doc.data().serviceAunit ? doc.data().serviceAunit : 'KM';
          self.serviceAinterval = doc.data().serviceAinterval ? doc.data().serviceAinterval : 0;
          self.serviceAlastServiceValue = doc.data().serviceAlastServiceValue ? doc.data().serviceAlastServiceValue : 0;
          self.serviceAlatestReading = doc.data().serviceAlatestReading ? doc.data().serviceAlatestReading : 0;
          // self.serviceAnextServiceValue = doc.data().serviceAnextServiceValue ? doc.data().serviceAnextServiceValue : 0;
					self.serviceAlastServiceDate = doc.data().serviceAlastServiceDate ? self.formatDate(doc.data().serviceAlastServiceDate,'D MMMM YYYY') : '';
          // serviceB
          self.serviceBname = doc.data().serviceBname ? doc.data().serviceBname : 'Service B';
          self.serviceBunit = doc.data().serviceBunit ? doc.data().serviceBunit : 'KM';
          self.serviceBinterval = doc.data().serviceBinterval ? doc.data().serviceBinterval : 0;
          self.serviceBlastServiceValue = doc.data().serviceBlastServiceValue ? doc.data().serviceBlastServiceValue : 0;
          self.serviceBlatestReading = doc.data().serviceBlatestReading ? doc.data().serviceBlatestReading : 0;
          // self.serviceBnextServiceValue = doc.data().serviceBnextServiceValue ? doc.data().serviceBnextServiceValue : 0;
          self.serviceBlastServiceDate = doc.data().serviceBlastServiceDate ? self.formatDate(doc.data().serviceBlastServiceDate,'D MMMM YYYY') : '';
          // serviceC
          self.serviceCname = doc.data().serviceCname ? doc.data().serviceCname : 'Service C';
          self.serviceCunit = doc.data().serviceCunit ? doc.data().serviceCunit : 'KM';
          self.serviceCinterval = doc.data().serviceCinterval ? doc.data().serviceCinterval : 0;
          self.serviceClastServiceValue = doc.data().serviceClastServiceValue ? doc.data().serviceClastServiceValue : 0;
          self.serviceClatestReading = doc.data().serviceClatestReading ? doc.data().serviceClatestReading : 0;
          // self.serviceCnextServiceValue = doc.data().serviceCnextServiceValue ? doc.data().serviceCnextServiceValue : 0;
          self.serviceClastServiceDate = doc.data().serviceClastServiceDate ? self.formatDate(doc.data().serviceClastServiceDate,'D MMMM YYYY') : '';
				});
      },

			loadServiceHistory: function() {
				var self = this;
				db.collection('trucks').doc(this.currentTruckID).collection('serviceLogs').orderBy('timestamp','desc').onSnapshot(function(querySnapshot) {
          self.serviceHistoryAll = [];
					querySnapshot.forEach(function(doc) {
						self.serviceHistoryAll.push({
							id: doc.id,
							mileage: doc.data().mileage ? doc.data().mileage : 0,
              logTimestamp: doc.data().logTimestamp,
              postedBy: doc.data().postedBy ? doc.data().postedBy : 'No name',
              notes: doc.data().notes ? doc.data().notes : 'No notes',
              serviceName: doc.data().serviceName ? doc.data().serviceName : 'No name',
						});
					});
				});
			},

      loadServiceChecklists() {
        var self = this;
        db.collection("trucks").doc(this.currentTruckID).collection("serviceAItems").onSnapshot(function(querySnapshot) {
          self.serviceAItems = [];
          querySnapshot.forEach(function(doc) {
            self.serviceAItems.push({
              id: doc.id,
              title: doc.data().title,
              details: doc.data().details ? doc.data().details : '',
              index: doc.data().index != null && doc.data().index != undefined ? doc.data().index : 999,
            });
          });
        });
        db.collection("trucks").doc(this.currentTruckID).collection("serviceBItems").onSnapshot(function(querySnapshot) {
          self.serviceBItems = [];
          querySnapshot.forEach(function(doc) {
            self.serviceBItems.push({
              id: doc.id,
              title: doc.data().title,
              details: doc.data().details ? doc.data().details : '',
              index: doc.data().index != null && doc.data().index != undefined ? doc.data().index : 999,
            });
          });
        });
        db.collection("trucks").doc(this.currentTruckID).collection("serviceCItems").onSnapshot(function(querySnapshot) {
          self.serviceCItems = [];
          querySnapshot.forEach(function(doc) {
            self.serviceCItems.push({
              id: doc.id,
              title: doc.data().title,
              details: doc.data().details ? doc.data().details : '',
              index: doc.data().index != null && doc.data().index != undefined ? doc.data().index : 999,
            });
          });
        });
      },

      loadHelperItems: function() {
        var self = this;

        db.collection("helperServiceItems").get().then(function(querySnapshot) {
          self.helperItems = [];
          querySnapshot.forEach(function(doc) {
            self.helperItems.push({
              id: doc.id,
              title: doc.data().title,
              details: doc.data().details,
              index: doc.data().index != null && doc.data().index != undefined ? doc.data().index : 999,
            });
          });
        });
      },

      saveHelperItems: function() {
        console.log(this.selectedItems);
        NProgress.start();
        var self = this;
        if (this.selectedItems.length) {
          this.selectedItems.forEach(function(item) {
            db.collection("trucks").doc(self.currentTruckID).collection(self.serviceName).add({
              title: item.title ? item.title : 'No title',
              details: item.details ? item.details : '',
              index: item.index ? item.index : 999,
            })
            .then(() => {
              self.isActiveAddHelperItems = false;
              self.selectedItems = [];
                NProgress.done();
                var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
                addTruckRevision({
                  userName: self.firebasedisplayname,
                  userID: self.firebaseid,
                  truckName: self.currentTruckName,
                  truckID: self.currentTruckID,
                  revisionDetails: 'Service checklist item added from helpers',
                });
              })
            .catch(error => {
              console.error("Error adding alert: ", error);
              self.isActiveAddHelperItems = false;
              NProgress.done();
            });
          });    
        } else {
          console.log("nothing selected");
          this.isActiveAddHelperItems = false;
          NProgress.done();
        }
      },

      launchItemWindow: function(itemID,itemTitle,itemDetails,action) {
        this.showItemWindow = true;
        this.selectedItem = {
          id: itemID,
          title: itemTitle,
          details: itemDetails,
          heading: 'Item',
          action: action
        };
      },

      launchAddHelperItems() {
        this.isActiveAddHelperItems = true;
      },

      returnedItem: function(returnItem) {
        
        this.showItemWindow = false;
        this.selectedItem = {};

        if (returnItem.edited) {
          NProgress.start();
          let self = this;
          if (returnItem.isNewItem) {
            db.collection('trucks').doc(self.currentTruckID).collection(self.serviceName).add({
              title: returnItem.title ? returnItem.title : 'No title',
              details: returnItem.details ? returnItem.details : '',
            })
            .then(() => {
              NProgress.done();
              var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
              addTruckRevision({
                userName: self.firebasedisplayname,
                userID: self.firebaseid,
                truckName: self.currentTruckName,
                truckID: self.currentTruckID,
                revisionDetails: 'New service checklist item added',
              });
            })
            .catch(error => {
              console.error("Error adding new item: ", error);
              NProgress.done();
            });
          } else {
            db.collection('trucks').doc(self.currentTruckID).collection(self.serviceName).doc(returnItem.id).update({
              title: returnItem.title ? returnItem.title : 'No title',
              details: returnItem.details ? returnItem.details : '',
            })
            .then(() => {
              NProgress.done();
              var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
              addTruckRevision({
                userName: self.firebasedisplayname,
                userID: self.firebaseid,
                truckName: self.currentTruckName,
                truckID: self.currentTruckID,
                revisionDetails: 'Service checklist item updated',
              });
            })
            .catch(error => {
              console.error("Error updating item: ", error);
              NProgress.done();
            });
          }
        } else {
          NProgress.done();
        }

      },

      enableSortModal: function(sentArray) {
        console.log(sentArray);
        // this.truckItems = this.sorted(this.truckItems);
        sentArray = this.sorted(sentArray);
        this.enableSort = true;
      },

      sorted: function(arr) {
        // Set slice() to avoid to generate an infinite loop!
        // console.log('Arr:',arr);
        return arr.slice().sort(function(a, b) {
          return a.index - b.index;
        });
      },


			// updateNextService: function() {
			// 	console.log('Updating next service hours...');
			// 	const lastInterval = ( Math.floor( parseInt(this.lastEngineHours) / parseInt(this.serviceInterval) ) ) * parseInt(this.serviceInterval);
			// 	this.nextServiceHours = lastInterval + parseInt(this.serviceInterval);
			// },

			close: function() {
				this.showDetails = false;
				this.editModal = false;
        this.editDropdownModal = false;
        this.editingItemValue = '';
        this.editingItem = '';
        this.editingItemOldValue = '';
        this.editingItemUnit = '';
        this.editNameModal = false;
        this.showItemWindow = false;
        this.isActiveAddHelperItems = false;
        this.selectedItems = [];
				//this.showResetFuel = false;
			},

			showServiceDetails: function(service) {
				this.currentService = service;
				this.showDetails = true;
			},

			editItem: function(itemName,itemSlug,itemValue, itemUnit) {
				this.editingItemName = itemName;
				this.editingItem = itemSlug;
				this.editingItemOldValue = itemValue;
        this.editingItemUnit = itemUnit;
				this.editModal = true;
				this.$refs.focused.focus();
			},

      editServiceUnit(itemName,itemSlug,itemValue) {
        // console.log(itemName,itemSlug,itemValue);
        this.editingItemName = itemName;
        this.editingItem = itemSlug;
        this.editingItemValue = itemValue;
        this.editDropdownModal = true;
      },

      editItemName: function(itemSlug,itemValue, itemUnit) {
        this.editingItemName = 'Change Service Name';
        this.editingItem = itemSlug;
        this.editingItemOldValue = itemValue;
        this.editingItemUnit = itemUnit;
        this.editNameModal = true;
        this.$refs.focused.focus();
      },

      saveServiceInterval() {
        let self = this;
        NProgress.start();
        let newValue = {};
        newValue[self.editingItem] = parseInt(self.editingItemValue);
        // console.log(Number.isInteger(parseInt(self.editingItemValue)));
        db.collection('trucks').doc(self.currentTruckID).update(newValue)
        .then(function() {
          self.close()
          NProgress.done();
        }).catch(function(err) {
          self.close()
          console.log(err);
          NProgress.done();
        });
      },

			saveEditedItem: function() {
				var self = this;
				//console.log(this.editingItem,this.editingItemValue);
				NProgress.start();
				var newValue = {};
				newValue[self.editingItem] = self.editingItemValue;
				//console.log(newValue);
				db.collection('trucks').doc(self.currentTruckID).update(newValue)
				.then(function() {
					self.close()
					NProgress.done();
				}).catch(function(err) {
					self.close()
					console.log(err);
				  NProgress.done();
				});
			},

      deleteService(serviceID) {
        console.log('Delete: ', serviceID);
        this.showDetails = false;
        var self = this;    

        let message = {
          title: "Delete this Service?",
          body: "This cannot be undone.",
        };
        let options = {
          okText: "DELETE",
          backdropClose: true,
          cancelText: "Cancel",
        };
        this.$dialog
        .confirm(message, options)
        .then(function() {
          NProgress.start();  
          db.collection("trucks").doc(self.currentTruckID).collection('serviceLogs').doc(serviceID).delete()
          .then(function(){ 
            NProgress.done();
            var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
            addTruckRevision({
              userName: self.firebasedisplayname,
              userID: self.firebaseid,
              truckName: self.currentTruckName,
              truckID: self.currentTruckID,
              revisionDetails: 'Service log deleted',
            });
          });
        })
        .catch(function() {
          console.log('Clicked on cancel'); 
          NProgress.done();
        });
      },

      deleteItem: function(itemID) {        
        var self = this;    
        let dialogueMsg = "<h5>Remove this Item?</h5>";
        let dialogueOptions = {
          html: true,
          //loader: true,
          okText: 'Yes, remove',
          cancelText: 'No, go back',
          message: 'This is a message',
          backdropClose: true
        };
        this.$dialog
        .confirm(dialogueMsg, dialogueOptions)
        .then(function() {
          NProgress.start();  
          db.collection("trucks").doc(self.currentTruckID).collection(self.serviceName).doc(itemID).delete()
          .then(function(){ 
            NProgress.done();
            var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
            addTruckRevision({
              userName: self.firebasedisplayname,
              userID: self.firebaseid,
              truckName: self.currentTruckName,
              truckID: self.currentTruckID,
              revisionDetails: 'Service checklist item deleted',
            });
          });
        })
        .catch(function() {
          console.log('Clicked on cancel'); 
          NProgress.done();
        });
      },

      saveEditedModalItem() {
        let self = this;
        //console.log(this.editingItem,this.editingItemValue);
        NProgress.start();
        let newValue = {};
        newValue[self.editingItem] = self.editingItemValue;
        //console.log(newValue);
        db.collection('trucks').doc(self.currentTruckID).update(newValue)
        .then(function() {
          self.close();
          NProgress.done();
        }).catch(function(err) {
          self.close();
          console.log(err);
          NProgress.done();
        });
      },

			// resetFuel: function() {
			// 	var self = this;  	
			// 	NProgress.start();
			// 	db.collection('trucks').doc(self.truckID).update({
			// 		startingHours: self.lastEngineHours,
			// 		runningFuelTotal: 0
			// 	}).then(function() {
			// 		self.showResetFuel = false;
			// 		self.isFull = false;
			// 		NProgress.done();
			// 	});
			// },


		}

	}

</script>



<style lang="scss" scoped>
	
	h3 {
		margin: 1rem 0;
		text-transform: uppercase;
	}
  h5 {
    font-size: 1.5rem;
  }
  .mileage {
    margin-bottom: 2rem;
    font-weight: bold;
    line-height: 33px;
    span {
      &.is-primary {
        font-weight: normal;
        margin-left: 5px;
      }
    }
  }
	table {
		margin-bottom: 3rem !important;
		table-layout: fixed;
    tr {
      td {
        line-height: 2rem;
        .button {
          margin-left: 10px;
        }
        &.bold {
          font-weight: bold;
        }
        &.red {
          color: red;
        }
        &.green {
          color: green;
        }
      }
    }
	}
  .modal-card-body {
    input[type="tel"] {
      display: inline-block;
      width: 120px;
    }
    .unit {
      line-height: 40px;
      display: inline-block;
      margin-left: 10px;
    }
  }
  .tabs {
    li {
      max-width: 33%;
      overflow: hidden;
      a {
        justify-content: left;
        text-align: left;
      }
    }
  }

</style>