<template>  

    <div> 

        <div class="site-wrapper no-header">

          <div class="container no-bottom">
            <ul class="scroller">
              <li><router-link to="my-dashboard">Dashboard</router-link></li>
              <li class="is-active"><a aria-current="page">My Fleets</a></li>
            </ul>
          </div>

          <div class="container">

              <div class="card">

                <h4 class="title">My Fleets</h4>

                <div class="spacer s20"></div>

                <div v-if="myFleets.length > 0">
                      
                  <div class="fleet-link" v-for="(fleet) in myFleets" :key="fleet.fleetID">

                    <!-- <router-link :to="{name: 'FleetDashboard', params: {fleetID: fleet.fleetID, fleetName: fleet.fleetName} }"> -->
                    <div class="content-wrapper" @click="toFleetDashboard(fleet)">
                      <!-- <div class="content"><font-awesome-icon icon="briefcase" /><span>{{fleet.fleetName}}</span></div> -->
                      <!-- <div :style="{backgroundImage:`url(${logoSrc})`}" class="round-button">
                        
                      </div> -->
                      <div class="round-button-letters">
                        <span>{{makeLetters(fleet.fleetName)}}</span>
                      </div>
                      <div class="content-box">
                        <div class="content-align">
                          <h2>{{fleet.fleetName}}</h2>
                          <span>{{fleet.fleetState}}</span>
                        </div>
                      </div>
                      <div class="clear">
                        
                      </div>
                    </div>
                    <!-- </router-link> -->
                    <!-- <router-link :to="{ name: 'places', params: {placeName: detail.name } }">more..</router-link> -->

                  </div>

                </div>

                <div v-else class="">
                  <p>You haven't set up any Fleets yet.</p><br>
                </div>

                <div class="spacer s20"></div>

                <div class="buttons">
                  <span class="button is-orange" @click="addFleetModal = true"><font-awesome-icon icon="plus" />&nbsp;Add a new Fleet</span>
                  <span v-if="myFleets.length > 0" class="button is-light" @click="deleteFleetModal = true"><font-awesome-icon icon="trash-alt" />&nbsp;Delete a Fleet</span>
                </div>

                <div class="clear"></div>

                


              </div>

              <hr>
              <router-link to="my-dashboard">
                <span class="button is-info">Back to My Dashboard</span>
              </router-link>

              <div class="spacer s90"></div>


              <!-- <sticky-footer></sticky-footer> -->


              <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':addFleetModal}">
                <div class="modal-background" @click="close"></div>
                <div class="modal-card">
                    <header class="modal-card-head">
                      <p class="modal-card-title">Add a new Fleet</p>
                      <button class="delete" aria-label="close" @click.prevent="close"></button>
                    </header>

                    <section class="modal-card-body has-text-centered">
                      <input class="input" type="text" v-model="newFleetName" placeholder="Type Fleet name..." maxlength="700" />
                      <div class="form-group-reg">
                        <label class="label-fixed">State where Fleet is based:</label>
                        <div class="select">
                          <select v-model="newFleetState">
                            <option disabled value="">Choose state</option>
                            <option v-for="state in states" :value="state.name" :key="state.name">{{ state.name }}</option>
                          </select>
                        </div>
                      </div>
                    </section>

                    <footer class="modal-card-foot">
                      <button class="button is-primary" :disabled="showSaveButton" @click="saveNewFleet()">Save New Fleet</button>
                      <button class="button cancel" @click.prevent="close">Cancel</button>
                    </footer>
                </div>
              </div>


              <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':deleteFleetModal}">
                <div class="modal-background" @click="close"></div>
                <div class="modal-card">
                    <header class="modal-card-head is-danger">
                      <p class="modal-card-title">Delete a Fleet</p>
                      <button class="delete" aria-label="close" @click.prevent="close"></button>
                    </header>

                    <section class="modal-card-body has-text-centered">
                      <div class="form-group-reg">
                        <label class="label-fixed">Select a Fleet to Delete:</label>
                        <div class="select">
                          <select v-model="fleetToDelete">
                            <option disabled value="">Choose Fleet</option>
                            <option v-for="fleet in myFleets" :value="fleet" :key="fleet.fleetID">{{ fleet.fleetName }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="delete-vessel" v-if="fleetToDelete != ''">
                        <p><span><font-awesome-icon icon="exclamation-triangle" />&nbsp;</span>Are you sure you want to delete {{ fleetToDelete.fleetName }}? This cannot be undone. </p>
                      </div>
                    </section>

                    <footer class="modal-card-foot">
                      <button class="button is-danger" :disabled="fleetToDelete == ''" @click="deleteFleet()">Delete this Fleet</button>
                      <button class="button cancel" @click.prevent="close">Cancel</button>
                    </footer>
                </div>
              </div>


          </div>



        </div>


    </div>


</template>






<script>
  
  import { db } from '../main.js';
  import firebase from 'firebase/app';
  import 'firebase/auth'; 
  import 'firebase/firestore';
  import 'firebase/functions';
  import NProgress from 'nprogress';

  // const StickyFooter = () => import('../reusables/StickyFooter.vue');

  

  export default {

    name: 'MyFleets',
    props: {
      
    },
    components: {
      // StickyFooter,
    },
    mounted: function() {
      // console.log('myFleets', this.$store);
      firebase.auth().onAuthStateChanged( this.onUserLogin );
    },
    computed: {
      userRole() {
        return this.$store.getters.getSubscriptionInfo.userrole;
      },
      myFleets() {
        return this.$store.getters.getMyFleets;
      },
      showSaveButton() {
        return this.newFleetName == '' || this.newFleetState == '';
      },
      userData() {
        return this.$store.getters.getSubscriptionInfo;
      },
    },
    data: function() {
      return {
        firebaseid: '',
        firebaseemail: '',
        // logoSrc: 'https://firebasestorage.googleapis.com/v0/b/dev-offshoresms.appspot.com/o/images%2Ffb76de00-9848-11ea-bd8a-07328cc07e49.png?alt=media&token=6934baaa-54ed-4821-a346-3c88fd241436',
        addFleetModal: false,
        deleteFleetModal: false,
        newFleetName: '',
        newFleetState: '',
        newFleetID: '',
        states: [
          { name: "Tasmania" },
          { name: "Victoria" },
          { name: "New South Wales" },
          { name: "Queensland" },
          { name: "South Australia" },
          { name: "Western Australia" },
          { name: "Northern Territory" }
        ],
        fleetToDelete: '',

      }
    },

    methods: {
      onUserLogin: function( user ) {
        if (user) {         
          this.firebaseid = user.uid;
          this.firebaseemail = user.email;
        }
      },

      makeLetters(fleetName) {
        let res = fleetName.split(" ");
        if (res.length > 1) {
          return res[0].substring(0, 1) + res[1].substring(0, 1);
        } else {
          return res[0].substring(0, 1);
        }
      },

      close() {
        this.addFleetModal = false;
        this.newFleetName = '';
        this.newFleetState = '';
        this.newFleetID = '';
        this.deleteFleetModal = false;
        this.fleetToDelete = '';
      },

      toFleetDashboard(fleet) {
        // console.log(fleet);
        NProgress.start();
        // let self = this;
        this.$store.dispatch('SETCURRENTFLEET',{fleet});
        this.$router.push({ 
          name: 'FleetDashboard'
        });
      },

      saveNewFleet() {
        NProgress.start();
        this.addFleetModal = false;
        let self = this;
        db.collection('fleets').add({
          fleetName: self.newFleetName,
          fleetState: self.newFleetState,
          adminUsers: firebase.firestore.FieldValue.arrayUnion(self.firebaseid),
          fleetEmail: self.firebaseemail != '' ? self.firebaseemail : 'help@safetruckie.com.au',
          maxTrucks: 0,
          primaryUserEmail: self.firebaseemail != '' ? self.firebaseemail : 'help@safetruckie.com.au',
          primaryUserID: self.firebaseid,
          primaryUserName: self.userData.username ? self.userData.username : '',
          primaryUserPhone: self.userData.userphone ? self.userData.userphone : '',
        })
        .then(function(docRef) {
          self.newFleetID = docRef.id;
          db.collection('userdata').doc(self.firebaseid).collection('myFleets').doc(docRef.id).set({
            fleetID: docRef.id,
            fleetName: self.newFleetName,
            fleetState: self.newFleetState,
          }, { merge: true });
        })
        .then(function() {
          self.close();
          NProgress.done();
        })
        .catch((error) => {
          console.log('Error', error);
          NProgress.done();
        });
      },

      deleteFleet() {
        NProgress.start();
        // console.log(this.fleetToDelete);
        let self = this;
        db.collection('userdata').doc(this.firebaseid).collection('myFleets').doc(this.fleetToDelete.fleetID).delete()
        .then(function() {
          self.close();
          NProgress.done();
        })
        .catch((error) => {
          console.log('Error', error);
          NProgress.done();
        });
      },



    },  

  }




</script>




<style lang="scss" scoped>
  
  .delete-vessel {
    color: red;
    span {
      margin-right: 10px;
    }
  }
  .form-group-reg {
    text-align: left;
    margin-top: 1rem;
  }
  .fleet-link {
    width: 100%;
    position: relative;
    cursor: pointer;
    .content-wrapper {
      .round-button-letters {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        text-align: center;
        position: relative;
        background: steelblue;
        display: block;
        margin: 0 2rem 2rem 0;
        float: left;
        transition: 0.3s;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        span {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          line-height: 1rem;
          font-size: 4rem;
          color: #fff;
          font-weight: 900;
        }
      }
      .content-box {
        height: 150px;
        width: calc(100% - 200px);
        float: left;
        position: relative;
        .content-align {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          position: relative;
          &:after {
            content: "";
            position: absolute;
            z-index: -1;
            left: 0;
            width: 0;
            bottom: -10px;
            background: steelblue;
            height: 5px;
            transition-property: width;
            transition-duration: 0.3s;
            transition-timing-function: ease-out;
          }
          h2 {
            font-size: 1.5rem;
            line-height: 1.8rem;
          }
          span {

          }
        }
      }
      &:hover {
        .content-align:after {
          left: 0;
          right: auto;
          width: 200px;
        }
      }
    }
    &:nth-of-type(2n) {
      .round-button-letters {
        background: cadetblue;
      }
      .content-box {
        .content-align {
          &:after {
            background: cadetblue;
          }
        }
      }
    }
    &:nth-of-type(3n) {
      .round-button-letters {
        background: skyblue;
      }
      .content-box {
        .content-align {
          &:after {
            background: skyblue;
          }
        }
      }
    }
    .content {
      position: absolute;
      top: 50%;
      transform: translate(-50%,-50%);
      left: 50%;
      display: block;
      margin: 0 auto;
      font-size: 1.5rem;
      line-height: 1.8rem;
      svg {
        display: block;
        margin: 1rem auto;
      }
      span {
        display: block;
      }
    }
  }

  @media only screen and (max-width: 767px) { 

    .fleet-link {
      .content-wrapper {
        .round-button-letters {
          width: 100px;
          height: 100px;
          span {
            font-size: 2.7rem;
          }
        }
        .content-box {
          height: 100px;
          width: calc(100% - 100px);
          .content-align {
            h2 {
              font-size: 1.2rem;
              line-height: 1.5rem;
            }
          }
        }
      }
    }

  }

  @media only screen and (max-width: 479px) { 

    .fleet-link {
      .content-wrapper {
        .round-button-letters {
          width: 70px;
          height: 70px;
          margin-right: 1rem;
          span {
            font-size: 1.7rem;
          }
        }
        .content-box {
          height: auto;
          margin-bottom: 2rem;
          .content-align {
            top: 0;
            transform: none;
            h2 {
              font-size: 1.2rem;
              line-height: 1.5rem;
            }
          }
        }
      }
    }



  }

    


</style>



