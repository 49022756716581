<template>
  
  <div>

    <div class="fleet-wrapper">

      <fleet-left-sidebar></fleet-left-sidebar>

      <div class="main-fleet-content">

          <div class="two-thirds">
            
            <div class="before-icon"><font-awesome-icon icon="users" /></div><h4 class="icon">Add a new Staff Member</h4>  
            <div class="clear"></div>

            <div>

                <!-- <div class="form-group">   
                    <div class="select is-primary">
                      <select v-model="state">
                        <option disabled value="">Choose a State</option>
                        <option v-for="(state, index) in allstates" :key="index">
                          {{ state.name }}
                        </option>
                      </select>
                    </div>
                </div> -->

                <div class="spacer s30"></div>

                <div class="notification is-primary">
                  <input class="input" v-model="findName" id="filtername" type="text" placeholder="Start typing staff name..." />
                </div>

                <div class="box" v-if="findName.length > 2">

                  <div v-if="filteredNames.length">
                    <ul class="staff-list">
                      <li v-for="(staff, index) in filteredNames" :key="index">
                        <span class="staff-name">{{staff.name}}</span>
                        <span v-if="!staff.connected" @click="connect(staff.id,staff.name,staff.phone,staff.email)" class="button is-small is-success">Add Staff</span>
                        <span v-else class="button is-small">Current Staff</span>
                        <div class="clear"></div>
                      </li>
                    </ul>
                  </div>

                  <div v-else>
                    <p>No staff matched your search.</p>
                  </div>

                </div>

            </div>

            <hr>

              <!-- <div class="before-icon"><font-awesome-icon icon="users" /></div><h4 class="icon">Or manually add a new staff member</h4>  
              <div class="clear"></div>

              <div class="buttons">
                <button :disabled="true" class="button is-warning" @click="addNewStaff = true">Add new staff member</button>
              </div> -->

            <!-- <hr>

            <div>
              
              <div class="before-icon"><font-awesome-icon icon="users" /></div><h4 class="icon">Invite someone new to join SafeTruckie</h4>  
              <div class="clear"></div>

              <div class="buttons">
                <button class="button is-primary" @click="smsInvite = true">Invite by SMS</button>
                <button class="button is-warning" @click="emailInvite = true">Invite by Email</button>
              </div>

            </div> -->


            <div class="spacer s40"></div>

            <!-- <hr>

            <router-link class="button is-info" to="fleet-dashboard">Back to Fleet Dashboard</router-link> -->

          </div>

          <div class="one-third">
            
            <div class="before-icon"><font-awesome-icon icon="users" /></div><h4 class="icon">Quick Links</h4>  
            <div class="clear"></div>

            
            <div class="buttons">
              <router-link to="fleet-staff" class="button is-primary">All staff members</router-link>
              <!-- <router-link to="fleet-admins" class="button is-info">Manage Admins</router-link> -->
            </div>

          </div>

          <div class="spacer s90"></div>
        


          <div class="clear"></div>

          <div class="spacer s90"></div>

      </div>



      <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':smsInvite}">
          <div class="modal-background" @click="close"></div>
          <div class="modal-card">
            
              <header class="modal-card-head is-primary">
                <p class="modal-card-title">Send SMS Invitation</p>
                <button class="delete" aria-label="hasSeenNotice" @click="close"></button>
              </header>

              <section class="modal-card-body">       
                
                <div class="form-group-reg">      
                  <label>Mobile number</label>
                  <input class="input" type="tel" placeholder="Starting with 04.." v-model="newphone" maxlength="10" v-on:keypress="isNumber($event)">
                </div>


              </section>
              <footer class="modal-card-foot">
                <button :disabled="!phoneNumberValid" class="button is-primary" @click.prevent="sendSMS">Send</button>
                <button class="button" @click="close">Close</button>
              </footer>        

          </div>
        </div>




        <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':emailInvite}">
          <div class="modal-background" @click="close"></div>
          <div class="modal-card">
            <!-- <form action="" @submit.prevent="sendEmail"> -->
              <header class="modal-card-head is-info">
                <p class="modal-card-title">Send Email Invitation</p>
                <button class="delete" aria-label="hasSeenNotice" @click="close"></button>
              </header>

              <section class="modal-card-body">       
                
                <div class="form-group-reg">      
                  <label>Name of person you're inviting:</label>
                  <input class="input" type="text" autocomplete="off" placeholder="First and last name" v-model="newname">
                </div>

                <div class="form-group-reg">      
                  <label>Their email address:</label>
                  <input class="input" type="text" placeholder="Email address" v-model="newemail">
                </div>

              </section>
              <footer class="modal-card-foot">
                <button :disabled="newname == '' || !isEmailValid" @click="sendEmail" class="button is-info">Send</button>
                <button class="button" @click="close">Close</button>
              </footer>        
             <!-- </form> -->
          </div>
        </div>




        <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':addNewStaff}">
          <div class="modal-background" @click="close"></div>
          <div class="modal-card">
              <header class="modal-card-head is-warning">
                <p class="modal-card-title">Add a staff member</p>
                <button class="delete" aria-label="hasSeenNotice" @click="close"></button>
              </header>

              <section class="modal-card-body">       
                <p>Add a new staff member if you can't find them on SafeTruckie. They will get a text message to let them know you've added them to your business.</p>
                <p>&nbsp;</p>
                <div class="form-group-reg">      
                  <!-- <label>Name of new staff:</label> -->
                  <input class="input" type="text" autocomplete="off" placeholder="First and last name" v-model="newStaffName">
                </div>

                <div class="form-group-reg">   
                  <!-- <label>Australian Mobile number</label>  --> 
                  <input class="input" type="tel" placeholder="Australian mobile number" v-model="newStaffPhone" maxlength="10" v-on:keypress="isNumber($event)">
                </div>

                <div class="form-group-reg">      
                  <!-- <label>Staff email address:</label> -->
                  <input class="input" type="text" placeholder="Email address (optional)" v-model="newStaffEmail">
                </div>

              </section>
              <footer class="modal-card-foot">
                <button :disabled="showAddNewStaff" @click="addNewStaffMember" class="button is-warning">Add Staff</button>
                <!-- <button @click="addNewStaffMember" class="button is-warning">Add Staff</button> -->
                <button class="button" @click="close">Close</button>
              </footer>        
          </div>
        </div>





    </div>


  </div>

</template>



<script>
  
import { db } from '../main.js';
import firebase from 'firebase/app';
import 'firebase/auth';
import NProgress from 'nprogress';
const FleetLeftSidebar = () => import('../reusables/FleetLeftSidebar.vue');
// const SimpleModal = () => import('../reusables/SimpleModal.vue');

export default {

  components: {
    FleetLeftSidebar,
      // SimpleModal
  },
  props: {

  },
  computed: {
    filteredStates() {
      let filter = this.state;
      //console.log("F: ",this.state,filter);
      return this.allUsers.filter(item => item.state.match(filter));
    },
    filteredNames() {
      let filter = new RegExp(this.findName, 'i')
      // return this.filteredStates.filter(item => item.name.match(filter));
      return this.allUsers.filter(item => item.name.match(filter));
    },
    userData() {
      return this.$store.getters.getSubscriptionInfo;
    },
    phoneNumberValid() {
      return this.newphone.length == 10 && this.newphone.slice(0,2) == '04';
    },
    newPhoneNumberValid() {
      return this.newStaffPhone.length == 10 && this.newStaffPhone.slice(0,2) == '04';
    },
    isEmailValid() {
      return (this.newemail == "") ? false : (this.reg.test(this.newemail)) ? true : false;
    },
    showAddNewStaff() {
      return this.newStaffName == '' || (this.newStaffPhone.length > 0 && !this.newPhoneNumberValid);
    },
    currentFleet() {
      return this.$store.getters.getCurrentFleet;
    }
  },

  mounted: function() {
    firebase.auth().onAuthStateChanged( this.onUserLogin );     
  },

  data() {
    return {
      firebaseid: '',
      myStaff: [],
      allUsers: [],
      findName: '',
      state: '',
      allstates: [
        { name: "Tasmania" },
        { name: "Victoria" },
        { name: "New South Wales" },
        { name: "Queensland" },
        { name: "South Australia" },
        { name: "Western Australia" },
        { name: "Northern Territory" }
      ],
      myStaffIDs: [],

      smsInvite: false,
      emailInvite: false,
      newphone: '',
      newemail: '',
      newname: '',
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,

      addNewStaff: false,
      newStaffName: '',
      newStaffEmail: '',
      newStaffPhone: '',

    }
  },

  methods: {

    onUserLogin: function( user ) {
      if (user) {         
        this.firebaseid = user.uid;
        this.loadMyStaff();
        
      }
    },

    finalNumber: function(num) {
        return "+61" + num.slice(1,10);
      },

      loadMyStaff: function() {
        var self = this;

        db.collection('fleets').doc(this.currentFleet.id).collection('fleetStaff')
        .onSnapshot(function(querySnapshot) {
            self.myStaffIDs = [];
            querySnapshot.forEach(function(doc) {
                self.myStaffIDs.push(doc.data().staffID);
            });
        });       

        db.collection("userdata").onSnapshot(function(querySnapshot) {
            self.allUsers = [];
            querySnapshot.forEach(function(doc) {
                var data = doc.data();
                let isConnected = ( self.myStaffIDs.includes(data.userid) ? true : false );
                // if (data.userid && data.userstate) {
                if (data.userid) {
                  self.allUsers.push({
                    id: data.userid,
                    name: data.username,
                    userid: data.userid,
                    phone: data.userphone,
                    email: data.useremail,
                    // state: data.userstate,
                    connected: isConnected
                  }); 
                }
            });
        });
      },

      addNewStaffMember: function() {
        NProgress.start();
        let self = this;
        let finalNum = this.newStaffPhone.length > 0 ? this.finalNumber(this.newStaffPhone) : '';
        let newID;
        db.collection('userdata').doc(this.firebaseid).collection('myTemporaryStaff').add({
          staffName: self.newStaffName,
          staffPhone: finalNum,
          staffEmail: self.newStaffEmail,
          dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(function(docRef) {
          newID = docRef.id;
          var addTemporaryStaff = firebase.functions().httpsCallable('addTemporaryStaff');
          addTemporaryStaff({
            newID: newID,
            staffName: self.newStaffName,
            staffPhone: finalNum,
            staffEmail: self.newStaffEmail,
            dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
            createdByUserName: self.firebasedisplayname,
            createdByUserID: self.firebaseid,
          }); 
        })
        .then(function() {
          // console.log('New temporary staff created with ID:',newID);
          self.addNewStaff = false;
          self.$router.replace('fleet-staff');
          NProgress.done();
        })
        .catch(function(err) {
          console.log('Error',err);
          NProgress.done();
        });
      },

      connect: function(staffID,staffName,staffPhone,staffEmail) {
        // console.log(staffID,staffName,staffPhone,staffEmail,this.currentFleet.id,this.currentFleet.name);

        let self = this;    
        let message = {
          title: 'Add ' + staffName + ' as fleet staff?',
          body: 'A text message will be sent to notify this staff member'
        };
        let options = {
          okText: 'Yes, Add',
          backdropClose: true,
          cancelText: 'Cancel',
        };
        this.$dialog
          .confirm(message, options)
          .then(function() {
            NProgress.start();  
            //
            db.collection('fleets').doc(self.currentFleet.id).update({
              isAddingStaff: true
            })
            .then(function() {

              var fleetAddStaff = firebase.functions().httpsCallable('fleetAddStaff');
              fleetAddStaff({
                staffID: staffID, 
                staffName: staffName,
                staffPhone: staffPhone,
                staffEmail: staffEmail,
                fleetID: self.currentFleet.id,
                fleetName: self.currentFleet.name
              }); 
              NProgress.done();
              //self.loadMyStaff();
              self.$router.replace('fleet-staff');

            });

              
              
          })
          .catch(function() {
            console.log('Clicked on cancel'); 
            NProgress.done();
          });

      },

      isNumber: function($evt) {
        $evt = ($evt) ? $evt : window.event;
        var charCode = ($evt.which) ? $evt.which : $evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          $evt.preventDefault();
        } else {
          return true;
        }
      },

      sendSMS: function() {
        let self = this;      
        let message = {
          title: 'Send this SMS?',
          body: 'By clicking SEND, you confirm that you know this person.'
        };
        let options = {
          okText: 'Yes, SEND',
          backdropClose: true,
          cancelText: 'Cancel',
        };
        self.smsInvite = false;
        let sender = self.firebasedisplayname == undefined ? 'Someone' : self.firebasedisplayname;
        let smsMessage = sender + ' has invited you to join SafeTruckie. You can join for FREE at https://safetruckie.com.au';
        this.$dialog
          .confirm(message, options)
          .then(function() {
            NProgress.start();  
            var sendTwilioSMS = firebase.functions().httpsCallable('sendTwilioSMS');
            sendTwilioSMS({
              toNumber: self.finalNumber(self.newphone),
              message: smsMessage,
              userName: self.firebasedisplayname,
              userID: self.firebaseid
            });
            self.close();
            self.$router.push('my-staff');
            NProgress.done();
          })
          .catch(function(error) {
            console.log(error);
            NProgress.done();
          });  
      },

      close: function() {
        this.smsInvite = false;
        this.emailInvite = false;
        this.newphone = '';
        this.newname = '';
        this.newemail = '';
        this.addNewStaff = false;
        this.newStaffName = '';
        this.newStaffEmail = '';
        this.newStaffPhone = '';
      },

      sendEmail: function() {
        let self = this;    
        let message = {
          title: 'Send this Email?',
          body: 'By clicking SEND, you confirm that you know this person.'
        };
        let options = {
          okText: 'Yes, SEND',
          backdropClose: true,
          cancelText: 'Cancel',
        };
        let sender = self.firebasedisplayname == undefined ? 'Someone' : self.firebasedisplayname;
        self.emailInvite = false;
        this.$dialog
          .confirm(message, options)
          .then(function() {
            NProgress.start();  
            var sendEmailInvitation = firebase.functions().httpsCallable('sendEmailInvitation');
            sendEmailInvitation({
              inviteeName: self.newname,
              inviteeEmail: self.newemail,
              senderName: sender,
              senderID: self.firebaseid
            });
            self.close();
            self.$router.push('my-staff');
            NProgress.done();
          })
          .catch(function(error) {
            console.log(error);
            NProgress.done();
          });  


      },


  }



}


</script>



<style lang="scss" scoped>
  

 
  .select {
    width: 100%;
    max-width: 400px;
    select {
      width: 100%;
    }
  }

  h4 {
    text-transform: uppercase;
    margin-bottom: 0.8rem;
    font-weight: bold;
  }

  ul.staff-list {
    max-width: 320px;
    li {
      margin-bottom: 1rem;
      &:last-of-type {
        margin-bottom: 0;
      }
      .staff-name {
        float: left;
      }
      .button {
        float: right;
      }
    }
  }


</style>


