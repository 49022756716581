<template>

  <div class="fleet-wrapper" v-if="currentFleet.id && userIsAdmin">

       

      <fleet-left-sidebar :fleetID="currentFleet.id" :fleetName="currentFleet.name"></fleet-left-sidebar>

      <div class="main-fleet-content">
        
        <h5 class="dash-heading">Fleet Dashboard - {{currentFleet.name}}</h5>
        <div class="spacer s30">
          
        </div>

        <div class="dash-widget-wrapper">
          <div class="dash-widget half">
            <h3><font-awesome-icon icon="info-circle" /> &nbsp; Business Information</h3>
            <!-- {{fleetName}} / {{fleetID}} -->
            <!-- {{currentFleet}} -->
            <table class="clean">
              <tbody>
                <tr>
                  <th>Fleet name</th>
                  <td>{{currentFleet.name}}</td>
                </tr>
                <!-- <tr>
                  <th>Fleet ID</th>
                  <td>{{currentFleet.id}}</td>
                </tr> -->
                <tr>
                  <th>State</th>
                  <td>{{currentFleet.state}}</td>
                </tr>
                <tr>
                  <th>Current Admin</th>
                  <td>{{userData.username}}</td>
                </tr>
                <tr>
                  <th>Total Drivers</th>
                  <td>{{driverCount}}</td>
                </tr>
                <tr>
                  <th>Truck Count</th>
                  <td>{{truckCount}}</td>
                </tr>
                <tr>
                  <th>Unresolved Defects</th>
                  <td>{{defectCount}}</td>
                </tr>
                <tr>
                  <th>Active Trucks</th>
                  <td>{{myActiveTrucks.length}}</td>
                </tr>
                <tr>
                  <th>Active Tasks</th>
                  <td><!-- {{myActiveTasks.length}} --></td>
                </tr>
                <tr>
                  <th>Account Max Trucks</th>
                  <td>{{fleetStatus.maxTrucks}}</td>
                </tr>
                <tr v-if="passengerTrucks.length > 0">
                  <th>Passenger Trucks</th>
                  <td>{{passengerTrucks.length}}</td>
                </tr>
              </tbody>
            </table>


            <div class="buttons">
              <!-- <router-link to="fleet-business-profile" class="button is-light">Edit Fleet Profile</router-link> -->
              <router-link to="fleet-dashboard" class="button is-light">Edit Fleet Profile</router-link>
            </div>

            <div class="clear"></div>
          </div>

          <!-- <div class="dash-widget half">
            <h3><font-awesome-icon icon="exclamation-triangle" /> &nbsp; Fleet Risk Profile</h3>
            <div class="circle-wrapper">
              <div class="progress-circle red" :class="[turnA > 50 ? 'over50 p'+turnA : 'p'+turnA]">
                <span>{{turnA}}%</span>
                <div class="left-half-clipper">
                  <div class="first50-bar"></div>
                  <div class="value-bar"></div>
                </div>
              </div>
              <div class="sub-title red">HIGH RISK<br>TRUCKS</div>
            </div>
            <div class="circle-wrapper">
              <div class="progress-circle orange" :class="[turnB > 50 ? 'over50 p'+turnB : 'p'+turnB]">
                <span>{{turnB}}%</span>
                <div class="left-half-clipper">
                  <div class="first50-bar"></div>
                  <div class="value-bar"></div>
                </div>
              </div>
              <div class="sub-title orange">MEDIUM RISK<br>TRUCKS</div>
            </div>
            <div class="circle-wrapper">
              <div class="progress-circle green" :class="[turnC > 50 ? 'over50 p'+turnC : 'p'+turnC]">
                <span>{{turnC}}%</span>
                <div class="left-half-clipper">
                  <div class="first50-bar"></div>
                  <div class="value-bar"></div>
                </div>
              </div>
              <div class="sub-title green">LOW RISK<br>TRUCKS</div>
            </div>
            <div class="clear"></div>
            <div class="spacer s60"></div>
            <div class="buttons centered">
              <router-link to="fleet-all-stats" class="button is-skip">All Truck Audits</router-link>
            </div>
          </div> -->

        </div>

        <div class="clear"></div>


        <div class="dash-widget-wrapper">

          <!-- <div class="dash-widget full">

            <h3><font-awesome-icon icon="search" /> &nbsp; Find a Truck</h3>

            <div class="spacer s10"></div>

            <div v-if="!selected" class="truck-input">
              <input class="input" v-model="findName" id="filtername" type="text" placeholder="Start typing truck name..." />
            </div>


            <div class="box" v-if="findName.length > 1 && !selected">

              <div v-if="filteredNames.length">
                <ul class="truck-list">
                  <li v-for="truck in filteredNames">
                    <span class="truck-name">{{truck.truckName}}</span>
                    <span @click="loadTruckData(truck)" class="button is-small is-success">Select</span>
                    <div class="clear"></div>
                  </li>
                </ul>
              </div>

              <div v-else>
                <span>No trucks matched your search.</span>
              </div>

            </div>

            <table v-if="selected" class="table is-bordered is-fullwidth">

              <tr>
                <th>Truck (and owner)</th>
                <th>Last Audit</th>
                <th>Options</th>
                <th>Compliance Risk</th>
              </tr>
              <tr>
                <td>{{selectedTruck.truckName}} (<span class="uppercase">{{selectedTruck.truckUVI}}</span>)<br><span class="owner">{{selectedTruck.truckOwnerName}}</span></td>
                <td>
                  <div class="traffic-light">
                    <div class="score">{{selectedTruck.lastAuditPercent}}%</div>
                    <button class="button cancel inline is-small" @click="viewAudit(selectedTruck.truckID,selectedTruck.lastAuditID,selectedTruck.truckName,selectedTruck.lastAuditDate,selectedTruck.sinceDate)">View</button>
                  </div>
                </td>
                <td>
                  <div class="buttons">
                    <span class="button is-skip is-small" @click="amsaAudits(selectedTruck)">All Audits</span>
                    <span v-if="selectedTruck.amsaAuditWindow == 'open'" class="button is-info is-small" @click="viewSMS(selectedTruck)">View SMS</span>
                  </div>
                </td>
                <td>
                  <div class="traffic-light">
                    <span><div class="light risk" :class="selectedTruck.lastAuditCode">{{selectedTruck.lastAuditRisk}}</div></span>
                  </div>
                </td>
              </tr>

            </table>

            <div class="buttons">
              <span v-if="findName != '' || selected" class="button is-danger" @click="clearTruck()">Clear Truck</span>
            </div>

          </div> -->

        </div>

        <div class="clear"></div>

        <div class="dash-widget-wrapper">

          <div class="dash-widget full">
            <h3><font-awesome-icon icon="list" /> &nbsp; Live Activity Feed</h3>

            <div v-if="allRevisions.length">
                <ul class="revisions">
                  <li v-for="(item, index) in sortedRevisions" :key="index" :class="{current: getHoursPassed(item.timestamp) < 6}">{{item.revision}} on truck <span class="caps">{{item.truckName}}</span> by {{item.userName}} - {{formatDate(item.timestamp,'D MMMM (h:mm a)')}}</li>
                </ul>
                <router-link class="button is-light" to="fleet-sms-revisions">View All Activity</router-link>
            </div>

            <div v-else>
              <p>No activity at the moment.</p>
            </div>


          </div>

        </div>

        <div class="clear"></div>

        <div class="dash-widget-wrapper">

          <div class="dash-widget half">
            <h3><font-awesome-icon icon="truck" /> &nbsp; Current Trucks in Fleet</h3>
            <div class="line-bar-wrapper one">
              <div class="line-bar">
                <div class="line-value" :style="{width: barOneWidth+'%'}"></div>
              </div>
              <div class="line-count">{{barOneVal}}</div>
            </div>
            <div class="buttons">
              <router-link to="fleet-trucks" class="button is-medium is-info">View All Trucks</router-link>
            </div>

          </div>

          <div class="dash-widget half">

            <h3><font-awesome-icon icon="users" /> &nbsp; Current Driver Count</h3>
            <div class="line-bar-wrapper two">
              <div class="line-bar">
                <div class="line-value" :style="{width: barOneWidth+'%'}"></div>
              </div>
              <div class="line-count">{{barTwoVal}}</div>
            </div>
            <div class="buttons">
              <router-link to="fleet-staff" class="button is-medium is-warning">View All Drivers</router-link>
            </div>

          </div>

        </div>

        <div class="clear"></div>




        <div class="dash-widget-wrapper">

          <!-- <div class="dash-widget third">
            <h3><font-awesome-icon icon="list-ul" /> &nbsp; Fleet Checklists</h3>
            <p>Manage your fleet level checklist items here.</p>
            <div class="buttons">
              <router-link to="fleet-sms-setup" class="button is-medium is-light">Fleet SMS</router-link>
            </div>
          </div> -->

          <div class="dash-widget third">
            <h3><font-awesome-icon icon="list-ul" /> &nbsp; All Reports</h3>
            <p>Create a custom report on your fleet activity.</p>
            <div class="buttons">
              <!-- <router-link to="fleet-sms-setup" class="button is-medium is-light">Fleet SMS</router-link> -->
              <router-link to="fleet-all-reports" class="button is-medium is-primary">Create a Report</router-link>
            </div>
          </div>

          <div class="dash-widget third">
            <h3><font-awesome-icon icon="wrench" /> &nbsp; Service Schedules</h3>
            <p>All scheduled services and maintenance on all trucks.</p>
            <div class="buttons">
              <!-- <router-link to="fleet-all-schedules" class="button is-medium is-light">View all schedules</router-link> -->
              <router-link to="" class="button is-medium is-light">Coming Soon</router-link>
            </div>
          </div>

          <div class="dash-widget third">
            <h3><font-awesome-icon icon="check-circle" /> &nbsp; Active Tasks</h3>
            <p>View all current active tasks on all trucks in this fleet.</p>
            <div class="buttons">
              <router-link to="" class="button is-medium is-light">Coming Soon</router-link>
            </div>
          </div> 

         <!--  <div class="dash-widget third">
            <h3><font-awesome-icon icon="info-circle" /> &nbsp; Truck Mileage</h3>
            <p>View current mileage and fuel usage for all trucks.</p>
            <div class="buttons">
              <router-link to="" class="button is-medium is-light">Coming Soon</router-link>
            </div>
          </div>  -->

         <!--  <div class="dash-widget third">
            <h3><font-awesome-icon icon="truck" /> &nbsp; Active Trucks</h3>
            <p>View all currently active trucks on {{currentFleet.name}}.</p>
            <div class="buttons">
            <span @click="toActiveTrucks()" class="button is-medium is-light">View Active Trucks
              <span v-if="myActiveTrucks.length > 0" class="counter">{{myActiveTrucks.length}}</span>
            </span>
            </div>
          </div> -->

          <!-- <div class="dash-widget third">
            <h3><font-awesome-icon icon="stopwatch" /> &nbsp; Driver Activity</h3>
            <p>View all user activity for fleet {{currentFleet.name}}.</p>
            <div class="buttons">
              <router-link to="fleet-sms-revisions" class="button is-medium is-light">View All Activity</router-link>
            </div>
          </div> -->

        </div>

        <div class="clear"></div>


        <div class="dash-widget-wrapper">

          <!-- <div class="dash-widget third">
            <h3><font-awesome-icon icon="wrench" /> &nbsp; Service Schedules</h3>
            <p>All scheduled services and maintenance on all trucks.</p>
            <div class="buttons">
              <router-link to="fleet-all-schedules" class="button is-medium is-light">View all schedules</router-link>
            </div>
          </div> -->

          <!-- <div class="dash-widget third">
            <h3><font-awesome-icon icon="oil-can" /> &nbsp; Current Defects</h3>
            <p>View all current defects on all trucks in the fleet.</p>
            <div class="buttons">
              <router-link to="fleet-all-defects" class="button is-medium is-light">All Truck Defects
                 <span v-if="defectCount > 0" class="counter">{{defectCount}}</span>
              </router-link>
            </div>
          </div> -->

          

         <!--  <div class="dash-widget third">
            <h3><font-awesome-icon icon="clipboard-list" /> &nbsp; Truck Logs</h3>
            <p>View the latest logs for fleet {{currentFleet.name}}.</p>
            <div class="buttons">
              <router-link to="" class="button is-medium is-light">Coming Soon</router-link>
            </div>
          </div>   -->

        </div>

        <div class="clear"></div>


        <div class="dash-widget-wrapper">

          

          

          <!-- <div class="dash-widget third">
            <h3><font-awesome-icon icon="clipboard-list" /> &nbsp; Expired Equipment</h3>
            <p>View all trucks with expired equipment.</p>
            <div class="buttons">
              <router-link to="fleet-all-equipment" class="button is-medium is-light">View Trucks
                <span v-if="expiredEquipmentCount > 0" class="counter">{{expiredEquipmentCount}}</span>
              </router-link>
            </div>
          </div>  

          <div class="dash-widget third">
            <h3><font-awesome-icon icon="shopping-cart" /> &nbsp; Shopping Lists</h3>
            <p>View shopping list items on {{currentFleet.name}}.</p>
            <div class="buttons">
              <router-link to="fleet-all-shopping-lists" class="button is-medium is-light">View All Shopping</router-link>
            </div>
          </div> -->

         <!--  <div class="dash-widget third">
            <h3><font-awesome-icon icon="truck" /> &nbsp; Driver Tracker</h3>
            <p>Find out which truck a member of your crew is on.</p>
            <div class="buttons">
              <router-link to="fleet-crew-tracker" class="button is-medium is-light">Find Crew</router-link>
            </div>
          </div> -->

          <!-- <div class="dash-widget third">
            <h3><font-awesome-icon icon="wrench" /> &nbsp; Service Logs</h3>
            <p>Engine service schedules on {{currentFleet.name}}.</p>
            <div class="buttons">
              <router-link to="service" class="button is-medium">Service Manager</router-link>
            </div>
          </div> -->

        </div>

        <!-- <div class="dash-widget-wrapper">

          <div v-if="passengerTrucks.length > 0" class="dash-widget third">
            <h3><font-awesome-icon icon="users" /> &nbsp; Passenger Manifests</h3>
            <p>View all current and past passenger manifests.</p>
            <div class="buttons">
              <span @click="choosePassengerTruck = true" class="button is-medium is-light">View All Manifests</span>
            </div>
          </div>  

        </div> -->



        <div class="clear"></div>

        <div class="dash-widget-wrapper">

          <div class="dash-widget full">
            <h3><font-awesome-icon icon="phone" /> &nbsp; Contact SafeTruckie:</h3>
            <p>The SafeTruckie team is available for support, feedback and guidance on <a href="tel:0409-709-596">0409 709 596</a> or <a href="mailto:help@safetruckie.com.au?subject=Enquiry from SafeTruckie">help@safetruckie.com.au</a>.</p>
          </div>

        </div>

        <div class="clear"></div>

        <div class="spacer s100">





      </div>

      <!-- <div class="right-sidebar">
        <h4>Quick Links</h4>
        <div class="buttons fullwidth">
          <router-link to="" class="button is-primary">Add a Truck</router-link>
          <router-link to="" class="button is-info">Add crew</router-link>
          <router-link to="" class="button is-success">Find a Truck</router-link>
          <router-link to="" class="button is-warning">New Induction</router-link>
          <router-link to="" class="button is-danger">Add a Hazard</router-link>
          <router-link to="" class="button is-link">Crew Training</router-link>
        </div>
        
      </div> -->

      
    </div>



    <!-- <div class="container has-text-centered">
      <div>
        <p class="dash-heading is-size-4 has-text-centered">Fleet Dashboard<span v-if="!isStateLoaded">...</span></p>
      </div>
      <div class="dashtile-wrapper">

        <div class="clear"></div>

        <h4>items for now:</h4>


        <div class="container full-buttons">

          <div class="buttons are-medium">
            <router-link to="sms-setup" class="button is-light is-fullwidth">
              <font-awesome-icon icon="cog" /> Fleet SMS Setup</router-link>
          </div>

          <div class="buttons are-medium">
            <router-link to="fleet-profile" class="button is-light is-fullwidth">
              <font-awesome-icon icon="cog" /> Business Profile</router-link>
          </div>

          <div class="buttons are-medium">
            <router-link to="" class="button is-light is-fullwidth">
              <font-awesome-icon icon="cog" /> Fleet Trucks</router-link>
          </div>

          <div class="buttons are-medium">
            <router-link to="" class="button is-light is-fullwidth">
              <font-awesome-icon icon="cog" /> All Staff</router-link>
          </div>

          <div class="buttons are-medium">
            <router-link to="planned-safety" class="button is-light is-fullwidth">
              <font-awesome-icon icon="cog" /> Planned Safety</router-link>
          </div>

          <div class="buttons are-medium">
            <router-link to="shopping-list" class="button is-light is-fullwidth">
              <font-awesome-icon icon="cog" /> Shopping List</router-link>
          </div>

        </div>

        <hr>

        <h4>items for the big time:</h4>

        <div class="container full-buttons">

          <div class="buttons are-medium">
            <router-link to="sms-setup" class="button is-light is-fullwidth">
              <font-awesome-icon icon="cog" /> Fleet Setup</router-link>
          </div>

          <div class="buttons are-medium">
            <router-link to="fleet-profile" class="button is-light is-fullwidth">
              <font-awesome-icon icon="cog" /> Fleet Profile</router-link>
          </div>

          <div class="buttons are-medium">
            <router-link to="" class="button is-light is-fullwidth">
              <font-awesome-icon icon="cog" /> Fleet Trucks</router-link>
          </div>

          <div class="buttons are-medium">
            <router-link to="" class="button is-light is-fullwidth">
              <font-awesome-icon icon="cog" /> Staff</router-link>
          </div>

          <div class="buttons are-medium">
            <router-link to="" class="button is-light is-fullwidth">
              <font-awesome-icon icon="cog" /> My Trucks</router-link>
          </div>

        </div>



      </div>
      <div class="spacer s50"></div>
      <div class="clear"></div>
    </div> -->




    <div class="spacer s90"></div>


    <!-- <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':choosePassengerTruck}">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Select a Truck</p>
            <button class="delete" aria-label="close" @click="close"></button>
          </header>
          <section class="modal-card-body"> 
            <div class="content">
              
              <div class="select is-primary">
                <select v-model="selectedPassengerTruck">
                  <option disabled value="">Select a truck</option>
                  <option v-for="truck in passengerTrucks" :value="truck" :key="truck.truckID">
                    {{ truck.truckName }}
                  </option>
                </select>
              </div>

            </div>  
          </section>  
          <footer class="modal-card-foot">
            <button class="button is-primary" :disabled="selectedPassengerTruck == ''" @click="viewManifests()">View Manifests</button>
            <button class="button cancel" @click="close">Close</button>
          </footer>
      </div>
    </div> -->




    <!-- <sticky-footer :allHelp="allHelp" :adminHelp="adminHelp" :title="helpTitle == '' ? 'Fleet Dashboard' : helpTitle" :quickLinks="quickLinks"></sticky-footer> -->


  </div>

  <div class="container" v-else>
    <!-- {{userIsAdmin}} / {{currentFleet.id}} / {{adminUsers}} / {{firebaseid}} -->
    <div class="spacer s40"></div>
    <p v-if="userIsAdmin">No fleet found.</p>
    <p v-else>You do not have the required permissions to access this page.</p>
    <hr>
    <router-link class="button is-info" to="my-dashboard">Back to My Dashboard</router-link>
    <div class="spacer s90"></div>
  </div>

</template>

<script>
import { db } from '../main.js';
import firebase from 'firebase/app';
import 'firebase/auth';
import NProgress from 'nprogress';

// import MobileNav from '../components/MobileNav.vue';
import * as moment from 'moment';

// const StickyFooter = () => import('../reusables/StickyFooter.vue');
const FleetLeftSidebar = () => import('../reusables/FleetLeftSidebar.vue');
// import { ProgressCircle } from 'vue-progress-circle'
//import func from '../../vue-temp/vue-editor-bridge';

// const UpdateAudit = () => import('./UpdateAudit.vue')


export default {
  name: 'FleetDashboard',
  components: {
    // StickyFooter,
    // MobileNav,
    FleetLeftSidebar,
    // UpdateAudit,
    // ProgressCircle,
  },

  props: {

  },

  computed: {
    // isUserAdmin() {
    //   return this.$store.getters.getIsUserAdmin;
    // },
    userData() {
      return this.$store.getters.getSubscriptionInfo;
    },
    // isStateLoaded() {
    //   return this.$store.getters.getDashboardLoaded;
    // },
    // totalTruckCount() {
    //   return this.allTrucks.length;
    // },
    truckPercentage() {
      // return Math.round(this.truckCount / 300 * 100);
      return this.truckCount;
    },
    // filteredNames() {
    //   let filter = new RegExp(this.findName, 'i')
    //   return this.allTrucks.filter(item => item.truckName.match(filter));
    // },
    driverPercentage() {
      return this.driverCount;
    },
    totalCount() {
      return this.allTrucks.length;
    },
    lowRiskCount() {
      let num = this.allTrucks.filter((truck) => truck.lastAuditCode == 'green').length;
      return Math.round(num / this.allTrucks.length * 100);
      // return this.allTrucks.filter((truck) => truck.lastAuditCode == 'green').length;
      // return 23;
    },
    mediumRiskCount() {
      let num = this.allTrucks.filter((truck) => truck.lastAuditCode == 'orange').length;
      return Math.round(num / this.allTrucks.length * 100);
      // return this.allTrucks.filter((truck) => truck.lastAuditCode == 'orange').length;
      // return 57;
    },
    highRiskCount() {
      let num = this.allTrucks.filter((truck) => truck.lastAuditCode == 'red').length;
      return Math.round(num / this.allTrucks.length * 100);
      // return this.allTrucks.filter((truck) => truck.lastAuditCode == 'red').length;
      // return 89;
    },
    currentFleet() {
      return this.$store.getters.getCurrentFleet;
    },
    fleetStatus() {
      return this.$store.getters.getFleetStatus;
    },
    userIsAdmin() {
      return this.adminUsers.includes(this.firebaseid);
    },
    myActiveTrucks() {
        return this.$store.getters.getMyActiveTrucks;
    },
    sortedRevisions() {
      return this.allRevisions.length > 0 ? this.allRevisions.slice().sort((a, b) => b.timestamp - a.timestamp).slice(0,8) : [];
    },
    passengerTrucks() {
      return this.allTrucks.filter((truck) => truck.takesPassengers);
    }

  },

  async mounted() {
    NProgress.start();
    // var self = this;

    // LOAD ALL FROM DASHBOARD AND GET NOTIFIED WHEN LOADED
    this.$store.dispatch('LOADDASHBOARD');
    //this.$store.dispatch('SETMYACTIVEVESSELS');

    Promise.all([
      // this.$store.dispatch('SETUSERPROFILE'),
      // this.$store.dispatch('updateUserState'),
      //this.$store.dispatch('SETMYACTIVEVESSELS'),
      // this.$store.dispatch('SETMYVESSELS'),
      // this.$store.dispatch('SETMYCREWEDVESSELDATA'),
      // this.$store.dispatch('setUser'),
      // this.$store.dispatch('LOADVESSELSTARTUPITEMS'),
      // this.$store.dispatch('LOADALLVESSELCREW'),
      // this.$store.dispatch('LOADDIVEPLAN'),
      this.$store.dispatch('UPDATEFLEETSTATUS'),
      // this.$store.dispatch('SETISAUDITOR'),
    ]).finally(() => {
      // using "finally" so even if there are errors, it stops "loading"
      this.loadingStore = false;
      NProgress.done();
    });

    firebase.auth().onAuthStateChanged(this.onUserLogin);

  },
  data: function() {
    return {
      firebaseid: '',
      firebasedisplayname: '',
      firebaseemail: '',

      allHelp: [
        { item: 'This is your Dashboard and the hub of your SMS.' },
        { item: 'Add Alerts and Reminders and manage your Trucks all from here.' }
      ],
      adminHelp: [
        { item: 'Only truck owners will see the My Drivers button.' }
      ],
      helpTitle: '',

      quickLinks: [
        { addTruck: true },
        { logStartup: true },
        { logIncident: false }
      ],

      loadingStore: true,
      alertCount: 0,

      //
      allTrucks: [],
      showAudit: false,
      selectedTruckID: '',
      selectedTruckName: '',
      selectedAuditID: '',
      selectedSinceDate: '',
      selectedAuditResult: {
        hasDesignatedStatement: false,
        hasMasterStatement: false,
        countHazards: 0,
        countContacts: 0,
        countPolicies: 0,
        countSOPS: 0,
        countEOPS: 0,
        countStartupItems: 0,
        countStartupLogs: 0,
        countMaintenanceItems: 0,
        countMaintenanceLogs: 0,
        auditScore: 0,
        auditCode: 'red',
        auditPercent: 0,
      },
      selectedAuditLoaded: false,
      showOptions: false,
      selectedTruck: {},
      truckInfoModal: false,

      // fill : { gradient: ["red", "green", "blue"], gradientAngle: Math.PI / 3 },
      fill: { color: "#ff1e41" },
      options: {
        color: '#ff0000',
        strokeWidth: 4,
        text: {
          value: 'Hello',

        }
      },

      turnA: 0,
      turnB: 0,
      turnC: 0,

      barOneVal: 0,
      barOneWidth: 0,
      barTwoVal: 0,
      barTwoWidth: 0,

      truckCount: 0,
      driverCount: 0,

      findName: '',
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      selected: false,

      adminUsers: [],

      defectCount: 0,
      expiredEquipmentCount: 0,
      //maxTrucks: 0,

      allRevisions: [],
      // choosePassengerTruck: false,
      // selectedPassengerTruck: '',


    }
  },
  methods: {

    onUserLogin: function(user) {
      if (user) {
        // console.log('User:',user);
        this.firebaseemail = user.email;
        this.firebaseid = user.uid;
        this.firebasedisplayname = this.userData.username;
        this.$store.dispatch('updateUserState');
        this.$store.dispatch('SETSUBSCRIPTIONINFO');
        // this.loadStats();
        this.loadFleetTrucks();
        this.loadAllDrivers();
        this.loadFleetData();
      }
    },

    close() {
      // this.choosePassengerTruck = false;
      // this.selectedPassengerTruck = '';
    },

    formatDate : function (sentDate,format) {
      let fixDate = (sentDate ? sentDate.toDate() : new Date());
      return moment(fixDate).format(format);
    },

    getHoursPassed(timestamp) {
      // console.log(timestamp.toDate());
      let end = moment();
      let start = moment(timestamp.toDate());
      let duration = moment.duration(end.diff(start));
      // console.log(duration);
      return Math.round(duration.asHours());
    },

    // viewManifests() {
    //   let self = this;
    //   let truckName = self.selectedPassengerTruck.truckName;
    //   let truckID = self.selectedPassengerTruck.truckID;
    //   let truckUVI = self.selectedPassengerTruck.truckUVI;
    //   this.$store.dispatch('UPDATESELECTEDVESSEL',{truckName,truckID,truckUVI});
    //   this.$router.push({
    //     name: 'FleetTruckManifests',
    //     params: {

    //     }
    //   });
    // },

    loadFleetData() {
      let self = this;
      // console.log('loading admins',this.currentFleet.id);
      db.collection('fleets').doc(this.currentFleet.id).onSnapshot(function(doc) {
        // console.log(self.firebaseid,doc.data());
        self.adminUsers = doc.data().adminUsers;
        //self.maxTrucks = doc.data().maxTrucks ? doc.data().maxTrucks : 0;
      });
    },

    async loadFleetTrucks() {
      let self = this;

        // db.collection('trucks').where("truckOwnerState", "==", self.userData.auditorState).onSnapshot(function(querySnapshot) {
        //   // self.truckCount = querySnapshot.size;
        //   console.log('Size 1',self.truckCount);
        // });

        await db.collection('fleets').doc(self.currentFleet.id).collection('fleetTrucks').get().then(function(querySnapshot) {
          self.truckCount = querySnapshot.size;
          self.barOneVal = querySnapshot.size;
          self.allTrucks = [];
          querySnapshot.forEach((truck) => {

            db.collection('trucks').doc(truck.id).get().then(function(doc) {
              self.allTrucks.push({
                truckID: truck.id,
                truckName: truck.data().truckName,
                truckUVI: truck.data().truckUVI,                
                // lastAuditID: (doc.data().lastAuditID ? doc.data().lastAuditID : 'NA'),
                // lastAuditDate: (doc.data().lastAuditDate ? self.formatDate(doc.data().lastAuditDate,'dddd D MMMM (h:mm a)') : 'Never'),
                // sinceDate: (doc.data().lastAuditDate ? moment(doc.data().lastAuditDate.toDate()).fromNow() : 'no audit'),
                // lastAuditPercent: (doc.data().lastAuditPercent ? doc.data().lastAuditPercent : 0),
                lastAuditCode: doc.data().lastAuditCode ? doc.data().lastAuditCode : 'red',
                // lastAuditRisk: doc.data().lastAuditCode == 'green' ? 'LOW' : doc.data().lastAuditCode == 'orange' ? 'MEDIUM' : 'HIGH',
                // hasBeenCounted: false,
                // truckOwnerName: (doc.data().truckOwnerName ? doc.data().truckOwnerName : ''),
                // truckOwnerID: doc.data().truckOwnerID,
                // truckOwnerEmail: (doc.data().truckOwnerEmail ? doc.data().truckOwnerEmail : ''),
                // truckOwnerPhone: (doc.data().truckOwnerPhone ? doc.data().truckOwnerPhone : ''),

                // amsaAuditWindow: doc.data().amsaAuditWindow ? doc.data().amsaAuditWindow : '', // open, pending or closed
                // amsaAuditStartDate: doc.data().amsaAuditStartDate ? doc.data().amsaAuditStartDate : '',
                // currentAmsaAuditID: doc.data().currentAmsaAuditID ? doc.data().currentAmsaAuditID : '',
                // doesDiving: doc.data().doesDiving ? doc.data().doesDiving : false,
                // takesPassengers: doc.data().takesPassengers ? doc.data().takesPassengers : false,

              });
            })
            .then(function() {
              if (self.truckCount == self.allTrucks.length) {
                self.loadStats();
                self.loadDefects();
                self.loadRevisions();
                self.loadExpiredEquipment();
              }
            });

          });
        });
        


    },

    loadAllDrivers: function() {
      let self = this;
      db.collection('fleets').doc(self.currentFleet.id).collection('fleetStaff').get().then(function(querySnapshot) {
        self.driverCount = querySnapshot.size;
        self.barTwoVal = querySnapshot.size;
      });
    },


    setCount (turnName,targetVal) {
      let self = this;
      let i = 0;
      if (i == 0 && targetVal > 0) {
        i = 1;
        var step = 1;
        var id = setInterval(frame, 10);
        function frame() {
          if (step >= targetVal) {
            clearInterval(id);
            i = 0;
          } else {
            step++;
            self[turnName] = step;
          }
        }
      }

    },

    setBarCount (turnName,targetVal,targetWidth) {
      // console.log('CHECK',turnName,targetVal,targetWidth);
      let self = this;
      let i = 0;
      if (i == 0 && targetVal > 0) {
        i = 1;
        var step = 1;
        var id = setInterval(frame, 10);
        function frame() {
          if (targetVal == 1) {
            self[targetWidth] = step / targetVal * 100;
            self[turnName] = step;
            clearInterval(id);
          } else {
            if (step >= targetVal) {
              clearInterval(id);
              i = 0;
            } else {
              // console.log('Step:',step);
              step++;
              self[targetWidth] = step / targetVal * 100;
              self[turnName] = step;
            }
          }
          
        }
      }

    },

    toActiveTrucks() {
      // let self = this;
      this.$store.dispatch('SETMYACTIVEVESSELS');  
      this.$router.push({
        name: 'FleetActiveTrucks',
        params: {

        }
      });
    },

    async loadExpiredEquipment() {
      let self = this;
      self.expiredEquipmentCount = 0;
      await self.allTrucks.forEach((truck) => {
        db.collection('trucks').doc(truck.truckID).collection('truckEquipment').onSnapshot(function(querySnapshot) {
          querySnapshot.forEach((doc) => {
            let tempDate = doc.data().expiryDate ? moment(doc.data().expiryDate.toDate()) : '';
            if (tempDate != '') {
              self.expiredEquipmentCount += moment().isAfter(tempDate) ? 1 : 0;
            }
          });
        });
      });
    },

    loadStats() {
      let self = this;
        setTimeout(function() {
        self.setCount('turnA',self.highRiskCount);
      }, 500);
      setTimeout(function() {
        self.setCount('turnB',self.mediumRiskCount);
      }, 1000);
      setTimeout(function() {
        self.setCount('turnC',self.lowRiskCount);
      }, 1500);
      setTimeout(function() {
        self.setBarCount('barOneVal',self.truckPercentage,'barOneWidth');
      }, 2000);
      setTimeout(function() {
        self.setBarCount('barTwoVal',self.driverPercentage,'barTwoWidth');
      }, 3500);
    },

    // toWhatsNew: function() {
    //   let self = this;
    //   let addRevision = firebase.functions().httpsCallable('addRevision');
    //   addRevision({
    //     userID: self.firebaseid,
    //     userName: self.firebasedisplayname,
    //     description: self.firebasedisplayname + ' has clicked on the What\'s new button',
    //   });
    //   this.$router.replace('whats-new');
    // },

    async loadDefects() {
      // console.log('here');
      let self = this;
      // self.defectCount = 0;
      await self.allTrucks.forEach((truck) => {
        // console.log(self.defectCount,truck.truckName);
        db.collection('trucks').doc(truck.truckID).onSnapshot(function(doc) {
          // console.log(truck.truckName,doc.data().countDefects);
          self.defectCount += doc.data().countDefects ? doc.data().countDefects : 0;
        });
      });
    },

    async loadRevisions() {
      let self = this;
      self.allRevisions = [];
      await self.allTrucks.forEach((truck) => {
        // console.log('truck:',truck);
        db.collection('trucks').doc(truck.truckID).collection('truckRevisions').orderBy('timestamp','desc').limit(15).onSnapshot(function(querySnapshot) {
          querySnapshot.forEach((doc) => {
            // console.log(doc.data());
            self.allRevisions.push(doc.data());
          });
        });
      });
    }


  }
}
</script>
<style lang="scss" scoped>
  
  // .header-spacer {
  //   height: 60px;
  // }
  

  html {
    height: 100%;
  }
  body {
    height: 100%;
    // background: #f9f9f9 !important;
  }
  #app {
    // background: #f9f9f9 !important;
    height: 100%;
  }

  .container {
    width: 90%;
  }

  ul.revisions {
    list-style: disc inside;
    margin-left: 8px;
    li {
      text-indent: -23px;
      margin-left: 20px;
      margin-bottom: 10px;
      &.current {
        color: #3273dc;
      }
    }
  }
  .caps {
    text-transform: uppercase;
  }

  .select select {
    text-transform: uppercase;
  }

  .dash-heading {
    color: #fd7b47;
    font-size: 1.8rem;
  }


    .dash-widget {
      width: auto;
      background:#fff;
      padding: 1.6rem;
      // box-shadow: 2px 2px 0px #eee;
      border: 1px solid #eee;
      border-radius: 5px;
      margin-bottom: 2.5rem;
      text-align: left;
      &.half {
        width: 48%;
        margin-right: 4%;
        float: left;
        &:nth-of-type(2n) {
          margin-right: 0;
        }
      }
      h3 {
        text-transform: uppercase;
        font-weight: 500;
        padding-bottom: 10px;
        border-bottom: 1px solid #ddd;
        display: block;
        margin-bottom: 1rem;
        margin-top: 0;
        color: cadetblue;
        font-size: 1.2rem;
        svg {
          vertical-align: sub;
          font-size: 1.5rem;
        }
      }
      &.full {
        width: 100%;
      }
      &.third {
        width: 31%;
        margin-right: 3.5%;
        float: left;
        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }
      .circle-wrapper {
        width: 33%;
        float: left;
        text-align: center;
        .circle-progress-inner {
          span {
            font-size: 1rem;
          }
        }
        p {
          font-size: 1rem !important;
        }
      }  
      .circle-progress-container {
        .circle-progress-inner {
          span {
            font-size: 1rem !important;
          }
        }
      }
      p {
        margin-bottom: 1rem;
      }
      .truck-input {
        input[type="text"] {
          font-size: 1.1rem;
          padding: 30px 15px;
          margin-bottom: 1.5rem;
        }
      }

    }
    
    span.counter {
      position: absolute;
      top: -8px;
      right: -10px;
      border-radius: 50%;
      background: red;
      width: 22px;
      height: 22px;
      color: #fff;
      font-size: 13px;
      line-height: 20px;
      font-weight: bold;
      text-align: center;
    }

    .traffic-light {
      position: relative;
      span {
          // float: left;
          margin-right: 10px;
      }
      .score {
        border-radius: 3px;
        display: inline-block;
        border: 1px solid #ccc;
        font-size: 0.8rem;
        padding: 3px 10px;
        margin-right: 5px;
        margin-bottom: 5px;
        width: 56px;
        text-align: center;
      }
      .light {
        border-radius: 3px;
        display: inline-block;
        color: #fff;
        font-size: 0.8rem;
        padding: 3px 10px;
        margin-right: 5px;
        margin-bottom: 5px;
        width: 56px;
        text-align: center;
        &.red {
          background: red;
          &.active {
            background: red;
          }
        }
        &.orange {
          background: orange;
          &.active {
            background: orange;
          }
        }
        &.green {
          background: #03d203;
          &.active {
            background: #03d203;
          }
        }
        &.zero {
          background: #f4f4f4;
          &.active {
            background: #f4f4f4;
          }
        }
      }
      .risk {
        width: 75px;
      }
    }
    .buttons.no-margin {
      margin-bottom: 0;
      .button {
        display: block;
      }
    }
    .button {
      margin-top: 0;
      &.is-skip {
        background-color: #ff7878;
        color: #fff;
        border-color: #ff7878;
      }
      &.inline {
        display: inline-block;
        width: 51px;
      }
      &.is-small {
        margin-bottom: 5px;
        margin-right: 5px;
      }
    }

    span {
      &.owner {
        font-size: 0.9rem;
      }
      &.audit-status {
        text-transform: uppercase;
        border-radius: 3px;
        display: inline-block;
        color: #fff;
        font-size: 0.8rem;
        padding: 4px 10px;
          // width: 51px;
          text-align: center;
          font-weight: bold;
          &.pending {
            color: orange;
          }
          &.closed {
            color: red;
          }
          &.open {
            color: green;
          }
      }
      &.uppercase {
        text-transform: uppercase;
      }
    }

    ul.truck-list {
      max-width: 320px;
      li {
        margin-bottom: 1rem;
        &:last-of-type {
          margin-bottom: 0;
        }
        .truck-name {
          float: left;
        }
        .button {
          float: right;
        }
      }
    }

    .line-bar-wrapper {
      width: 100%;
      height: 40px;
      margin: 2rem 0;
      .line-bar {
        width: calc(100% - 80px);
        height: 40px;
        background: #f4f4f4;
        float: left;
        .line-value {
          width: 0;
          height: 100%;
        }
      }
      .line-count {
        width: 80px;
        color: steelblue;
        font-size: 2.5rem;
        padding-left: 10px;
        float: right;
        line-height: 2.5rem;
        font-weight: bold;
        text-align: center;
      }
      &.one {
        .line-bar {
          .line-value {
            background: #6bc4ff;
          }
        }
        .line-count {
          color: #6bc4ff;
        }
      }
      &.two {
        .line-bar {
          .line-value {
            background: #FCEB9A;
          }
        }
        .line-count {
          color: #FCEB9A;
        }
      }

    }



      // profress circle css
      .progress-circle {
        font-size: 20px;
        margin: 20px auto;
        position: relative; /* so that children can be absolutely positioned */
        padding: 0;
        width: 5em;
        height: 5em;
        background-color: #ddd; 
        border-radius: 50%;
        line-height: 5em;
        /* Text inside the control */
        span {
          position: absolute;
          line-height: 5em;
          width: 5em;
          text-align: center;
          display: block;
          color: #1abc9c;
          z-index: 2;
        }
        &:after {
          border: none;
          position: absolute;
          top: 0.35em;
          left: 0.35em;
          text-align: center;
          display: block;
          border-radius: 50%;
          width: 4.3em;
          height: 4.3em;
          background-color: white;
          content: " ";
        }
        &.over50 {
          clip: rect(auto,auto,auto,auto);
          .first50-bar {
            /*Progress bar for the first 50%, filling the whole right half*/
            position: absolute; /*needed for clipping*/
            clip: rect(0, 5em, 5em, 2.5em);
            background-color: #1abc9c;
            border-radius: 50%;
            width: 5em;
            height: 5em;
          }
          .left-half-clipper {
            clip: rect(auto,auto,auto,auto);
          }
        }
        &.red {
          span {
            color: red;
          }
          .first50-bar {
            background-color: red;
          }
          .value-bar {
            border-color: red !important;
          }
        }
        &.orange {        
          span {
            color: orange;
          }
          .first50-bar {
            background-color: orange;
          }
          .value-bar {
            border-color: orange !important;
          }
        }
        &.green {
          span {
            color: seagreen;
          }
          .first50-bar {
            background-color: limegreen;
          }
          .value-bar {
            border-color: limegreen !important;
          }
        }
        .left-half-clipper {
          /* a round circle */
          border-radius: 50%;
          width: 5em;
          height: 5em;
          position: absolute; /* needed for clipping */
          clip: rect(0, 5em, 5em, 2.5em); /* clips the whole left half*/ 
          .value-bar {
            /*This is an overlayed square, that is made round with the border radius,
           then it is cut to display only the left half, then rotated clockwise
           to escape the outer clipping path.*/ 
           position: absolute; /*needed for clipping*/
           clip: rect(0, 2.5em, 5em, 0);
           width: 5em;
           height: 5em;
           border-radius: 50%;
           border: 0.45em solid #1abc9c; /*The border is 0.35 but making it larger removes visual artifacts */
           /*background-color: #4D642D;*/ /* for debug */
           box-sizing: border-box;
        }
      }
    }

    .sub-title {
      font-weight: bold;
      &.red {
        color: red;
      }
      &.orange {
        color: orange;
      }
      &.green {
        color: limegreen;
      }
    }
    // .progress-circle.over50 .left-half-clipper {
    //    clip: rect(auto,auto,auto,auto);
    // }

    .progress-circle:not(.over50) .first50-bar{ display: none; }


    /* Progress bar rotation position */
    .progress-circle.p0 .value-bar { display: none; }
    .progress-circle.p1 .value-bar { transform: rotate(4deg); }
    .progress-circle.p2 .value-bar { transform: rotate(7deg); }
    .progress-circle.p3 .value-bar { transform: rotate(11deg); }
    .progress-circle.p4 .value-bar { transform: rotate(14deg); }
    .progress-circle.p5 .value-bar { transform: rotate(18deg); }
    .progress-circle.p6 .value-bar { transform: rotate(22deg); }
    .progress-circle.p7 .value-bar { transform: rotate(25deg); }
    .progress-circle.p8 .value-bar { transform: rotate(29deg); }
    .progress-circle.p9 .value-bar { transform: rotate(32deg); }
    .progress-circle.p10 .value-bar { transform: rotate(36deg); }
    .progress-circle.p11 .value-bar { transform: rotate(40deg); }
    .progress-circle.p12 .value-bar { transform: rotate(43deg); }
    .progress-circle.p13 .value-bar { transform: rotate(47deg); }
    .progress-circle.p14 .value-bar { transform: rotate(50deg); }
    .progress-circle.p15 .value-bar { transform: rotate(54deg); }
    .progress-circle.p16 .value-bar { transform: rotate(58deg); }
    .progress-circle.p17 .value-bar { transform: rotate(61deg); }
    .progress-circle.p18 .value-bar { transform: rotate(65deg); }
    .progress-circle.p19 .value-bar { transform: rotate(68deg); }
    .progress-circle.p20 .value-bar { transform: rotate(72deg); }
    .progress-circle.p21 .value-bar { transform: rotate(76deg); }
    .progress-circle.p22 .value-bar { transform: rotate(79deg); }
    .progress-circle.p23 .value-bar { transform: rotate(83deg); }
    .progress-circle.p24 .value-bar { transform: rotate(86deg); }
    .progress-circle.p25 .value-bar { transform: rotate(90deg); }
    .progress-circle.p26 .value-bar { transform: rotate(94deg); }
    .progress-circle.p27 .value-bar { transform: rotate(97deg); }
    .progress-circle.p28 .value-bar { transform: rotate(101deg); }
    .progress-circle.p29 .value-bar { transform: rotate(104deg); }
    .progress-circle.p30 .value-bar { transform: rotate(108deg); }
    .progress-circle.p31 .value-bar { transform: rotate(112deg); }
    .progress-circle.p32 .value-bar { transform: rotate(115deg); }
    .progress-circle.p33 .value-bar { transform: rotate(119deg); }
    .progress-circle.p34 .value-bar { transform: rotate(122deg); }
    .progress-circle.p35 .value-bar { transform: rotate(126deg); }
    .progress-circle.p36 .value-bar { transform: rotate(130deg); }
    .progress-circle.p37 .value-bar { transform: rotate(133deg); }
    .progress-circle.p38 .value-bar { transform: rotate(137deg); }
    .progress-circle.p39 .value-bar { transform: rotate(140deg); }
    .progress-circle.p40 .value-bar { transform: rotate(144deg); }
    .progress-circle.p41 .value-bar { transform: rotate(148deg); }
    .progress-circle.p42 .value-bar { transform: rotate(151deg); }
    .progress-circle.p43 .value-bar { transform: rotate(155deg); }
    .progress-circle.p44 .value-bar { transform: rotate(158deg); }
    .progress-circle.p45 .value-bar { transform: rotate(162deg); }
    .progress-circle.p46 .value-bar { transform: rotate(166deg); }
    .progress-circle.p47 .value-bar { transform: rotate(169deg); }
    .progress-circle.p48 .value-bar { transform: rotate(173deg); }
    .progress-circle.p49 .value-bar { transform: rotate(176deg); }
    .progress-circle.p50 .value-bar { transform: rotate(180deg); }
    .progress-circle.p51 .value-bar { transform: rotate(184deg); }
    .progress-circle.p52 .value-bar { transform: rotate(187deg); }
    .progress-circle.p53 .value-bar { transform: rotate(191deg); }
    .progress-circle.p54 .value-bar { transform: rotate(194deg); }
    .progress-circle.p55 .value-bar { transform: rotate(198deg); }
    .progress-circle.p56 .value-bar { transform: rotate(202deg); }
    .progress-circle.p57 .value-bar { transform: rotate(205deg); }
    .progress-circle.p58 .value-bar { transform: rotate(209deg); }
    .progress-circle.p59 .value-bar { transform: rotate(212deg); }
    .progress-circle.p60 .value-bar { transform: rotate(216deg); }
    .progress-circle.p61 .value-bar { transform: rotate(220deg); }
    .progress-circle.p62 .value-bar { transform: rotate(223deg); }
    .progress-circle.p63 .value-bar { transform: rotate(227deg); }
    .progress-circle.p64 .value-bar { transform: rotate(230deg); }
    .progress-circle.p65 .value-bar { transform: rotate(234deg); }
    .progress-circle.p66 .value-bar { transform: rotate(238deg); }
    .progress-circle.p67 .value-bar { transform: rotate(241deg); }
    .progress-circle.p68 .value-bar { transform: rotate(245deg); }
    .progress-circle.p69 .value-bar { transform: rotate(248deg); }
    .progress-circle.p70 .value-bar { transform: rotate(252deg); }
    .progress-circle.p71 .value-bar { transform: rotate(256deg); }
    .progress-circle.p72 .value-bar { transform: rotate(259deg); }
    .progress-circle.p73 .value-bar { transform: rotate(263deg); }
    .progress-circle.p74 .value-bar { transform: rotate(266deg); }
    .progress-circle.p75 .value-bar { transform: rotate(270deg); }
    .progress-circle.p76 .value-bar { transform: rotate(274deg); }
    .progress-circle.p77 .value-bar { transform: rotate(277deg); }
    .progress-circle.p78 .value-bar { transform: rotate(281deg); }
    .progress-circle.p79 .value-bar { transform: rotate(284deg); }
    .progress-circle.p80 .value-bar { transform: rotate(288deg); }
    .progress-circle.p81 .value-bar { transform: rotate(292deg); }
    .progress-circle.p82 .value-bar { transform: rotate(295deg); }
    .progress-circle.p83 .value-bar { transform: rotate(299deg); }
    .progress-circle.p84 .value-bar { transform: rotate(302deg); }
    .progress-circle.p85 .value-bar { transform: rotate(306deg); }
    .progress-circle.p86 .value-bar { transform: rotate(310deg); }
    .progress-circle.p87 .value-bar { transform: rotate(313deg); }
    .progress-circle.p88 .value-bar { transform: rotate(317deg); }
    .progress-circle.p89 .value-bar { transform: rotate(320deg); }
    .progress-circle.p90 .value-bar { transform: rotate(324deg); }
    .progress-circle.p91 .value-bar { transform: rotate(328deg); }
    .progress-circle.p92 .value-bar { transform: rotate(331deg); }
    .progress-circle.p93 .value-bar { transform: rotate(335deg); }
    .progress-circle.p94 .value-bar { transform: rotate(338deg); }
    .progress-circle.p95 .value-bar { transform: rotate(342deg); }
    .progress-circle.p96 .value-bar { transform: rotate(346deg); }
    .progress-circle.p97 .value-bar { transform: rotate(349deg); }
    .progress-circle.p98 .value-bar { transform: rotate(353deg); }
    .progress-circle.p99 .value-bar { transform: rotate(356deg); }
    .progress-circle.p100 .value-bar { transform: rotate(360deg); }



    @media only screen and (max-width: 949px) {

      .dash-widget {
        width: 100% !important;
        float: none !important;
        margin-right: 0 !important;
      }

    }




</style>