<template>
  <div>
    <!-- <div class="header-image">
      <div class="header-content">
        <h1>Truck Overview</h1>
        <h4>{{currentTruckName}}</h4>
      </div>
    </div> -->
    <div class="fleet-wrapper">
      
      <fleet-left-sidebar></fleet-left-sidebar>

      <div class="main-fleet-content">
        <fleet-scroller tab="Information" backLink="fleet-edit-sms" backTitle="Truck Details"></fleet-scroller>
        <div v-if="currentTruckID">
          <br>
          <div class="before-icon">
            <font-awesome-icon icon="ship" />
          </div>
          <h4 class="icon">Truck Information</h4>
          <div class="clear"></div>
          <div class="card">
            <div class="card-content">
              <div class="tab-content">
                <!-- <p>Vuex Name: {{currentTruckName}}</p>
                        <p>Vuex ID: {{currentTruckID}}</p>
                        <p>Vuex UVI: {{currentTruckUVI}}</p> -->
                <form id="profile-form" @submit.prevent="updateInformation">
                  <!-- <div class="form-group">      
                            <input class="input" type="text" v-model="details.truckname" required>
                            <span class="highlight"></span>
                            <span class="bar"></span>
                            <label>TRUCK NAME:</label>
                          </div>

                          <div class="form-group">      
                            <input class="input" type="text" v-model="details.uvi" required>
                            <span class="highlight"></span>
                            <span class="bar"></span>
                            <label>TRUCK DIST MARKS / UVI:</label>
                          </div> -->
                  <!-- <div class="notification pad-bottom">
                            <div class="left">
                              TRUCK NAME: <span>{{currentTruckName}}</span>
                            </div>
                            <div class="left">
                              TRUCK DIST MARKS / UVI: <span>{{currentTruckUVI}}</span>
                            </div>
                            <div class="clear">
                              
                            </div>
                          </div> -->
                  <!-- {{myTrucks}} / {{currentTruckID}}
                          <hr> -->
                  <!-- <p>Selected Index: {{selectedTruckIndex}}</p>
                          <ul>
                            <li v-for="(truck, index) in myTrucks">{{index}} - {{truck.name}}</li>
                          </ul> -->
                  <table class="table is-fullwidth is-bordered pad-bottom">
                    <tr>
                      <th>Truck Name</th>
                      <td class="caps">{{currentTruckName}}</td>
                    </tr>
                    <tr>
                      <th>Truck Rego</th>
                      <td class="caps">{{currentTruckUVI}}</td>
                    </tr>
                    <tr>
                      <th>Last KM</th>
                      <td class="caps">{{details.lastMileage}} <span class="button is-small is-light" @click="showEditMileage = true">Edit</span></td>
                    </tr>
                  </table>
                  <!-- <span class="button" @click="showEditDetails = true">Edit</span>
                          <p>&nbsp;</p> -->
                  <div class="form-group">
                    <label class="label-fixed">TRUCK DESCRIPTION:</label>
                    <!-- <input class="input" type="text" :disabled="!isTruckOwner" v-model="details.description"> -->
                    <textarea v-model="details.description" class="textarea" :disabled="!isTruckOwner" placeholder="Truck description here..." rows="5"></textarea>
                    <!-- <span class="highlight"></span>
                            <span class="bar"></span> -->
                  </div>
                  <div class="form-group">
                    <label class="label-fixed">TRUCK HOME STATE:</label>
                    <div class="control">
                      <div class="select">
                        <select :disabled="!isTruckOwner" v-model="details.truckState">
                          <option disabled value="">Choose state</option>
                          <option v-for="state in options.states" v-bind:value="state.name" :key="state.name">
                            {{ state.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="label-fixed">GENERAL TRUCK NOTES:</label>
                    <textarea v-model="details.operationalArea" class="textarea" :disabled="!isTruckOwner" placeholder="Area of operation details here..." rows="5"></textarea>
                  </div>
                  <div class="form-group">
                    <label class="label-fixed">LENGTH (m or ft):</label>
                    <input class="input" type="text" :disabled="!isTruckOwner" v-model="details.length">
                  </div>
                  <div class="form-group">
                    <label class="label-fixed">TRUCK TYPE:</label>
                    <div class="control">
                      <div class="select">
                        <select :disabled="!isTruckOwner" v-model="details.truckType">
                          <option disabled value="">Choose type</option>
                          <option v-for="item in options.truckType" v-bind:value="item.type" :key="item.type">
                            {{ item.type }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="form-group">   
                            <label class="label-fixed">HULL COLOUR:</label>   
                            <input class="input" type="text" :disabled="!isTruckOwner" v-model="details.colour">
                          </div> -->
                  <div class="form-group">
                    <label class="label-fixed">ENGINE INFO:</label>
                    <!-- <input class="input" type="text" :disabled="!isTruckOwner" v-model="details.propulsion"> -->
                    <textarea v-model="details.propulsion" class="textarea" :disabled="!isTruckOwner" placeholder="Engine details here..." rows="5"></textarea>
                  </div>
                  <!-- <div class="form-group">
                    <label class="label-fixed">A SERVICE INTERVAL (KM):</label>
                    <input class="input" type="tel" :maxlength="4" v-on:keypress="isNumber($event)" :disabled="!isTruckOwner" v-model="details.serviceInterval">
                  </div>
                  <div class="form-group">
                    <label class="label-fixed">B SERVICE INTERVAL (KM):</label>
                    <input class="input" type="tel" :maxlength="4" v-on:keypress="isNumber($event)" :disabled="!isTruckOwner" v-model="details.serviceIntervalGenset">
                  </div> -->
                  <!-- <div class="form-group">      
                            <label class="label-fixed">FUEL CAPACITY:</label>
                            <input class="input" type="tel" :maxlength="6" v-on:keypress="isNumber($event)" :disabled="!isTruckOwner" v-model="details.fuelCapacity">
                          </div> -->
                  <!-- <div class="form-group">
                    <label class="label-fixed">MINIMUM CREW:</label>
                    <input class="input" type="tel" :disabled="!isTruckOwner" v-model="details.mincrew" :maxlength="3" v-on:keypress="isNumber($event)">
                  </div> -->
                  <!-- <div class="form-group">
                    <label class="label-fixed">APPROPRIATE CREW:</label>
                    <input class="input" type="tel" :disabled="!isTruckOwner" v-model="details.crew" :maxlength="3" v-on:keypress="isNumber($event)">
                  </div> -->
                 <!--  <div class="form-group">
                    <label class="label-fixed">Appropriate Crew Explanation:</label>
                    <textarea v-model="details.appCrewExplanation" class="textarea" :disabled="!isTruckOwner" placeholder="Write explanation here..." rows="5"></textarea>
                  </div>
 -->
                  <!-- <div class="form-group-reg"> 
                    <input class="is-checkradio" id="doesDiving" type="checkbox" v-model="details.doesDiving">
                    <label for="doesDiving">Diving operations are performed on this truck</label>
                  </div> -->

                  <div class="form-group-reg"> 
                    <input class="is-checkradio" id="takesPassengers" type="checkbox" v-model="details.takesPassengers">
                    <label for="takesPassengers">This truck carries passengers</label>
                  </div>




                  <!-- //// ===============  -->
                  <!-- 
                          <div class="qualification-photo has-text-centered" v-if="truckPhoto">
                            <img :src="truckPhoto" alt="Photo"/>
                          </div>

                          <div v-if="isTruckOwner" class="upload-wrapper">
                            <image-uploader
                                :preview="false"
                                :className="['fileinput', { 'fileinput--loaded': hasImage }]" 
                                capture="environment" 
                                :debug="1" 
                                doNotResize="gif" 
                                id="firstImage"  
                                :autoRotate=true  
                                outputFormat="verbose" 
                                @input="setImage" 
                                :quality="0.8" 

                              >
                              <label for="firstImage" slot="upload-label">
                                <figure>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                    <path
                                      class="path1"
                                      d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                                    ></path>
                                  </svg>
                                </figure>
                                <span class="upload-caption">{{ hasImage ? 'Change photo' : 'Take a photo' }}</span>
                                <div class="clear"></div>
                              </label>
                            </image-uploader>
                          </div>

                          <div v-if="hasImage && isTruckOwner" class="button-wrapper center">
                            <span @click="removeImage" class="button">Remove</span>
                          </div>  
 -->
                  <!-- //// ===============  -->
                  <!-- <img class="hazard-image" :src="details.image" />

                          <div v-if="isTruckOwner" class="upload-wrapper">
                            <image-uploader
                                :preview="false"
                                :className="['fileinput', { 'fileinput--loaded': hasImage }]" 
                                capture="environment" 
                                :debug="1" 
                                doNotResize="gif" 
                                id="firstImage"  
                                :autoRotate=true  
                                outputFormat="verbose" 
                                @input="setImage" 
                                :quality="0.8" 

                              >
                              <label for="firstImage" slot="upload-label">
                                <figure>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                    <path
                                      class="path1"
                                      d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                                    ></path>
                                  </svg>
                                </figure>
                                <span class="upload-caption">{{ hasImage ? 'Change photo' : 'Take a photo' }}</span>
                                <div class="clear"></div>
                              </label>
                            </image-uploader>
                          </div>

                          <div v-if="hasImage && isTruckOwner" class="button-wrapper center">
                            <span @click="removeImage" class="button">Remove</span>
                          </div>   -->
                  <div class="buttons">
                    <button @click="goBack()" class="button is-info"><span>
                        <font-awesome-icon icon="chevron-left" /></span>&nbsp; Back</button>
                    <button v-if="isTruckOwner" class="button is-primary" type="submit">Save truck details</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="notification is-warning">
            <p>No truck detected.</p>
            <p>&nbsp;</p>
            <!-- <router-link to="my-trucks">
              <span class="button is-primary">Back to My Trucks</span>
            </router-link> -->
            <button @click="goBack()" class="button is-info"><span>
                <font-awesome-icon icon="chevron-left" /></span>&nbsp; Go Back</button>
          </div>
        </div>
        <div class="spacer s90"></div>
      </div>
    </div>
    
    <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showEditMileage}">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Edit Truck Mileage:</p>
          <button class="delete" aria-label="close" @click.prevent="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="form-group-reg">
            <label class="label-fixed">Enter current mileage (in KM):</label>
            <input class="input" type="number" :placeholder="details.lastMileage" v-model="newMileage">
          </div>
          <p class="red" v-if="showWarning"><span><font-awesome-icon icon="exclamation-triangle" /></span>&nbsp;Warning: The mileage you are entering is less than the last reading.</p>
          <div class="spacer s30" v-else></div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" @click="saveMileage()" type="submit">Save Mileage</button>
          <button class="button cancel" @click.prevent="close">Cancel</button>
        </footer>
      </div>
    </div>

    <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showEditDetails}">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Edit Truck Details:</p>
          <button class="delete" aria-label="close" @click.prevent="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="form-group-reg">
            <label class="label-fixed">New Truck Name:</label>
            <input class="input" type="text" :placeholder="currentTruckName" v-model="newTruckName">
          </div>
          <div class="form-group-reg">
            <label class="label-fixed">New Truck UVI:</label>
            <input class="input" type="text" :placeholder="currentTruckUVI" v-model="newTruckUVI">
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" @click="editTruckDetails" type="submit">Update Truck</button>
          <button class="button cancel" @click.prevent="close">Cancel</button>
        </footer>
      </div>
    </div>


  </div>
</template>
<script>
import { db } from '../main.js';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
// import 'firebase/functions';
import NProgress from 'nprogress';
//import { store } from '../store/store';

// const ImageUploader = () => import('../reusables/ImageUploader.vue');
const FleetScroller = () => import('../reusables/FleetScroller.vue');
const FleetLeftSidebar = () => import('../reusables/FleetLeftSidebar.vue');



export default {

  name: 'FleetTruckInformation',
  props: {
    isFleetTruck: Boolean,
  },
  components: {
    // ImageUploader,
    FleetScroller,
    FleetLeftSidebar
  },
  mounted: function() {
    firebase.auth().onAuthStateChanged(this.onUserLogin);
    // console.log(this.$route.params);
  },
  computed: {
    isUserAdmin() {
      return this.$store.getters.getIsUserAdmin;
    },
    currentTruckID() {
      return this.$store.getters.getCurrentTruckID;
    },
    currentTruckName() {
      return this.$store.getters.getCurrentTruckName;
    },
    currentTruckUVI() {
      return this.$store.getters.getCurrentTruckUVI;
    },
    isTruckOwner() {
      return this.firebaseid === this.truckOwnerID || this.isFleetTruck || this.currentFleet.id != '';
    },
    userData() {
      return this.$store.getters.getSubscriptionInfo;
    },
    currentFleet() {
      return this.$store.getters.getCurrentFleet;
    },
    showWarning() {
      return this.newMileage == '' || this.newMileage == 0 ? false : parseInt(this.newMileage) < parseInt(this.details.lastMileage) ? true : false;
    }
    // selectedTruckIndex() {
    //  if (this.myTrucks.length) {
    //    let thisTruck = this.myTrucks.filter(truck => {
    //      return truck.id == this.currentTruckID;
    //    });
    //    return thisTruck[0].index;
    //  } 
    // },

  },
  data: function() {
    return {
      msg: 'Truck Overview',
      firebaseid: '',
      firebaseemail: '',
      firebaserole: '',
      firebasedisplayname: '',

      details: {
        // truckname: '',
        // uvi: '',
        description: '',
        truckState: '',
        operationalArea: '',
        length: '',
        truckType: '',
        // colour: '',
        propulsion: '',
        crew: '', //appropriate crew
        mincrew: '',
        image: '',
        appCrewExplanation: '',
        doesDiving: false,
        takesPassengers: false,
        lastMileage: 0,
      },
      options: {
        truckType: [
          { type: "Aluminium" },
          { type: "Fibreglass" },
          { type: "FRP" },
          { type: "Steel" },
          { type: "Timber" },
          { type: "Other" }
        ],
        states: [
          { name: "Tasmania" },
          { name: "Victoria" },
          { name: "New South Wales" },
          { name: "Queensland" },
          { name: "South Australia" },
          { name: "Western Australia" },
          { name: "Northern Territory" }
        ],
      },

      truckPhoto: '',
      hasImage: false,
      imageURL: '',

      truckOwnerID: null,
      newTruckName: '',
      newTruckUVI: '',
      newMileage: '',
      showEditDetails: false,
      showEditMileage: false,
      //selectedTruckIndex: '',

      // myTrucks: [],
    }
  },
  methods: {


    isNumber: function($evt) {
      $evt = ($evt) ? $evt : window.event;
      var charCode = ($evt.which) ? $evt.which : $evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        $evt.preventDefault();
      } else {
        return true;
      }
    },

    setImage: function(output) {
      this.hasImage = true;
      this.imageURL = output.dataUrl;
      this.truckPhoto = output.dataUrl;
    },

    removeImage: function() {
      this.hasImage = false;
      this.truckPhoto = '';
      this.imageURL = '';
    },


    onUserLogin: function(user) {
      if (user) {
        this.firebaseemail = user.email;
        //this.firebasedisplayname = user.displayName;
        this.firebasedisplayname = this.userData.username;
        this.firebaseid = user.uid;
        var self = this;
        db.collection('trucks').doc(this.currentTruckID).onSnapshot(function(documentSnapshot) {
          if (documentSnapshot.exists) {
            var data = documentSnapshot.data();
            self.details.description = (data.truckDescription ? data.truckDescription : '');
            self.details.truckState = (data.truckState ? data.truckState : '');
            self.details.length = (data.truckLength ? data.truckLength : '');
            self.details.truckType = (data.truckType ? data.truckType : '');
            // self.details.colour = (data.truckColour ? data.truckColour : '');
            self.details.propulsion = (data.truckPropulsion ? data.truckPropulsion : '');
            self.details.operationalArea = (data.truckOperationalArea ? data.truckOperationalArea : '');
            // self.details.serviceInterval = (data.serviceInterval ? data.serviceInterval : '');
            // self.details.serviceIntervalGenset = (data.serviceIntervalGenset ? data.serviceIntervalGenset : '');
            // self.details.fuelCapacity = (data.fuelCapacity ? data.fuelCapacity : '');
            // self.details.mincrew = (data.truckMinCrew ? data.truckMinCrew : '');
            // self.details.crew = (data.truckCrew ? data.truckCrew : '');
            // self.details.appCrewExplanation = data.appCrewExplanation ? data.appCrewExplanation : '',
            // self.details.doesDiving = data.doesDiving ? data.doesDiving : false;
            self.details.takesPassengers = data.takesPassengers ? data.takesPassengers : false;
            self.details.lastMileage = data.lastMileage ? data.lastMileage : 0;
              //self.details.image = (data.truckImage ? data.truckImage : '');
              // self.truckPhoto = (data.truckPhoto ? data.truckPhoto : '');
              // self.hasImage = (data.truckPhoto ? true : false);
            self.truckPhoto = (data.truckPhoto ? data.truckPhoto : '');
            self.imageURL = (data.truckPhoto ? data.truckPhoto : '');
            self.hasImage = (data.truckPhoto ? true : false);
            self.truckOwnerID = data.truckOwnerID;
          } else {
            console.log('Document userdata not found');
          }
        });

        // db.collection('userdata').doc(self.firebaseid).onSnapshot(function(doc) {
        //  //self.myTrucks = doc.data().myTrucks;
        //  self.myTrucks = [];
        //  doc.data().myTrucks.forEach(function(truck,index) {
        //    self.myTrucks.push({
        //      index: index,
        //      name: truck.truckName,
        //      id: truck.truckID,
        //      uvi: truck.truckUVI
        //    });
        //  });
        // });

      }
    },
    updateInformation: function() {
      NProgress.start();
      let self = this;
      const createdAt = new Date();
      db.collection("trucks").doc(this.currentTruckID).update({
        truckDescription: self.details.description,
        truckState: self.details.truckState,
        truckLength: self.details.length,
        truckType: self.details.truckType,
        // truckColour: self.details.colour,
        truckPropulsion: self.details.propulsion,
        truckOperationalArea: self.details.operationalArea,
        // serviceInterval: self.details.serviceInterval,
        // serviceIntervalGenset: self.details.serviceIntervalGenset,
        // fuelCapacity: self.details.fuelCapacity,
        // truckCrew: self.details.crew,
        // truckMinCrew: self.details.mincrew,
        // appCrewExplanation: self.details.appCrewExplanation,
        //truckImage: (self.imageURL != '' ? self.imageURL : ''),
        truckPhoto: (self.imageURL != '' ? self.imageURL : ''),
        // doesDiving: self.details.doesDiving,
        takesPassengers: self.details.takesPassengers,
        overviewSavedAt: createdAt
      })
      .then(() => {
        var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
        addTruckRevision({
          userName: self.firebasedisplayname,
          userID: self.firebaseid,
          truckName: self.currentTruckName,
          truckID: self.currentTruckID,
          revisionDetails: 'Truck information updated',
        });
        self.goBack();
        //    self.$router.push({ name: 'EditSms', params: { 
        //  selectedTruckID: self.currentTruckID,
        //  selectedTruckName: self.currentTruckName,
        //  selectedTruckUVI: self.currentTruckUVI,
        // } })
        NProgress.done();
      }).catch((error) => {
        console.log(error);
        NProgress.done();
      });
    },

    saveMileage() {
      NProgress.start();
      let self = this;
      const createdAt = new Date();
      db.collection("trucks").doc(this.currentTruckID).update({
        lastMileage: parseInt(self.newMileage),
        overviewSavedAt: createdAt
      })
      .then(() => {
        var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
        addTruckRevision({
          userName: self.firebasedisplayname,
          userID: self.firebaseid,
          truckName: self.currentTruckName,
          truckID: self.currentTruckID,
          revisionDetails: 'Truck mileage updated',
        });
        self.showEditMileage = false;
        self.newMileage = '';
        NProgress.done();
      })
      .catch((error) => {
        console.log(error);
        NProgress.done();
      });
    },

    goBack() {
      // if (!this.isFleetTruck) {
      // this.$router.push({ name: 'EditSms'});
      // } else {
      this.$router.push({ name: 'FleetEditSms' });
      // }
    },

    backToSms: function() {
      let self = this;
      console.log(self.currentTruckID, self.currentTruckName, self.currentTruckUVI);
      this.$router.push({
        name: 'TruckEditSms',
        params: {
          selectedTruckID: self.currentTruckID,
          selectedTruckName: self.currentTruckName,
          selectedTruckUVI: self.currentTruckUVI,
        }
      })
    },

    close: function() {
      this.showEditDetails = false;
      this.showEditMileage = false;
      this.newMileage = '';
      this.newTruckName = '';
      this.newTruckUVI = '';
    },

    editTruckDetails: function() {
      let self = this;
      console.log(this.currentTruckID, this.currentTruckName, this.currentTruckUVI);
      let updatedTruckName = self.newTruckName == '' ? self.currentTruckName : self.newTruckName;
      let updatedTruckUVI = self.newTruckUVI == '' ? self.currentTruckUVI : self.newTruckUVI;

      console.log(this.currentTruckID, this.newTruckName, this.newTruckUVI);
      db.collection('trucks').doc(this.currentTruckID).update({
          truckName: updatedTruckName,
          truckUVI: updatedTruckUVI
        })
        .then(function() {
          // db.collection('userdata').doc(self.firebaseid).update({
          //  myTrucks[self.selectedTruckIndex].truckName: updatedTruckName,
          //  myTrucks[self.selectedTruckIndex].truckUVI: updatedTruckUVI,
          // });
        });
    },



  }

}
</script>
<style lang="scss" scoped>
table {
  &.pad-bottom {
    margin-bottom: 1rem;
  }

  .caps {
    text-transform: uppercase;
  }
}

.form-group {
  margin-bottom: 2.5rem;

  &:first-of-type {
    margin-top: 0;
  }

  input {
    border: 1px solid #e4e4e4;
    padding: 10px;
    height: auto;
    border-radius: 0;
  }

  label.label-fixed {
    display: block;
    position: relative;
    margin-bottom: 1rem;
  }
}

.field {
  margin-bottom: 2.5rem;

  &.pad {
    margin-bottom: 3rem;
  }
}

.label {
  color: inherit;
  font-weight: inherit;
}

.notification {

  a,
  a:visited {
    text-decoration: none !important;
  }

  &.pad-bottom {
    margin-bottom: 3rem;
  }
}

.header-image {
  // background: url('../assets/img/placeholder-2.jpg') no-repeat center center;
  color: #fff;
}

#profile-form {
  // margin-top: 2rem;
}

// .hazard-image {
//  max-width: none;
//  width: 100%;
// }
.upload-wrapper {
  margin: 0;
  text-align: center;

  label[for="firstImage"] {
    text-align: center;
  }
}

.qualification-photo {
  img {
    max-width: 500px;
    width: 100%;
  }
}

.input[disabled],
.textarea[disabled] {
  color: #111 !important;
}

.table.is-bordered td, .table.is-bordered th {
  line-height: 30px;
}
.red {
  color: red;
}

textarea {
  min-height: 100px !important;
  box-shadow: none;
  padding: 0.6rem 1rem;

  &.notes {
    margin-top: 1rem;
  }
}
</style>