<template>
  
  <div>

    <div class="fleet-wrapper">

      <fleet-left-sidebar></fleet-left-sidebar>

      <div class="main-fleet-content">

          <div class="two-thirds">
            
            <div class="before-icon"><font-awesome-icon icon="users" /></div><h4 class="icon">Approve Drivers on {{selectedTruckName}}</h4>  
            <div class="clear"></div>

            <!-- <p><strong>Staff</strong> access will allow {{selectedStaffName}} to see the truck SMS only, as required by Law.</p>
            <p><strong>Master</strong> access lets {{selectedStaffName}} see the SMS as well as do all truck logs on your behalf.</p> -->
            <!-- <p>&nbsp;</p>  -->

            

            <!-- {{savedStaffIDs}}<hr>{{savedMasterIDs}} -->
            <!-- {{allStaff}}<br>{{driversChanged}} <br>{{newDrivers}} -->
      

            <div v-if="allStaff.length">

                <p>Click on a driver's name to view their profile.</p>
                <div class="spacer s30"></div>

                <div>

                      <div v-if="allStaff.length" id='truck-checkboxes'>

                          <table class="table is-fullwidth">
                            <tbody>
                              <tr>
                                <th>Name</th>
                                <th>Set as Driver?</th>
                                <!-- <th>Master</th> -->
                              </tr>
                              <tr class="field" v-for="(staff, index) in allStaff" :key="index">
                                <!-- <td><span class="simple-link" @click="viewStaffProfile(staff.staffID, staff.staffName)">{{staff.staffName}}</span></td> -->
                                <td>
                                  <span class="simple-link" :class="{red: staff.qualExpired}" @click="launchStaffProfile(staff)">{{staff.staffName}}</span>&nbsp; 
                                  <span class="red" v-if="staff.qualExpired"><font-awesome-icon icon="exclamation-triangle" /></span>     
                                </td>

                                <td>
                                  <input @change="staff.changed = !staff.changed" class="is-checkradio" type="checkbox" :id="`staff-${index}`" :value="staff" v-model="staff.isStaff">
                                  <label :for="`staff-${index}`"></label>
                                </td>
                                <!-- <td>
                                  <input class="is-checkradio" type="checkbox" :id="`master-${index}`" :value="staff" v-model="staff.isMaster">
                                  <label :for="`master-${index}`"></label>
                                </td> -->

                              </tr>

                            </tbody>
                          </table>
                          

                          <div class="buttons">
                            <button class="button is-primary" @click="saveTruckStaff">Save driver access</button>
                          </div>

                         <!--  <hr>

                          {{listOfStaff}}

                          <hr>

                          {{listOfStaffIDs}}

                          <hr>

                          {{listOfMasters}}

                          <hr>

                          {{listOfMasterIDs}} -->

                          <!-- <hr>
                          {{updatedStaffList}} -->

                      </div>

                      <div v-else class="">
                        No trucks found.
                      </div>

                </div>

            </div>

            <div v-else>

              <p>No drivers found.</p>
              <div class="spacer s10"></div>
              <div class="buttons">
                <router-link to="fleet-trucks">
                  <span class="button is-primary">Back to Trucks</span>
                </router-link>
              </div>

            </div>



            <!-- <div class="spacer s40"></div>

            <hr>

            <router-link class="button is-info" to="">Back to Fleet Dashboard</router-link> -->

          </div>

          <div class="one-third">
            
            <div class="before-icon"><font-awesome-icon icon="link" /></div><h4 class="icon">Quick Links</h4>  
            <div class="clear"></div>
            
            <div class="buttons">
              <router-link to="fleet-trucks" class="button is-info">All trucks</router-link>
              <router-link to="fleet-staff" class="button is-primary">All drivers</router-link>
             <!--  <router-link to="fleet-admins" class="button is-info">Manage Admins</router-link> -->
            </div>

          </div>
        


          <div class="clear"></div>

          <div class="spacer s90">
            
          </div>

      </div>



      <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':isActiveStaffProfile}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">{{currentStaffProfile.name}} <span class="red" v-if="expiredQuals.length"><font-awesome-icon icon="exclamation-triangle" /></span></p>
            <button class="delete" aria-label="close" @click="close"></button>
          </header>
          <section class="modal-card-body"> 

                <!-- <div class="">Name: {{currentStaffProfile.name}}</div>
                <div class="">User ID: {{currentStaffProfile.id}}</div>
                <div class="">Phone number: {{currentStaffProfile.phone}}</div>
                <div class="">Email address: {{currentStaffProfile.email}}</div>
                <div class="">State: {{currentStaffProfile.state}}</div>
                <div class="">Home port: {{currentStaffProfile.homeport}}</div>
                <div class="">Address: {{currentStaffProfile.address}}</div>
                <div class="">ABN: {{currentStaffProfile.abn}}</div> -->

                <p v-if="showLoadingProfile">Loading...</p>
                <div v-else>
                  <table class="table is-fullwidth">
                    <tbody>
                      <tr>
                        <td>Name:</td>
                        <td>{{currentStaffProfile.name}}</td>
                      </tr>
                      <tr>
                        <td>State:</td>
                        <td>{{currentStaffProfile.state}}</td>
                      </tr>
                      <tr>
                        <td>Industry:</td>
                        <td>{{currentStaffProfile.industry}}</td>
                      </tr>
                      <tr>
                        <td>Phone:</td>
                        <td>{{currentStaffProfile.phone}}</td>
                      </tr>
                      <tr>
                        <td>Qualifications</td>
                        <td>
                          <div :class="{expired: qual.past}" v-for="(qual, index) in currentStaffProfile.quals" :key="index">
                            {{qual.title}} <span v-if="qual.expiryDate != ''">(Expires: {{formatDate(qual.expiryDate,'D MMMM YYYY')}})</span>
                          </div>  
                        </td>
                      </tr>
                      <tr>
                        <td>Staff Notes:</td>
                        <td><div v-for="line in currentStaffProfile.qualifications" :key="line">{{line}}<br></div></td>
                      </tr>
                      <tr>
                        <td>Contacts:</td>
                        <td><div v-for="line in currentStaffProfile.contacts" :key="line">{{line}}<br></div></td>
                      </tr>
                      <tr>
                        <td>Key Duties:</td>
                        <!-- <td><textarea rows="3" class="input" v-model="currentStaffProfile.keyDuties" required></textarea></td> -->
                        <td>{{currentStaffProfile.keyDuties}}</td>
                      </tr>
                      <tr>
                        <td>Fleet Notes:</td>
                        <!-- <td><textarea rows="3" class="input" v-model="currentStaffProfile.staffNotes" required></textarea></td> -->
                        <td>{{currentStaffProfile.staffNotes}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </section>
              <footer class="modal-card-foot">
                <button class="button cancel" @click="close">Close</button>
              </footer>
            </div>
          </div>





    </div>


  </div>

</template>



<script>
  
import { db } from '../main.js';
import firebase from 'firebase/app';
import 'firebase/auth';
import NProgress from 'nprogress';
const FleetLeftSidebar = () => import('../reusables/FleetLeftSidebar.vue');
import * as moment from 'moment';

export default {

  components: {
    FleetLeftSidebar,
  },  
  props: {
    selectedTruckID: String,
    selectedTruckName: String,
  },
  mounted: function() {
    firebase.auth().onAuthStateChanged( this.onUserLogin );
  },
  computed: {    
    currentFleet() {
      return this.$store.getters.getCurrentFleet;
    },
    // listOfStaff() {
    //   return this.allStaff.filter((staff) => staff.isStaff);
    // },
    listOfStaffIDs() {
      // return this.allStaff.filter((staff) => staff.isStaff || staff.isMaster).map((staff) => staff.staffID);
      return this.allStaff.filter((staff) => staff.isStaff).map((staff) => staff.staffID);
    },
    // listOfMasters() {
    //   return this.allStaff.filter((staff) => staff.isMaster);
    // },
    // listOfMasterIDs() {
    //   return this.allStaff.filter((staff) => staff.isMaster).map((staff) => staff.staffID);
    // },
    updatedStaffList() {
      let tempArray = this.allStaff.filter((staff) => staff.changed).map((staff) => {
        // return { staffID: staff.staffID, isStaff: staff.isStaff, isMaster: staff.isMaster}
        return { staffID: staff.staffID, isStaff: staff.isStaff, staffName: staff.staffName}
      });
      return tempArray;
    },
    expiredQuals() {
      return this.currentStaffProfile.quals.filter((qual) => qual.past);
    },
    driversChanged() {
      return this.allStaff.filter((staff) => staff.changed).map((staff) => staff.staffID);
    },
    newDrivers() {
      return this.allStaff.filter((staff) => staff.changed && staff.isStaff).map((staff) => staff.staffID);
    }
  },

  data() {
    return {
      allStaff: [],
      savedStaffIDs: [],
      savedMasterIDs: [],
      showLoadingProfile: false,
      isActiveStaffModal: false,
      isActiveStaffProfile: false,
      currentStaffProfile: {
        id: '',
        name: '',
        phone: '',
        email: '',
        state: '',
        homeport: '',
        qualifications: '',
        industry: '',
        address: '',
        keyDuties: '',
        staffNotes: '',
        quals: [],
      },
    }
  },

  methods: {

    onUserLogin: function() {
      // if (user) {         
      //   this.firebaseid = user.uid;
      // }
      this.loadStaffAndMasters();
      this.loadAllStaff();
    },

    loadStaffAndMasters() {
      let self = this;
      db.collection('trucks').doc(self.selectedTruckID).onSnapshot(function(doc) {
        self.savedStaffIDs = doc.data().listOfStaffIDs ? doc.data().listOfStaffIDs : [];
        // self.savedMasterIDs = doc.data().listOfMasterIDs ? doc.data().listOfMasterIDs : [];
      });
    },

    loadAllStaff() {
      let self = this;
      db.collection('fleets').doc(this.currentFleet.id).collection('fleetStaff').get().then(function(querySnapshot) {
        self.allStaff = [];
        querySnapshot.forEach((doc) => {
          // console.log(doc.data());
          self.allStaff.push({
            id: doc.id,
            staffID: doc.data().staffID,
            staffName: doc.data().staffName,
            isStaff: self.savedStaffIDs.includes(doc.id) ? true : false,            
            changed: false,
            // isMaster: self.savedMasterIDs.includes(doc.id) ? true : false,
            keyDuties: doc.data().keyDuties ? doc.data().keyDuties : '',
            staffNotes: doc.data().adminNotes ? doc.data().adminNotes : '',
            qualExpired: false,
          });
        });
      })
      .then(function() {
        self.allStaff.forEach((staff) => {
          db.collection('userdata').doc(staff.id).collection('myQualifications').get().then(function(querySnapshot) {
            querySnapshot.forEach((doc) => {
              let tempDate = doc.data().expiryDate != '' ? moment(doc.data().expiryDate.toDate()) : moment();
              if (moment().isAfter(tempDate)) staff.qualExpired = true;
            });
          });
        });
      });

    },

    // viewStaffProfile: function(staffID, staffName) { 
    //   this.$router.push({ 
    //     name: 'FleetStaffProfile', 
    //     params: { 
    //       staffID: staffID,
    //       staffName: staffName
    //     } 
    //   });

    // },

    simpleNumber: function(num) {
      return "0" + num.slice(3,12);
    },

    close: function() {
        this.isActiveStaffModal = false;
        this.isActiveStaffProfile = false;
    },

    // formatDate : function (sentDate,format) {
    //     let fixDate = (sentDate ? sentDate.toDate() : new Date());
    //     //console.log("Fix date:",fixDate);
    //     return moment(fixDate).format(format);
    // },

    formatDate : function (sentDate,format) {
      return sentDate == '' ? '' : moment(sentDate).format(format);
    },

    launchStaffProfile: function(staff) {
      NProgress.start();
      let self = this;  
      this.showLoadingProfile = true;
      this.isActiveStaffProfile = true;      
      this.currentStaffProfile.id = staff.staffID;
      this.currentStaffProfile.name = staff.staffName;
      this.currentStaffProfile.keyDuties = staff.keyDuties;
      this.currentStaffProfile.staffNotes = staff.staffNotes;
      // this.currentStaffProfile.connectionID = staff.connectionID;
      db.collection('userdata').doc(staff.staffID).get()
      .then(function(docref) {
        // console.log(docref.data());
        self.currentStaffProfile.state = docref.data().userstate ? docref.data().userstate : '';
        self.currentStaffProfile.industry = docref.data().industry ? docref.data().industry : '';
        self.currentStaffProfile.phone = docref.data().userphone ? self.simpleNumber(docref.data().userphone) : '';
        self.currentStaffProfile.contacts = docref.data().contacts ? docref.data().contacts.split('\n') : '';
        self.currentStaffProfile.qualifications = docref.data().qualifications ? docref.data().qualifications.split('\n') : '';
      })
      .then(function() {
        db.collection('userdata').doc(staff.staffID).collection('myQualifications').onSnapshot(function(querySnapshot) {
          self.currentStaffProfile.quals = [];
          querySnapshot.forEach((doc) => {
            let tempDate = doc.data().expiryDate != '' ? moment(doc.data().expiryDate.toDate()) : moment();
            self.currentStaffProfile.quals.push({
              id: doc.id,
              title: doc.data().title,
              expiryDate: doc.data().expiryDate != '' ? doc.data().expiryDate.toDate() : '',
              past: moment().isAfter(tempDate),
            });
          });
        });
      })
      .then(function() {
        self.showLoadingProfile = false;
        NProgress.done();
      }).catch(function() {
        self.showLoadingProfile = false;
        NProgress.done();
      });  

    },

    saveTruckStaff() {
      // console.log(this.listOfStaffIDs,this.listOfMasterIDs);
      // console.log(this.selectedTruckID);
      console.log(this.updatedStaffList);
      NProgress.start();
      let self = this;
      db.collection('trucks').doc(this.selectedTruckID).update({
        // listOfMasterIDs: self.listOfMasterIDs,
        listOfStaffIDs: self.listOfStaffIDs,
      })
      .then(function() {
        var updateStaffTruckAccess = firebase.functions().httpsCallable('updateStaffTruckAccess');
        updateStaffTruckAccess({
          truckID: self.selectedTruckID,
          truckName: self.selectedTruckName,
          userName: self.currentFleet.name,
          updatedStaffList: self.updatedStaffList,
        }); 
        var addTruckRevision = firebase.functions().httpsCallable('addTruckRevision');
        addTruckRevision({
          userName: self.currentFleet.name,
          userID: self.currentFleet.id,
          truckName: self.selectedTruckName,
          truckID: self.selectedTruckID,
          revisionDetails: 'Staff access updated',
        });  
        if (self.newDrivers.length > 0) {
          var sendNewDriverNotification = firebase.functions().httpsCallable('sendNewDriverNotification');
          sendNewDriverNotification({
            sentDriverIDs: self.newDrivers,
            messageTitle: 'You\'ve been added as a driver on a Fleet vehicle (' + self.selectedTruckName + ').'
          }); 
        }
      })
      .then(function() {
        NProgress.done();
        self.$router.push('fleet-trucks');
      })
      .catch(function(err) {
        console.log('Error',err);
        NProgress.done();
      });

    },


  }



}


</script>



<style lang="scss" scoped>
  

textarea {
  min-height: 60px !important;
  box-shadow: none;
  padding: 0.6rem 1rem;
}
.table {
  tr {
    width: 100%;
    td {
      // &:first-of-type {
      //   width: 30%;
      // }
      div {
        &.expired {
          color: red;
        }
      }
    }
  }
}
span {
  &.red {
    color: red;
  }
}


</style>


